import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.css';
import { useRef, useState } from 'react';
import UnfocusableWrapper from '../../UnfocusableWrapper/UnfocusableWrapper';
import classNames from 'classnames';
import AutosizeInput from 'react-input-autosize';
import { Calendar } from '../../../../assets/images/icons';
import './DatePickerRC.scss';

export const DatePickerInitial = props => {
	const [selected, setSelected] = useState(moment());
	const pickerTriggerRef = useRef();

	const handleChange = sel => {
		setSelected(sel);
		props.onChange && props.onChange(sel);
		pickerTriggerRef?.current && pickerTriggerRef.current.click();
	};
	return (
		<>
			<DatetimePickerTrigger showTimePicker={false} moment={selected} onChange={handleChange}>
				<input ref={pickerTriggerRef} type="text" value={selected.format('YYYY-MM-DD HH:mm')} readOnly />
			</DatetimePickerTrigger>
		</>
	);
};
export const DatePickerRC = props => {
	const { value_date, maxDate, onDateChange, containerClassName } = props;
	const dateTimePickerRef = useRef();
	const [selectedDate, setSelectedDate] = useState(moment(value_date, 'YYYY-MM-DD')?.isValid() ? moment(value_date, 'YYYY-MM-DD') : moment());
	const toggleDatePicker = () => {
		if (dateTimePickerRef?.current?.state?.isOpen) {
			dateTimePickerRef?.current?.togglePicker(false);
		}
	};
	const handlerDateChange = date => {
		if (moment(date)?.isValid()) {
			setSelectedDate(date);
			onDateChange && onDateChange(moment(date));
		}
	};
	return (
		<UnfocusableWrapper className={classNames('host__DatePickerRC', containerClassName)} onClickOutside={toggleDatePicker}>
			<DatetimePickerTrigger ref={dateTimePickerRef} showTimePicker={false} dayFormat={'YYYY-MM-DD'} moment={selectedDate} maxDate={maxDate} onChange={handlerDateChange} style={{ width: 'auto' }}>
				<div className={'datePickerRCContentContainer'}>
					<AutosizeInput
						readOnly
						name="FoundingDate"
						value={moment(value_date, 'YYYY-MM-DD')?.isValid() ? moment(selectedDate).format('Do of MMMM YYYY') : ''}
						placeholderIsMinWidth={true}
						placeholder={'Select date '}
						inputStyle={{
							padding: '0 0 0 10px',
							outline: 'none',
							border: 'none',
							borderRadius: '8px',
							fontFamily: 'var(--app-text-main-font)',
							fontSize: '16px',
							height: '52px',
							color: '#4a4a4a',
							textAlign: 'left',
							alignSelf: 'center',
						}}
						onChange={_event => {}}
					/>
					<Calendar className={'calendarSVG_DatePickerRC'} />
				</div>
			</DatetimePickerTrigger>
		</UnfocusableWrapper>
	);
};
