import moment, { now } from 'moment';
import { Observable } from 'rxjs';
import {
	componentBlueprintDelete,
	setBlueprintComponents,
	setMessageBar,
	setLastUpdate,
	setSelectedComponentBlueprint,
	setAllComponents,
	setFilters,
	addComponent,
	setComponentTypes,
	removeComponent,
	editComponent,
	addFavorite,
	setFavorites,
	deleteFavorite,
	setCompanyProcesses,
} from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

const R = require('ramda');
const qs = require('qs');

export const getFieldTypes = (forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates } = store.getState().flowReducer;

	const config = {
		...URLS.componentsBlueprint.getComponentFieldTypes(last_access_token),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.blueprinttypes), 'minutes') > 10) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						store.dispatch(setLastUpdate({ ...lastUpdates, blueprinttypes: now() }));
						const fieldTypes = response?.data?.data;
						store.dispatch(setComponentTypes(fieldTypes));
						subscriber.next(fieldTypes);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		}
	});
};

export const createComponent = (componentData, taskId = '', module_Id = '', users = [], title = '', isCompleted = true, positions = [], local = false) => {
	const component_fields = componentData?.selected_fields.map(function (el) {
		return {
			procedure_step_blueprint_field_id: el.procedure_step_blueprint_field_id,
			index: el.index,
			value: el.value,
			group: el.group ?? 0,
		};
	});

	const component_items_added_component = componentData?.component_items
		? componentData?.component_items.map(function (el, index) {
			return {
				index: index,
				item_code: el.item_code ?? '',
				quantity: el.quantity ?? '',
				value: el.value ?? '',
				alt_value: el.alt_value ?? '',
				total: el.total ?? '',
				type: el.type ?? ''
			};
		})
		: [];

	const newComponent = {
		title: title,
		task_id: taskId,
		module_id: module_Id,
		procedure_step_id: '',
		component_blueprint_id: componentData.id,
		is_complete: isCompleted,
		status: componentData?.status,
		options: JSON.stringify({ ...componentData?.options }),
		component_fields: component_fields,
		component_items: component_items_added_component,
		assigned_to: componentData?.assigned_to
	};

	if (Object.keys(componentData?.options ?? {}).length == 0) {
		delete newComponent.options;
	}
	if (Object.keys(componentData?.assigned_to ?? {}).length == 0) {
		delete newComponent.assigned_to;
	}
	newComponent.users = users;
	newComponent.positions = positions;

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.createComponent(last_access_token),
		data: newComponent,
		forwardAllErrors: true,
	};
	return new Observable(subscriber => {
		if (local) {
			localStorage.setItem(module_Id, JSON.stringify(newComponent))
			subscriber.next(newComponent);
			subscriber.complete();
		} else {
			axiosInstance(config)
				.then(response => {
					if (response?.status == 201 && response?.data?.data) {
						store.dispatch(addComponent(response?.data?.data));
						store.dispatch(setMessageBar({ show: true, message: `You have successfully created a transaction ${response?.data?.data.title}`, top: '0' }));
						subscriber.next(response?.data?.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				})
		}
	}
	);
};

export const updateComponent = (componentData, componentId, users = [], title, isCompleted = true, positions = [], local=false) => {
	const selected_fields = componentData?.selected_fields?.map(function (el) {
		const obj = {
			procedure_step_blueprint_field_id: el.procedure_step_blueprint_field_id,
			value: el?.value ? el?.value : '-',
			index: el.index,
			group: el.group ?? 0,
		};
		if (el?.new !== true) {
			obj.id = el.id;
		}
		return obj;
	});

	const updatedComponent = {
		title: title,
		component_fields: selected_fields,
		component_items: componentData?.component_items?.map(it => {
			return {
				...it,
				item_code: it?.item_code ?? '',
				quantity: it?.quantity ?? '',
				value: it?.value ?? '',
				alt_value: it?.alt_value ?? '',
				total: it?.total ?? ''

			}
		}),
		status: componentData?.status ?? 'New',
		assigned_to: componentData?.assigned_to?.id,
		options: JSON.stringify(componentData?.options),
	};

	if (componentData.is_complete !== isCompleted) {
		updatedComponent.is_complete = isCompleted;
	}
	updatedComponent.users = users;
	updatedComponent.positions = positions;

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.editComponent(last_access_token, componentId),
		data: updatedComponent,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>{
		if (local) {
			localStorage.setItem(componentData.module_id, JSON.stringify({...componentData, ...updatedComponent}))
			subscriber.next({...componentData, ...updatedComponent});
			subscriber.complete();
		} else {
			axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {

					if (localStorage.getItem('transaction') === componentId) {
						localStorage.removeItem('transaction');
					}

					store.dispatch(editComponent(response?.data?.data));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${title}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			});
		}
	}
		
	);
};
export const updateComponentItem = (componentItem, componentId) => {


	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.updateItem(last_access_token, componentId),
		data: componentItem,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response?.data?.data));
					// store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${response?.data?.data?.title}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const assignComponent = (componentId, assigned_to) => {

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.assignComponent(last_access_token, componentId),
		data: { assigned_to: assigned_to },
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response?.data?.data));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${response?.data?.data?.title}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const assignStatusComponent = (componentId, status) => {

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.updateStatusComponent(last_access_token, componentId),
		data: { status: status },
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response?.data?.data));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${response?.data?.data?.title}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deleteComponent = componentId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.components.removeComponent(last_access_token, componentId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully deleted item`, color: 'red', top: '0' }));
					store.dispatch(removeComponent(componentId));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const duplicateComponent = componentId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.components.duplicateComponent(last_access_token, componentId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 201 && response?.data) {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully duplicated transaction`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateComponentStatus = (component_id, status = '') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.editComponent(last_access_token, component_id),
		data: { statusOnly: status, statusDate: new Date(moment.now()) },
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response?.data?.data));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${response?.data?.data?.title} status to ${status}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getComponents = (sharedOnly = false, forced = false) => {
	const { lastUpdates, components, blueprints, companyProcesses } = store.getState().flowReducer;

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.getComponents(last_access_token, sharedOnly, moment.utc(lastUpdates.component ?? 0).format('YYYY-MM-DD HH:mm:ss')),
		handlerEnabled: true,
	};

	//console.log('moment diff components', moment(now()).diff(moment(lastUpdates.components), 'minutes'), components.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.components), 'minutes') > 10 || components.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {

						if (response?.data?.procedures?.length > 0) {
							store.dispatch(setCompanyProcesses([...companyProcesses ?? [], ...response?.data?.procedures?.filter(proc => companyProcesses?.filter(p => p.id === proc.id)?.length === 0) ?? []], 1));
						}

						store.dispatch(setAllComponents(response.data.data.length > 0 ? R.sortBy(R.prop('created_date'), response.data.data) : [], sharedOnly == true ? 0 : 1, blueprints.map(bp => bp.fields).flat(1)));
						store.dispatch(setLastUpdate({ ...lastUpdates, components: now() }));
						subscriber.next(response.data.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};

export const getComponentById = component_id => {
	const { lastUpdates, components, blueprints } = store.getState().flowReducer;

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.getComponentById(last_access_token, component_id),
		handlerEnabled: true,
	};

	return new Observable(subscriber => {
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response.data.data));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			});
	});
};
export const updateFieldToComponent = fieldData => {
	const data = new FormData();
	data.append('value', fieldData.value);
	data.append('index', fieldData.index);
	data.append('group', fieldData.group);
	data.append('component_field_id', fieldData.component_field_id);

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.updateField(last_access_token, fieldData.component_id),
		data: fieldData,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.message) {
					const message = response?.data?.message;
					subscriber.next(message);
					subscriber.complete();
				} else if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response?.data?.data));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${response?.data?.data?.title}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const addNewFieldToComponent = fieldData => {
	const data = new FormData();
	data.append('procedure_step_blueprint_field_id', fieldData.procedure_step_blueprint_field_id);
	data.append('value', fieldData.value);
	data.append('index', fieldData.index);
	data.append('group', fieldData.group);

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.addNewField(last_access_token, fieldData.component_id),
		data: fieldData,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.message) {
					const message = response?.data?.message;
					subscriber.next(message);
					subscriber.complete();
				} else if (response?.status === 200 && response?.data && response?.data?.data) {
					store.dispatch(editComponent(response?.data?.data));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${response?.data?.data?.title}`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const uploadFileComponent = (file, fieldId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('file', file);
	data.append('field_id', fieldId);

	const config = {
		...URLS.components.fileUpload(last_access_token),
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getAllComponentBlueprints = (page, forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates, blueprints } = store.getState().flowReducer;

	const config = {
		...URLS.componentsBlueprint.getBlueprints(last_access_token, ''),
		//signal: axiosAbort.signal
	};

	//console.log('moment diff blueprints', moment(now()).diff(moment(lastUpdates.blueprints), 'minutes'), blueprints.length);
	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.blueprints), 'minutes') > 10 || blueprints.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						const components = response?.data;
						store.dispatch(setBlueprintComponents(components, page));
						store.dispatch(setLastUpdate({ ...lastUpdates, blueprints: now() }));
						subscriber.next(response?.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
export const getComponentBlueprintById = id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.componentsBlueprint.getComponentBlueprintById(last_access_token, id),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					const componentBlueprint = response?.data?.data;
					store.dispatch(setSelectedComponentBlueprint(componentBlueprint));
					subscriber.next(componentBlueprint);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const createBlueprintComponent = (componentData, temp_id = undefined) => {
	const data = JSON.stringify(componentData);
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.componentsBlueprint.createComponentBlueprint(last_access_token),
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 201 && response?.data && response?.data?.data) {
					const component = response?.data?.data;
					store.dispatch(setBlueprintComponents(temp_id ? { ...component, temp_id: temp_id } : component, 0));
					subscriber.next(temp_id ? { ...component, temp_id: temp_id } : component);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateBlueprintComponent = (bluePrintId, updatedBlueprintComponent) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.componentsBlueprint.updateComponentBlueprint(last_access_token, bluePrintId),
		handlerEnabled: true,
		data: updatedBlueprintComponent,
	};
	const { blueprints } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					const component = response?.data?.data;
					store.dispatch(setBlueprintComponents({ data: [...blueprints.filter(bp => bp.id !== bluePrintId), component] }, 1));
					subscriber.next(component);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deleteBlueprintComponent = id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.componentsBlueprint.removeComponentBlueprint(last_access_token, id),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(componentBlueprintDelete(id));
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const addNewFieldToComponentBlueprint = fieldData => {
	const data = new FormData();
	data.append('field_type_id', fieldData.field_type_id);
	data.append('value', fieldData.value);
	data.append('index', fieldData.index);
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { blueprints } = store.getState().flowReducer;

	const config = {
		...URLS.componentsBlueprint.addNewField(last_access_token, fieldData.component_blueprint_id),
		data: fieldData,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.message) {
					const message = response?.data?.message;
					subscriber.next(message);
					subscriber.complete();
				} else if (response?.status === 200 && response?.data && response?.data?.data) {
					const component = response?.data?.data;
					store.dispatch(setBlueprintComponents({ data: [...blueprints.filter(bp => bp.id !== fieldData.component_blueprint_id), component] }, 1));
					subscriber.next(component);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateComponentBlueprintField = fieldData => {
	const data = new FormData();
	data.append('field_id', fieldData.field_id);
	data.append('value', fieldData.value);
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { blueprints } = store.getState().flowReducer;

	const config = {
		...URLS.componentsBlueprint.updateField(last_access_token, fieldData.component_blueprint_id),
		data: fieldData,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.message) {
					const message = response?.data?.message;
					subscriber.next(message);
					subscriber.complete();
				} else if (response?.status === 200 && response?.data && response?.data?.data) {
					const component = response?.data?.data;
					store.dispatch(setBlueprintComponents({ data: [...blueprints.filter(bp => bp.id !== fieldData.component_blueprint_id), component] }, 1));
					subscriber.next(component);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const deleteComponentBlueprintField = (componentId, fieldId) => {
	var data = qs.stringify({
		component_field_id: fieldId,
	});
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.componentsBlueprint.deleteComponentBlueprintFieldbyId(last_access_token, componentId),
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.message) {
					const message = response?.data?.message;
					subscriber.next(message);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const duplicateComponentBlueprint = (componentId, name) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.componentsBlueprint.duplicateComponent(last_access_token, componentId) };

	const data = new FormData();
	data.append('newName', name);

	const config = {
		...defaultConfig,
		//data: data,
		headers: { ...defaultConfig.headers },
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 201 && response?.data) {
					const component = response?.data?.data;
					store.dispatch(setBlueprintComponents(component, 0));

					store.dispatch(setMessageBar({ show: true, message: `You have successfully duplicated component`, top: '0' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getComponentsFilters = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.getComponentsFilters(last_access_token),
		handlerEnabled: true,
	};

	const { filters } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					store.dispatch(setFilters({ ...filters, components: response?.data?.data }));
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getFilteredComponents = sentData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = JSON.stringify({ filters: [...sentData] });
	const config = {
		...URLS.components.getFilteredComponents(last_access_token),
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getComponentsByBlueprintField = fieldId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.components.getComponentsByBlueprint(last_access_token, fieldId),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const createFavorite = (user_id, component_id, model) => {
	const newFavorite = {
		user_id: user_id,
		component_id: component_id,
		model: model,
	};

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.favorites.createFavorite(last_access_token),
		data: newFavorite,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 201 && response?.data?.data) {
					store.dispatch(addFavorite(response?.data?.data));
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const removeFavorite = id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.favorites.deleteFavorite(last_access_token, id),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(deleteFavorite(id));
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getAllFavorites = (page, forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates } = store.getState().flowReducer;

	const config = {
		...URLS.favorites.getAllFavorites(last_access_token, ''),
		//signal: axiosAbort.signal
	};

	//console.log('moment diff favorites', moment(now()).diff(moment(lastUpdates.favorites), 'minutes'), favorites.length);
	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.favorites), 'minutes') > 10) {
			axiosInstance(config)
				.then(response => {
					if (response?.status === 200 && response?.data && response?.data?.data) {
						const favorites = response?.data?.data;

						store.dispatch(setFavorites(favorites, page));
						store.dispatch(setLastUpdate({ ...lastUpdates, favorites: now() }));
						subscriber.next(response?.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
