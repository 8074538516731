export const authDefault = {
	sessionSettings: {
		rememberMe: true,
		expirationDate: '',
		last_access_token: '',
		last_expires_in: 0,
		last_refresh_token: '',
	},
	authenticatedUser: {
		departments: [],
		email: '',
		first_name: '',
		id: '',
		is_admin: true,
		last_name: '',
		photo: null,
		positions: [],
		timezone: '',
		ui_permissions: [],
		user_type: '',
	},
	lastKnownRoute: '',
};
