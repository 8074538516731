import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Calendar } from '../../../assets/images/icons';
import cls from './RangeDatePicker.module.scss';
import classNames from 'classnames';
export const RangeDatePicker = props => {
	const { error, dateChangeHandler, startDate, endDate, inputContainerClassName } = props;

	return (
		<div className={cls.host}>
			<DatePicker
				showYearDropdown
				selected={startDate}
				onChange={dateChangeHandler}
				startDate={startDate}
				endDate={endDate}
				customInput={
					<div className={classNames(cls.customInputContainer, inputContainerClassName)}>
						<input readOnly value={moment(startDate).format('Do of MMMM, YYYY') + ' - ' + moment(endDate).format('Do of MMMM, YYYY')} type="text" id={'range-date'} className={cls.customInput} placeholder={'Select Range...'} />
						<Calendar style={{ padding: '15px' }} />
					</div>
				}
				selectsRange
			/>
			<div>{error?.length > 0 && <p className={cls.errorGeneralText}>{error} </p>}</div>
		</div>
	);
};
