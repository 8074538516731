import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import ProcessOptionsModal from '../../SideMenuEntries/BlueprintsComps/ProcessesTab/ProcessOptionsModal';
import classNames from 'classnames';
import cls from './Onboarding_basic_company.module.scss';
import { isMobile } from 'react-device-detect';
import { Button } from '../../common';

const OnboardingNewProcess = props => {
	const { onPreviousButtonAction, onContinueButtonPressed, onSkiputtonPressed, title = '', subtitle = '' } = props;

	return (
		<div className={cls.new_process_container}>
			<ProcessOptionsModal
				onCreate={() => {
					onContinueButtonPressed();
				}}
				onSkip={onSkiputtonPressed}
				onClose={onPreviousButtonAction}
				isOpen={true}
				modalStyle={{ maxWidth: '500px' }}
				selectedProcess={{ id: '', name: '', description: 'Example workflow on how to create a product sale' }}
				shadowedContainerStyle={{ width: isMobile ? '100%' : '100%', height: '100%', padding: '50px 100px 0 100px' }}
			>
				<div className={cls.headerContainer_III}>
					<h1 className={cls.header_III}>{title}</h1>
				</div>
				<div className={classNames(cls.subheadingContainer_III, cls.justifiedLeft_III)}>
					<p className={cls.subheading_III}>{subtitle}</p>
				</div>
				{props.children}
			</ProcessOptionsModal>
		</div>
	);
};

const mapStateToProps = store => {
	const { companyData } = store.flowReducer;
	const { isOnboarding } = store.modalsReducer;
	return { companyData, isOnboarding };
};

export default connect(mapStateToProps)(OnboardingNewProcess);
