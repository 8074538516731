import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { first } from 'rxjs/operators';
import { setSelectedProStep, setSelectedProStepOptions } from '../../../../../reduxStore/actions/flowActions';
import { useProcessContext, pat } from '../../../../../context/processStep.context';
import { MultiselectWithSearchR, FieldWrapper, RenderDynamicTextInput, RenderYesNoSwitcher, Spinner, SelectWithSearch, RenderTitle, ContainerWrapper } from '../../../../common';
import { RenderStepNewComponents } from './StepComponents';
import { getStepsByProcessId } from '../../../../../services/processes.service';
import { Tag } from '../../../../common/UI/Select/SelectComponents';
import cls from './StepData.module.scss';
import { stepSchema } from '../stepManagement';
import { processImg, stepImg } from '../../../../../assets/images/icons';
import * as R from 'ramda';
import { useMountedState } from '../../../../../shared/utility';
import { MultiSelectWithCheckboxesAndNestedItems_v2 } from '../../../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';

const StepData = props => {
	const { onSetSelectedProStep, selectedProcess, companyPositionsArray, blueprints, companyData, onEditChange = () => {} } = props;
	const [procContext, setInProcContext] = useProcessContext();
	const [parentStepOptions, setParentStepOptions] = useState([]);
	const [loadingParentSteps, setLoadingParentSteps] = useState(false);
	const [pathwayOptions, setPathwayOptions] = useState([]);
	const [pathwaysLoading, setpathwaysLoading] = useState(false);
	const [allPathwayOptions, setAllPathwayOptions] = useState([]);
	const [useTemplates, setUseTemplates] = useState(procContext.selectedStep?.options?.useCustomTemplate ?? false);
	const [sectionErrors, setSectionErrors] = useState({
		parentSteps: '',
		stepName: '',
		estimatedTime: '',
		guidance: '',
		inheritedComponents: '',
		newComponents: '',
		pathwayOptions: '',
	});
	const isMounted = useMountedState();
	useEffect(() => {
		if (procContext.selectedStep?.options?.is_start_next_process == false) {
			setInProcContext({
				type: pat.updateSelectedStepPathways,
				value: [...procContext.selectedStep.pathway_steps],
			});
		}
	}, [procContext.selectedStep?.options?.is_start_next_process]);
	useEffect(() => {
		computeRankings();
	}, [procContext.selectedStep]);

	useEffect(() => {
		setLoadingParentSteps(true);
		getStepsByProcessId({ processId: selectedProcess.id, parentsOnly: 1 })
			.pipe(first())
			.subscribe({
				next: data => {
					if (data?.length > 0) {
						isMounted() &&
							setParentStepOptions([
								...R.compose(
									R.reduce((acc, item) => acc.concat({ rank: item.rank, label: item.name, value: item.id }), []),
									R.reject(R.propEq('id', procContext.selectedStep?.id))
								)(data),
							]);
					} else if (procContext.selectedStep?.isNewStep && !R.find(R.propEq('is_start', true))(procContext?.steps)) {
						isMounted() && setParentStepOptions([]);
					}
					isMounted() && setLoadingParentSteps(false);
					isMounted() && retreivePathWays();
				},
				error: _error => {
					isMounted() && setLoadingParentSteps(false);
				},
			});
	}, []);

	const retreivePathWays = () => {
		if (procContext.selectedStep?.id !== 'newDefaultStep' && procContext?.selectedStep?.id !== '') {
			setpathwaysLoading(true);
			getStepsByProcessId({ processId: selectedProcess.id, pathways: 1, forStepId: procContext.selectedStep?.id })
				.pipe(first())
				.subscribe({
					next: data => {
						if (data) {
							try {
								const validSteps = R.reject(R.propEq('id', procContext?.selectedStep?.id), data?.steps ?? []);
								const validProcesses = R.reject(R.propEq('id', selectedProcess.id), data.processes ?? []) ?? [];
								const pathways = [...(validSteps?.map(i => ({ value: i?.id, label: i?.name, rank: i?.rank, icon: stepImg, type: 'step' })) ?? []), ...(validProcesses?.map(i => ({ value: i?.id, label: i?.name, icon: processImg, type: 'process' })) ?? [])];
								if (pathways) {
									setpathwaysLoading(false);
									setPathwayOptions(pathways);
									setpathwaysLoading(false);
								}
								setAllPathwayOptions([...validSteps, ...validProcesses]);
							} catch (ex) {
								setpathwaysLoading(false);
							}
						} else {
							setParentStepOptions([]);
							setpathwaysLoading(false);
						}
					},
					error: _error => {
						setpathwaysLoading(false);
					},
				});
		}
	};
	const computeRankings = useCallback(() => {}, [procContext.steps, procContext.selectedStep]);
	const initialParentValues = [...R.reduce((a, i) => a.concat({ value: i.id, label: i.name }), [])(procContext?.selectedStep?.parent_steps ?? [])];
	const pathwaysReceived = procContext?.selectedStep?.pathway_processes?.concat(procContext?.selectedStep?.pathway_steps);
	const pathwaysSelected =
		pathwaysReceived?.length > 0
			? R.uniq(pathwaysReceived)
					?.map(i => i?.name)
					.join(', ')
			: '';

	const validateSections = useCallback(
		(section = 'all') => {
			const errorsString = {
				parentSteps: 'Step must be connected to at least one other step',
				stepName: 'Step name is mandatory',
				guidance: 'Short description of step is mandatory',
				inheritedComponents: 'Some inherited component have missing required information',
				newComponents: 'Some new component have missing required informations',
				pathwayOptions: 'Error inside Pathway Options',
			};
			let errors = { ...sectionErrors };
			if (section) {
				switch (section) {
					// case 'parentSteps':
					// 	errors.parentSteps = isStartingStep ? '' : !((!!R.find(R.propEq('is_start', true))(procContext?.steps) && procContext?.selectedStep?.parent_steps?.length > 0) || procContext.selectedStep.is_start == true) ? errorsString.parentSteps : '';
					// 	break;
					case 'stepName':
						errors.stepName = !(typeof procContext?.selectedStep?.name == 'string' && procContext?.selectedStep?.name?.length > 0) ? errorsString.stepName : '';
						break;

					case 'guidance':
						errors.guidance = !(typeof procContext?.selectedStep?.guidance == 'string' && procContext?.selectedStep?.guidance?.length > 0) ? errorsString.guidance : '';
						break;

					case 'newComponents':
						{
							errors.newComponents = Array.isArray(procContext?.selectedStep?.component_blueprints) ? errorsString.newComponents : '';
							const noStatusForBluePrintComponents = procContext?.selectedStep?.component_blueprints?.find(i => !i?.status || i?.status == '' || i?.status?.id == '');
							if (noStatusForBluePrintComponents) {
								errors.newComponents = 'Invalid status in new blueprints components';
							}
							const noTitleForBluePrintComponents = procContext?.selectedStep?.component_blueprints?.find(i => !i?.title || i?.title == '');
							if (noTitleForBluePrintComponents) {
								errors.newComponents += '\nInvalid title in new blueprints components';
							}
						}
						break;
					case 'pathwayOptions':
						errors.pathwayOptions = !(Array.isArray(procContext?.selectedStep?.pathway_steps) && Array.isArray(procContext?.selectedStep?.pathway_processes)) ? errorsString.pathwayOptions : '';
						break;
					default:
						// errors.parentSteps = isStartingStep ? '' : !(!!R.find(R.propEq('is_start', true))(procContext?.steps) && procContext?.selectedStep?.parent_steps?.length > 0) ? errorsString.parentSteps : '';
						errors.stepName = !(typeof procContext?.selectedStep?.name == 'string' && procContext?.selectedStep?.name?.length > 0) ? errorsString.stepName : '';
						errors.guidance = !(typeof procContext?.selectedStep?.guidance == 'string' && procContext?.selectedStep?.guidance?.length > 0) ? errorsString.guidance : '';

						errors.inheritedComponents = !Array.isArray(procContext?.selectedStep?.component_blueprints) ? errorsString.inheritedComponents : '';
						errors.newComponents = !Array.isArray(procContext?.selectedStep?.component_blueprints) ? errorsString.newComponents : '';
						errors.pathwayOptions = !(Array.isArray(procContext?.selectedStep?.pathway_steps) && Array.isArray(procContext?.selectedStep?.pathway_processes)) ? errorsString.pathwayOptions : '';
						break;
				}
			}

			setSectionErrors(errors);
			return errors;
		},
		[procContext?.selectedStep, sectionErrors]
	);

	const isStartingStep = !R.find(R.propEq('is_start', true))(R.reject(R.propEq('id', procContext.selectedStep.id), procContext?.steps)) || procContext?.selectedStep?.is_start == true;
	return (
		<div>
			<RowSection percentualWidth={'100%'} style={{ marginBottom: '10px' }}>
				<RenderTitle title={`Step Name`} />
				<FieldWrapper
					{...stepSchema.stepName}
					title=""
					value={procContext.selectedStep?.name}
					sectionError={sectionErrors.stepName}
					onOpenChanged={isOpen => {
						!isOpen && validateSections('stepName');
					}}
				>
					<RenderDynamicTextInput
						onControlTouched={() => {}}
						placeholder={'Insert step name...'}
						text={procContext.selectedStep?.name}
						onTextChanged={text => {
							setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { name: text } });
							onSetSelectedProStep({ name: text });
						}}
					/>
				</FieldWrapper>
			</RowSection>

			<RowSection percentualWidth={'100%'}>
				<RenderTitle title={'Description'} />
				<FieldWrapper
					{...stepSchema.guidance}
					title=""
					value={procContext.selectedStep?.guidance}
					sectionError={sectionErrors.guidance}
					// onOpenChanged={isOpen => {
					// 	!isOpen && validateSections('guidance');
					// }}
				>
					<RenderDynamicTextInput
						plcHoffset={'0px'}
						text={procContext.selectedStep?.guidance}
						placeholder={'Add description for step here...'}
						onTextChanged={text => {
							setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { guidance: text } });
							onSetSelectedProStep({ guidance: text });
						}}
					/>
				</FieldWrapper>
			</RowSection>
			<RowSection percentualWidth={'100%'} style={{ display: isStartingStep == true ? 'none' : '' }}>
				<RenderTitle title={'Connect Step To'} />
				<FieldWrapper
					{...stepSchema.parentSteps}
					title=""
					readOnly={isStartingStep}
					value={procContext.selectedStep?.parent_steps?.length > 0 ? R.reduce((a, i) => a.concat(i.name), [])(R.uniq(procContext.selectedStep?.parent_steps)).map((i, idx) => <Tag key={'tag' + idx}>{i}</Tag>) : isStartingStep ? <Tag>{'Start of Workflow'}</Tag> : null}
					sectionError={sectionErrors.parentSteps}
					onOpenChanged={isOpen => {
						!isOpen && !isStartingStep && validateSections('parentSteps');
					}}
				>
					{loadingParentSteps && (
						<span style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
							<Spinner size={30} type={'ThreeDots'} />
						</span>
					)}
					{parentStepOptions?.length > 0 && !isStartingStep && (
						<MultiselectWithSearchR
							labelsTag={true}
							tagCloseButtonType={'X'}
							tagType={'step-plated'}
							tagEmplacement={'inlined'}
							triggerType={'eyeglass'}
							initialSelectedItems={initialParentValues}
							placeholder={'Search steps here...'}
							className={cls.multiselectWithSearch}
							inputClassName={cls.inputClassName}
							molclassname={cls.smoMultiselectParent}
							moliclassname={cls.selectMenuItem}
							menuOverlayListItemStyle={{ padding: '10px', maxWidth: '100%' }}
							onChange={opt => {
								setInProcContext({
									type: pat.updateSelectedStepParentSteps,
									value: [...R.reduce((a, i) => a.concat({ rank: i.rank, name: i.label, id: i.value }), [])(opt)],
								});
								setTimeout(computeRankings(), 0);
							}}
							items={parentStepOptions}
						/>
					)}
					{parentStepOptions?.length == 0 && !loadingParentSteps && procContext?.selectedStep.isNewStep == true ? <Tag tagType={'step-plated'}>{'Start of Workflow'}</Tag> : null}
				</FieldWrapper>
			</RowSection>
			<RowSection percentualWidth={'100%'} style={{ marginBottom: '10px', display: 'none' }}>
				<RenderTitle title={'Step Number'} />
				<FieldWrapper
					{...stepSchema.rank}
					title=""
					value={procContext.selectedStep?.rank}
					sectionError={sectionErrors.rank}
					onOpenChanged={isOpen => {
						!isOpen && validateSections('stepRank');
					}}
				>
					<RenderDynamicTextInput
						onControlTouched={() => {}}
						placeholder={'Insert step rank...'}
						text={procContext.selectedStep?.rank}
						onTextChanged={text => {
							setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { rank: +text } });
							onSetSelectedProStep({ rank: +text });
						}}
					/>
				</FieldWrapper>
			</RowSection>
			<RowSection percentualWidth={'100%'} style={{ marginTop: '20px' }}>
				<RenderTitle title={stepSchema.newComponents.title} />
				<RenderStepNewComponents onEditChange={(edit, id) => onEditChange(edit, id)} showAddButton={!useTemplates} selectedProcess={selectedProcess} blueprints={blueprints} companyPositionsArray={companyPositionsArray} />
			</RowSection>

			<RowSection percentualWidth={'100%'} style={{ display: 'none' }}>
				<FieldWrapper
					{...stepSchema.pathwayOptions}
					loading={pathwaysLoading}
					readOnly={pathwayOptions?.length === 0}
					placeholder={pathwayOptions?.length === 0 ? 'No branch steps available!' : 'Select steps or processes to jump to...'}
					value={pathwaysSelected}
					sectionError={sectionErrors.pathwayOptions}
					onOpenChanged={isOpen => {
						!isOpen && validateSections('pathwayOptions');
					}}
				>
					{pathwaysLoading ? (
						<span style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
							<Spinner size={30} type={'ThreeDots'} />
						</span>
					) : (
						<MultiselectWithSearchR
							labelsTag={true}
							placeholder={'Search for steps or processes here...'}
							tagCloseButtonType={'circleX'}
							tagType={'plated'}
							tagEmplacement={'inlined'}
							triggerType={'eyeglass'}
							initialSelectedItems={pathwayOptions.filter(i => procContext?.selectedStep?.pathway_processes?.concat(procContext?.selectedStep?.pathway_steps)?.filter(e => e.id == i.value)?.length > 0)}
							className={cls.multiselectWithSearch}
							leftIconItemContainerClassName={cls.leftIconItemContainerClassName}
							inputContainerClassname={cls.smoMultiselectInputContainerPathways}
							inputClassName={cls.inputClassName}
							molclassname={cls.smoMultiselectPathway}
							moliclassname={cls.selectMenuItem}
							onChange={opt => {
								const selectedIds = [...opt.map(i => i.value)];
								const pathways = allPathwayOptions.filter(i => selectedIds?.indexOf(i.id) > -1);
								setInProcContext({ type: pat.updateSelectedStepPathways, value: [...R.uniq(pathways)] });
							}}
							items={procContext.selectedStep?.options?.is_start_next_process == false ? R.reject(R.propEq('type', 'process'), pathwayOptions ?? []) : pathwayOptions}
						/>
					)}
				</FieldWrapper>
			</RowSection>
			<RowSection percentualWidth={'100%'} style={{ marginTop: '20px', display: procContext.selectedStep?.is_end == true || procContext.selectedStep?.is_start == true ? 'none' : '' }}>
				<RenderYesNoSwitcher
					{...stepSchema.showAsPopup}
					style={{ margin: '20px 2.5% 0 5px', width: 'auto' }}
					value={procContext.selectedStep?.options?.popup ?? false}
					toggleSelected={val => {
						setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { options: { ...procContext.selectedStep?.options, popup: val } } });
						onSetSelectedProStep({ options: { ...procContext.selectedStep?.options, popup: val } });
					}}
				/>
			</RowSection>
			
			<RowSection percentualWidth={'100%'} style={{ marginTop: '20px', display: procContext.selectedStep?.is_end == true || procContext.selectedStep?.is_start == true ? 'none' : '' }}>
				<RenderYesNoSwitcher
					{...stepSchema.deleteOnBack}
					style={{ margin: '20px 2.5% 0 5px', width: 'auto' }}
					value={procContext.selectedStep?.options?.deleteOnBack ?? false}
					toggleSelected={val => {
						setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { options: { ...procContext.selectedStep?.options, deleteOnBack: val } } });
						onSetSelectedProStep({ options: { ...procContext.selectedStep?.options, deleteOnBack: val } });
					}}
				/>
			</RowSection>

			
			<RowSection percentualWidth={'100%'} style={{ marginTop: '20px', display: procContext.selectedStep?.is_end == true ? 'none' : '' }}>
				<RenderYesNoSwitcher
					{...stepSchema.showStepAll}
					style={{ margin: '20px 2.5% 0 5px', width: 'auto' }}
					value={procContext.selectedStep?.options?.showStepAll ?? true}
					toggleSelected={val => {
						setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { options: { ...procContext.selectedStep?.options, showOnly: [], showStepAll: val } } });
						onSetSelectedProStep({ options: { ...procContext.selectedStep?.options, showOnly: [], showStepAll: val } });
					}}
				/>
			</RowSection>
			<ContainerWrapper style={{ display: procContext.selectedStep?.options?.showStepAll == false ? '' : 'none' }} ungrouped title={'Only show this step to:'} columned withAckButton={false}>
				<br />
				<MultiSelectWithCheckboxesAndNestedItems_v2
					tagEmplacement={'inlined'}
					searchable={false}
					items={companyPositionsArray}
					groupedBy={'department'}
					groupname={'department'}
					triggerType={'chevron'}
					initialSelectedValues={procContext.selectedStep?.options?.showOnly?.map(d => {
						return companyPositionsArray.find(item => item.value === d);
					})}
					className={cls.peoplePositionsSelect}
					inputClassName={cls.peoplePositionsSelectInput}
					molclassname={cls.peoplePositionsSelectMenuOverlay}
					moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
					onChange={selection => {
						setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { options: { ...procContext.selectedStep?.options, showOnly: selection?.map(i => i?.value) } } });
						onSetSelectedProStep({ options: { ...procContext.selectedStep?.options, showOnly: selection?.map(i => i?.value) } });
					}}
				/>
			</ContainerWrapper>
			<RenderYesNoSwitcher
				{...stepSchema.isFinalStep}
				style={{ margin: '20px 2.5% 0 5px', width: 'auto' }}
				value={procContext.selectedStep?.is_end}
				toggleSelected={val => {
					if (val == true) {
						onSetSelectedProStep({ child_steps: [] });
						setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { is_end: val, options: { ...procContext.selectedStep?.options, showOnly: [], showStepAll: true } } });
						onSetSelectedProStep({ is_end: val, options: { ...procContext.selectedStep?.options, showOnly: [], showStepAll: true } });
					} else {
						setInProcContext({ type: pat.updateSelectedStepKeyValue, value: { is_end: val } });
						onSetSelectedProStep({ is_end: val });
					}
				}}
			/>
		</div>
	);
};
const RowSection = props => {
	const { percentualWidth, children, style, ...rest } = props;
	return (
		<div style={{ width: percentualWidth, ...style }} {...rest}>
			{children}
		</div>
	);
};
const mapStateToProps = store => {
	const { blueprints, allStatutes, selectedProcess, selectedProStep, companyData, companyPositionsArray } = store.flowReducer;
	return { blueprints, allStatutes, selectedProcess, selectedProStep, companyData, companyPositionsArray };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetSelectedProStep: selectedStep => dispatch(setSelectedProStep(selectedStep)),
		onSetSelectedProStepOptions: selectedStep => dispatch(setSelectedProStepOptions(selectedStep)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(StepData);
