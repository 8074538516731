import moment from 'moment';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { unreadCountFetch, allFetch, unreadFetch, readAllFetch, readNotifFetch } from '../services/notifications.service';
import { useSelector } from 'react-redux';
import { store } from '../reduxStore/store';
const R = require('ramda');

type RenderContext = {
	unreadNotificationsCount: { counted: number; loading: boolean };
	renderUnreadOnly: boolean;
	renderNotifications?: { isUnread: boolean; message: string; id: string }[];
	canFetchMore: boolean;
	showMarkAllAsRead: boolean;
	listVisible: boolean;
};
type OnClickNotificationType = (notificationId: string) => Promise<boolean>;
type NotificationsContext = [
	renderContext: RenderContext,
	loading: boolean,
	onReadAllPressed: () => void,
	onShowOnlyUnread: (e: { target: { checked?: boolean } & any }) => void,
	onLoadMore: () => void,
	onClickNotification: OnClickNotificationType,
	setListVisible: (val: boolean) => void
];

const NotificationsContext = createContext<NotificationsContext>([
	{ unreadNotificationsCount: { counted: 0, loading: false }, renderUnreadOnly: false, renderNotifications: [], canFetchMore: false, showMarkAllAsRead: false, listVisible: false },
	false,
	() => {},
	() => {},
	() => {},
	() => Promise.resolve(true),
	() => {},
]);

export default function NotificationsProvider({ children }: { children: ReactNode }) {
	const [renderContext, setRenderContext] = useState<RenderContext>({
		unreadNotificationsCount: { counted: 0, loading: false },
		renderUnreadOnly: false,
		renderNotifications: [],
		canFetchMore: false,
		showMarkAllAsRead: true,
		listVisible: false,
	});

	const {
		authenticated
	} = store.getState().sessionReducer;

	const [rawAPIResponse, setRawAPIResponse] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [lastMeta, setLastMeta] = useState<MetaType | undefined>();
	const [actualPage, setActualPage] = useState<number>(1);

	let interval: NodeJS.Timeout;
	const [tickLoop, setTickLoop] = useState(0);

	useEffect(() => {
		try {
			if (!authenticated){
				clearInterval(interval);
				clearTimeout(interval);
				return;
				
			}
			interval && clearInterval(interval);
			interval = setInterval(() => setTickLoop(Math.random()), 60 * 1000);
			(async () => await refreshNotifications(actualPage))();
			return () => {
				interval && clearInterval(interval);
			};
		} catch (error) {
			console.warn('timeout',error);
		}
	}, [authenticated]);
	useEffect(() => {
		refreshUnreadNotifications(undefined);
	}, [tickLoop]);
	useEffect(() => {
		if (!renderContext.listVisible) {
			refreshUnreadNotifications();
		}
	}, [renderContext.listVisible]);
	useEffect(() => {
		(() => {
			if (!lastMeta) return;
			setRenderContext(c => ({ ...c, canFetchMore: lastMeta?.current_page < lastMeta?.last_page }));
			setActualPage(lastMeta.current_page);
		})();
	}, [lastMeta]);

	useEffect(() => {
		(() => {
			const newRenderedNotifs = rawAPIResponse.map((notif: NotificationType) => {
				let composed = notif.body.message;
				const replacements = notif?.body?.objects;
				if (replacements instanceof Array && hasElements(replacements)) {
					replacements?.map((i: NotifMessageObject) => {
						composed = replaceLink(composed, i.placeholder, i.name ?? i.first_name + ' ' + i.last_name, makeHref(i.type, i.id));
					});
				} else if (isNotifMessageObject(replacements)) {
					composed = replaceLink(composed, replacements?.placeholder, replacements?.name ?? replacements?.first_name + ' ' + replacements?.last_name, makeHref(replacements?.type, replacements?.id));
				}
				return { isUnread: !moment(notif.read_at).isValid(), message: `<p>${composed}</p>`, id: notif.id, objects: notif?.body?.objects };
			});

			setRenderContext(c => ({ ...c, renderNotifications: newRenderedNotifs }));
		})();
	}, [rawAPIResponse]);

	const setListVisible = (value: boolean) => {
		setRenderContext(c => ({ ...c, listVisible: value }));
	};
	const setUnreadNotifCountLoading = (value: boolean) => setRenderContext(c => ({ ...c, unreadNotificationsCount: { ...c.unreadNotificationsCount, loading: value } }));
	const refreshUnreadNotifications = useCallback(
		async (onSuccess?: () => void) => {
			if (renderContext.listVisible) {
				return;
			}
			setUnreadNotifCountLoading(true);
			const response = await unreadCountFetch();
			if (response?.data?.count !== renderContext.unreadNotificationsCount.counted) {
				onSuccess && onSuccess();
				setRenderContext(c => ({
					...c,
					unreadNotificationsCount: { counted: response?.data?.count ?? 0, loading: false },
				}));
			} else {
				setUnreadNotifCountLoading(false);
			}
		},
		[renderContext.unreadNotificationsCount.counted, renderContext.listVisible]
	);

	const computeRefreshRawResponse = useCallback(
		(newRawData: any[]) => {
			const removeReceivedFromCurrent = rawAPIResponse?.length > 0 ? R.reduce((acc: any[], i: any) => R.any(R.propEq('id', i.id))(newRawData) ? acc : R.append(i, acc), [], rawAPIResponse ?? []) : [];
			setRawAPIResponse([...sortRawRes([...removeReceivedFromCurrent, ...newRawData ?? []])]);
		},
		[rawAPIResponse]
	);

	const refreshNotifications = useCallback(
		async (page?: number) => {
			setLoading(true);
			const response: any = renderContext.renderUnreadOnly === true ? await unreadFetch(page) : await allFetch(page);
			if (response) {
				if (page == 1) {
					response?.data && setRawAPIResponse([...sortRawRes(response.data)]);
				} else {
					computeRefreshRawResponse(response.data);
				}
				response?.meta && setLastMeta(response.meta);
				setLoading(false);
			} else {
				setLoading(false);
			}
		},
		[renderContext.renderUnreadOnly, rawAPIResponse]
	);
	const onShowOnlyUnread = async (e: { target: { checked?: boolean } & any }) => {
		setRenderContext(c => ({ ...c, renderUnreadOnly: e.target.checked }));
		setRawAPIResponse([]);
		setLastMeta(undefined);
		setLoading(true);

		const response: any = e.target.checked == true ? await unreadFetch(1) : await allFetch(1);
		response?.data && setRawAPIResponse([...sortRawRes(response.data)]);
		response?.meta && setLastMeta(response?.meta);
		setLoading(false);
	};

	const onReadAllPressed = async () => {
		setLoading(true);
		setRawAPIResponse([]);
		const responseReadAll: any = await readAllFetch();
		if (responseReadAll) {
			setRenderContext(c => ({
				...c,
				unreadNotificationsCount: { counted: 0, loading: false },
				showMarkAllAsRead: false,
			}));
			refreshNotifications(1);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const onClickNotification: OnClickNotificationType = useCallback(
		async (notificationId: string) => {
			if (!renderContext?.renderNotifications || !isArray(renderContext?.renderNotifications)) return false;
			const responseReadNotif: any = await readNotifFetch(notificationId);
			if (!responseReadNotif) {
				return false;
			}
			const notif = renderContext?.renderNotifications?.find(i => i.id === notificationId);
			const index = (notif && renderContext?.renderNotifications.indexOf(notif)) ?? -1;
			if (index > -1) {
				const newNotifs = [...renderContext.renderNotifications.slice(0, index), { ...notif, isUnread: false }, ...renderContext.renderNotifications.slice(index + 1)] as RenderContext['renderNotifications'];
				setRenderContext(c => ({
					...c,
					renderNotifications: newNotifs,
					showMarkAllAsRead: Boolean(newNotifs?.some(i => i.isUnread === true)),
				}));
				if (renderContext.renderUnreadOnly && lastMeta && newNotifs && lastMeta.per_page > newNotifs?.filter(i => i.isUnread)?.length && lastMeta?.current_page < lastMeta?.last_page) {
					refreshNotifications(lastMeta?.current_page + 1);
				}
				return true;
			} else {
				return false;
			}
		},
		[renderContext.renderNotifications, lastMeta, renderContext.renderUnreadOnly]
	);

	const onLoadMore = useCallback(async () => {
		if (!lastMeta) return;
		await refreshNotifications(lastMeta?.current_page < lastMeta?.last_page ? lastMeta?.current_page + 1 : lastMeta?.last_page);
	}, [lastMeta]);

	return <NotificationsContext.Provider value={[renderContext, loading, onReadAllPressed, onShowOnlyUnread, onLoadMore, onClickNotification, setListVisible]}>{children}</NotificationsContext.Provider>;
}

export const getUnreadCount = (renderNotifications: RenderContext['renderNotifications']) => {
	let occurs = 0;
	if (renderNotifications) {
		for (let i = 0; i < renderNotifications.length; i++) {
			if ('isUnread' in renderNotifications[i] && renderNotifications[i].isUnread === true) occurs++;
		}
	}
	return occurs;
};
const sortRawRes = (rawRes: any[]) => R.sortBy(R.descend(R.prop('created_at')), rawRes ?? []);
type MetaType = {
	current_page: number;
	from: number;
	last_page: number;
	links: {
		url: string;
		label: string;
		active: boolean;
	}[];
	path: string;
	per_page: number;
	to: number;
	total: number;
};
type NotifMessageObject = {
	id: string;
	name?: string;
	first_name?: string;
	last_name?: string;
	placeholder: string;
	type: 'position' | 'department' | 'user' | 'task';
};
type NotificationType = {
	id: string;
	type: string;
	body: {
		message: string;
		objects: NotifMessageObject | NotifMessageObject[];
	};
	read_at: string | null;
	created_at: string;
};
function isNotifMessageObject(obj: NotifMessageObject | any): obj is NotifMessageObject {
	return ['position', 'department', 'user', 'task'].indexOf(obj.type) > -1;
}
function isArray(object: any): boolean {
	if (Array.isArray) return Array.isArray(object);
	else return object.constructor === Array;
}
const hasElements = (object: any) => {
	return isArray(object) && object.length > 0;
};
const makeHref = (element: NotifMessageObject['type'], id: string) => {
	let href = '';
	switch (element) {
		case 'position':
			href = `/chart?positionId=${id}`;
			break;
		case 'department':
			href = '/chart';
			break;
		case 'user':
			href = `/company?userId=${id}`;
			break;
		case 'task':
			href = `/tasks?taskId=${id}`;
	}
	return href;
};
const replaceLink = (rawMessage: string, placeholder: string, value: string, href: string) => {
	const indexOfReplacement = rawMessage.indexOf(placeholder);
	return [...rawMessage.slice(0, indexOfReplacement), `<a>${value}</a>`, ...rawMessage.slice(indexOfReplacement + placeholder?.length)].join('');
};

export const useCreateRenderMessages = (rawResponse: NotificationType[], setRenderContext: React.Dispatch<React.SetStateAction<RenderContext>>) => {
	useEffect(() => {
		(() => {
			setRenderContext(c => ({
				...c,
				renderNotifications: rawResponse.map((notif: NotificationType) => {
					let composed = notif.body.message;
					const replacements = notif?.body?.objects;

					if (replacements instanceof Array && hasElements(replacements)) {
						replacements?.map((i: NotifMessageObject) => {
							composed = replaceLink(composed, i.placeholder, i.name ?? i.first_name + ' ' + i.last_name, makeHref(i.type, i.id));
						});
					} else if (isNotifMessageObject(replacements)) {
						composed = replaceLink(composed, replacements?.placeholder, replacements?.name ?? replacements?.first_name + ' ' + replacements?.last_name, makeHref(replacements?.type, replacements?.id));
					}
					return { isUnread: moment(notif.read_at).isValid(), message: `<p>${composed}</p>`, id: notif.id };
				}),
			}));
		})();
	}, [rawResponse]);
};

export function useNotifications() {
	return useContext(NotificationsContext);
}
