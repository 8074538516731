import GoogleMapReact from 'google-map-react';
import cls from './MapView.module.scss';
import { useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';

import { setKey } from 'react-geocode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarSide, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { getDistanceBetweenPoints } from '../../../shared/utility';
import { isMobile } from 'react-device-detect';
import { Observable } from 'rxjs';

const R = require('ramda');

const MapView = props => {
	const { pins = { lat: 0, lng: 0, address: 'Nowhere' }, mapkey = '', current, direction } = props;
	const averageLng = (Math.max(...pins.map(pin => pin.lng)) + Math.min(...pins.map(pin => pin.lng))) / 2;
	const averageLat = (Math.max(...pins.map(pin => pin.lat)) + Math.min(...pins.map(pin => pin.lat))) / 2;

	const PinMarker = ({ text, tooltip, type = 'source' }) => (
		<div className={cls.circle}>
			<span className={cls.circleText} title={tooltip}>
				<FontAwesomeIcon icon={type === 'realtime' ? faCarSide : type === 'destination' ? faLocationDot : faLocationDot} style={{ color: type === 'realtime' ? 'black' : type === 'destination' ? 'blue' : 'green', width: '30px', height: '30px' }} />
			</span>
		</div>
	);

	const getMapBounds = (map, maps, pins) => {
		const bounds = new maps.LatLngBounds();

		pins.forEach(place => {
			bounds.extend(new maps.LatLng(place.lat, place.lng));
		});
		return bounds;
	};

	// Re-center map when resizing the window
	const bindResizeListener = (map, maps, bounds) => {
		maps.event.addDomListenerOnce(map, 'idle', () => {
			maps.event.addDomListener(window, 'resize', () => {
				map.fitBounds(bounds);
			});
		});
	};

	const apiIsLoaded = (map, maps, pins) => {
		const bounds = getMapBounds(map, maps, pins);
		map.fitBounds(bounds);
		bindResizeListener(map, maps, bounds);
	};

	return (
		<div style={{ height: '500px', width: '100%', margin: isMobile ? '0' : '20px' }}>
			<div style={{ marginTop: '20px', height: 'inherit', width: 'inherit' }}>
				<GoogleMapReact
					onClick={ev => {}}
					bootstrapURLKeys={{
						// key: mapkey,
						key: '',
						language: 'en',
						region: 'US',
					}}
					center={{ lat: isNaN(averageLat) ? 0 : +averageLat, lng: isNaN(averageLng) ? 0 : +averageLng }}
					defaultZoom={15}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, pins)}
				>
					{pins.length > 0 && pins.map((pin, index) => <PinMarker key={index} lat={pin.lat} lng={pin.lng} text={pin.address} tooltip={pin.address} type={pin.type} />)}
					{direction && <DirectionsRenderer direction={direction} />}
				</GoogleMapReact>
			</div>
		</div>
	);
};

export default MapView;

export const calculateRoute = (source, destination) => {
	if (source === '' || destination === '') {
		return;
	}
	const directionsService = new window.google.maps.DirectionsService();

	return new Observable(subscriber =>
		directionsService
			?.route({
				origin: source,
				destination: destination,
				travelMode: window.google.maps.TravelMode.DRIVING,
			})
			.then(response => {
				subscriber.next(response);
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
