import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import { CheckedStyle, CloseIcon2, InformationInfo, TaskBoard, TaskBoardWhite } from '../../../../assets/images/icons';
import { useStateRef } from '../../../../shared/utility';
import { useOLContext, OLContext, useListItemsRenderedContext } from '../../../../context/appRender.context';
import UnfocusableWrapper from '../../UnfocusableWrapper/UnfocusableWrapper';
import cls from './ContentEditorWrapper.module.scss';
import classNames from 'classnames';
import * as R from 'ramda';
const RenderTitle = props => {
	const { title, tooltipText, tooltipPlace } = props;
	const tooltipDivRef = useRef(null);
	return (
		<div className={cls.renderTitle}>
			<p className={cls.title}>{title}</p>
			<div ref={tooltipDivRef} id="tooltip" className={cls.tooltipContainer}>
				<InformationInfo data-tip={tooltipText} className={cls.tooltipIcon} />
				<ReactTooltip multiline effect={'float'} type={'dark'} clickable={true} textColor={'#4a4a4a'} backgroundColor={'#fff'} borderColor={'#4a4a4a'} className={cls.tooltip} place={tooltipPlace ? tooltipPlace : tooltipDivRef?.current?.offsetTop < 50 ? 'bottom' : 'top'} />
			</div>
		</div>
	);
};
/* text input component with multiline and textArea */
const RenderDinamicText = props => {
	const { placeholder, readonly, text, onTextChanged, onControlTouched, legacy, className, textAreaClass } = props;
	const textAreaRef = useRef();
	const [textAreaValue, setTextAreaValue] = useState(text ?? '');
	const textAreaLabelStyle =
		'position: absolute; top: 35%; left: 10px; right: 0;/*  bottom: 0, */ userSelect: none; pointerEvents: none; display: block; color: var(--app-color-gray-light); fontFamily: Quicksand; font-size: 16px; font-weight: 400; letter-spacing: 0; line-height: 20px;';
	const textAreaOnChange = text => {
		setTextAreaValue(text);
		onTextChanged(text);
	};
	useLayoutEffect(() => {
		// Reset height - important to shrink on delete
		textAreaRef.current.style.height = 'inherit';
		// Set height
		textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 20)}px`;
	}, [textAreaValue]);
	return (
		<div className={classNames(cls.dinamicText, className)}>
			{legacy ? (
				<textarea
					ref={textAreaRef}
					type="text"
					name="textarea"
					readOnly={readonly}
					className={classNames(cls.textArea, textAreaClass)}
					style={{ color: textAreaValue?.length > 0 ? ' #4A4A4A' : 'var(--app-color-gray-light)', maxHeight: '88vh' }}
					value={textAreaValue}
					onBlur={e => {
						if (textAreaValue?.length < 1) {
							if (e.target.value !== '') e.target.nextSibling.style = 'display:none;';
							else e.target.nextSibling.style = textAreaLabelStyle;
						}
						onControlTouched && onControlTouched();
					}}
					onFocus={e => {
						if (textAreaValue?.length < 1) e.target.nextSibling.style = `${'display:none;'}`;
					}}
					onChange={e => {
						textAreaOnChange(e.target.value);
					}}
				/>
			) : (
				<TextareaAutosize
					ref={textAreaRef}
					type="text"
					name="textarea"
					readOnly={readonly}
					className={classNames(cls.textArea, textAreaClass)}
					style={{ color: textAreaValue?.length > 0 ? ' #4A4A4A' : 'var(--app-color-gray-light)', maxHeight: '90vh' }}
					value={textAreaValue}
					onBlur={e => {
						if (textAreaValue?.length < 1) {
							if (e.target.value !== '') e.target.nextSibling.style = 'display:none;';
							else e.target.nextSibling.style = textAreaLabelStyle;
						}
						onControlTouched && onControlTouched();
					}}
					onFocus={e => {
						if (textAreaValue?.length < 1) e.target.nextSibling.style = `${'display:none;'}`;
					}}
					onChange={e => {
						textAreaOnChange(e.target.value);
					}}
				/>
			)}
			{textAreaValue?.length < 1 && <label className={cls.placeholder}>{placeholder}</label>}
		</div>
	);
};
/* isolated text input framed entry */
export const FramedTextArea = props => {
	const { title, toogleEdit, tooltipText, placeholder, text, onTextChanged, tooltipPlace } = props;
	const [editMode, setEditMode] = useState(false);
	const [inputValue, setInputValue] = useState(text);
	const [controlTouched, setControlTouched] = useState(false);

	const setting = value => setEditMode(value);
	useEffect(() => {
		setting(toogleEdit);
	}, [toogleEdit]);
	const textAreaOnChange = text => {
		setInputValue(text);
	};
	useEffect(() => {
		if (editMode == false && controlTouched && onTextChanged) {
			onTextChanged(inputValue);
		}
	}, [editMode]);

	return editMode ? (
		<UnfocusableWrapper
			onClickOutside={() => {
				/* setEditMode(false) */
			}}
			className={cls.framedTextAreaEditHost}
			app-edit-mode={editMode ? 'true' : 'false'}
			style={{ border: editMode ? '1px solid var(--app-color-gray-lighter)' : 'none' }}
		>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
				<RenderTitle title={title + ': '} tooltipText={tooltipText} tooltipPlace={tooltipPlace} />
				<div
					onClick={() => {
						setEditMode(false);
						onTextChanged(inputValue);
					}}
					style={{ margin: '8px 10px 0 0', display: 'flex', width: '32px', height: '32px', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', cursor: 'pointer' }}
				>
					<CheckedStyle style={{ width: '32px', height: '32px' }} />
				</div>
			</div>
			<RenderDinamicText legacy onControlTouched={() => setControlTouched(true)} placeholder={placeholder} text={inputValue} onTextChanged={textAreaOnChange} />
		</UnfocusableWrapper>
	) : (
		<div
			onClick={() => {
				setEditMode(true);
			}}
			style={{ width: '100%', padding: '0px', border: 'none', borderRadius: '4px' }}
		>
			<div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
				<RenderTitle title={title} tooltipText={tooltipText} tooltipPlace={tooltipPlace} />
			</div>
			<div className={cls.framedTextAreaDinamicText}>
				<pre
					style={{
						boxSizing: 'border-box',
						width: '100%',
						maxWidth: '100%',
						border: 'none',
						borderRadius: '8px',
						color: inputValue?.length > 0 ? '#4A4A4A' : 'var(--app-color-gray-light)',
						fontFamily: 'var(--app-text-main-font)',
						fontSize: '1.1em',
						fontWeight: '500',
						position: 'relative',
						padding: '0px 10px 0px 0px',
						whiteSpace: 'pre-wrap',
						overflowWrap: 'break-word',
						maxHeight: '90vh',
						height: '80vh',
						overflowY: 'auto',
						cursor: 'context-menu',
					}}
				>
					{inputValue?.length > 0 ? inputValue : placeholder}
				</pre>
			</div>
		</div>
	);
};
export const FramedListItems = props => {
	const { readOnly, title, tooltipText, toogleEdit, onListChanged, onListSaved } = props;
	const [context, setInContext] = useListItemsRenderedContext();

	const [editMode, setEditMode] = useState(false);
	const [contextState, setRefreshAtChangeContext] = useState(false);
	const [rederedItems, setRenderedItems] = useState([...context.items]);

	const setRenderedValues = values => setInContext(c => ({ ...c, items: [...values] }));
	useEffect(() => {
		(() => setEditMode(toogleEdit))();
	}, [toogleEdit]);
	const onAreaSave = useCallback(() => {
		onListSaved?.(rederedItems);
		setEditMode(false);
	}, [rederedItems]);

	useEffect(() => {
		(() => setRefreshAtChangeContext(c => !c))();
	}, [context]);
	useEffect(() => {
		(() => setRefreshAtChangeContext([...context.items]))();
	}, [context.items]);
	setRenderedItems;
	const onDeleteItem = useCallback(
		(deleteItem, index) => {
			const composeArray = source => [...source.slice(0, index), ...source.slice(index + 1)];

			setRenderedItems(c => [...composeArray(c)]);
			setRenderedValues([...composeArray(context.items)]);
		},
		[context.items, rederedItems]
	);
	const onEditedItem = useCallback(
		(editedItem, index) => {
			const composeArray = source => [...source.slice(0, index), editedItem, ...source.slice(index + 1)];
			setRenderedItems(c => [...composeArray(c)]);
			setRenderedValues([...composeArray(context.items)]);
		},
		[context.items]
	);
	const onAddItem = useCallback(
		newItem => {
			setRenderedItems([...R.append(newItem, rederedItems)]);
			setRenderedValues([...R.append(newItem, context.items)]);
		},
		[rederedItems, context.items]
	);

	return editMode ? (
		<UnfocusableWrapper
			className={cls.framedListEditing}
			onClickOutside={() => {
				/* setEditMode(false) */
			}}
		>
			<div className={cls.header}>
				<RenderTitle title={title} tooltipText={tooltipText} />
				<div onClick={onAreaSave} className={cls.saveButton}>
					<CheckedStyle className={cls.buttonIco} />
				</div>
			</div>
			<OLContext key={'entry-list'}>
				{rederedItems?.length > 0 ? (
					<div className={cls.content}>
						{rederedItems.map((item, index) => (
							<RenderListItem key={index + contextState} id={index + item?.toString()} unfocused={!readOnly} value={item} index={index} onEditedItem={onEditedItem} onDeleteItem={onDeleteItem} />
						))}
					</div>
				) : (
					<p className={cls.renderListFramedEntryEmptyItem}>{'[Empty]'}</p>
				)}
			</OLContext>
			<div className={cls.renderListFramedEntryRenderAddListItemWrapper}>
				<RenderAddListItemField onAddItem={onAddItem} />
			</div>
		</UnfocusableWrapper>
	) : (
		<div
			onClick={() => {
				setEditMode(true);
				onListChanged();
			}}
			className={cls.framedListView}
		>
			<div className={cls.header}>
				<RenderTitle title={title} tooltipText={tooltipText} />
			</div>
			{rederedItems && (
				<div className={cls.content}>
					{rederedItems?.map((item, index) => {
						return (
							<pre key={index} className={cls.text}>
								{index + 1}
								{'. '}
								{item}
							</pre>
						);
					})}
				</div>
			)}
		</div>
	);
};

export const RenderListItem = props => {
	const { id, value, index, readOnly, unfocused, onDeleteItem, onEditedItem } = props;

	const textareaRef = useRef(null);
	const [editState, setEditState] = useOLContext();
	const [editMode, setEditMode] = useState(false);
	const [showButtons, setShowButtons] = useState(false);
	const [inputValue, setInputValue] = useState(value ?? '');
	useEffect(() => {
		if (editState.activeElement == id && !readOnly) {
			openingEditMode();
		} else {
			closingEditMode();
		}
	}, [id, editState.activeElement]);

	const openingEditMode = useCallback(() => {
		if (!readOnly) {
			setShowButtons(true);
			setEditMode(true);
			setEditState({ open: true, activeElement: id });
		}
	}, [id, readOnly]);

	const closingEditMode = () => {
		if (showButtons) {
			setShowButtons(false);
		}
		if (editMode) {
			setEditMode(false);
			setEditState({ open: true, activeElement: '' });
		}
	};
	useEffect(() => closingEditMode(), [unfocused]);

	const textAreaOnChange = text => {
		setInputValue(text);
	};
	const onSaveInputValue = useCallback(() => {
		setShowButtons(false);
		setEditMode(false);
		onEditedItem(inputValue, index);
	}, [inputValue]);

	const onDeleteInputValue = useCallback(() => {
		onDeleteItem(inputValue, index);
		setShowButtons(false);
		setEditMode(false);
	}, [inputValue, index]);

	return (
		<UnfocusableWrapper className={cls.renderListItemWrapper} onClick={openingEditMode} onClickOutside={() => closingEditMode()}>
			<div className={cls.renderListItemContainer} app-variant-show-buttons={showButtons ? 'true' : 'false'}>
				<div className={cls.renderListItemLabelContainer}>
					<label className={cls.renderListItemItemNumber} app-variant-show-buttons={showButtons ? 'true' : 'false'}>
						{+index + 1 + '.'}
					</label>
				</div>
				<div className={cls.renderListItemTextareaContainer}>
					<TextareaAutosize
						ref={textareaRef}
						type="text"
						name="textarea"
						className={cls.renderListItemTextarea}
						placeholder={'   insert value for persistence'}
						style={{
							color: !showButtons ? '#4A4A4A' : '#4A4A4A',
							backgroundColor: !showButtons ? '#FFFFFF' : '#D1D1D1',
							padding: !showButtons ? '0px 0px 0 5px' : inputValue?.split('\n')?.length < 1 ? '0' : '5px 0 0 0',
						}}
						value={inputValue}
						onChange={e => textAreaOnChange(e.target.value)}
					/>
				</div>
				{showButtons && (
					<div className={cls.renderListItemAnnotationWrapper}>
						<button className={cls.renderListItemSaveButton} onClick={onSaveInputValue}>
							<TaskBoard />
							<p>{'SAVE'}</p>
						</button>
						<button className={cls.renderListItemDeleteButton} onClick={onDeleteInputValue}>
							<CloseIcon2 />
						</button>
					</div>
				)}
			</div>
		</UnfocusableWrapper>
	);
};
export const RenderAddListItemField = props => {
	const { unfocused, onAddItem } = props;
	const [editMode, setEditMode] = useState(false);
	const [inputValue, setInputValue, inputValueRef] = useStateRef('');

	useEffect(() => {
		(() => setEditMode(false))();
	}, [unfocused]);
	const saveInputValue = useCallback(() => {
		if (inputValue?.length > 0) {
			onAddItem?.(inputValue);
			setInputValue('');
		} else if (inputValueRef?.current?.length > 0) {
			onAddItem?.(inputValueRef?.current);
			setInputValue('');
		}
	}, [inputValue]);

	return (
		<div onClick={() => setEditMode(true)} onMouseLeave={() => setEditMode(false)} className={cls.renderAddListItemWrapper}>
			<div className={cls.renderAddListItemInputContainer}>
				<div className={cls.renderAddListItemAnnotationWrapper}>
					<button type={'button'} className={cls.renderAddListItemSaveButton} onClick={saveInputValue}>
						<TaskBoardWhite />
						<p>{'ADD'}</p>
					</button>
				</div>
				<TextareaAutosize
					className={cls.renderAddListItemInputStyle}
					type="text"
					name={'addListItem'}
					placeholder={'(insert item text and press ADD button or SHIFT+ENTER keys to add new item)'}
					value={inputValue}
					readOnly={!editMode}
					onChange={e => setInputValue(e.target.value)}
					onFocus={() => {}}
					onBlur={() => {
						/* setInputValue('') */
					}}
					onKeyDown={e => {
						if (e.key === 'Enter' && e.shiftKey /*|| && event.ctrlKey  */) {
							saveInputValue();
						}
					}}
				/>
				<div className={cls.renderAddListItemAnnotationWrapper}>
					{inputValue?.length > 0 && (
						<button type={'button'} className={cls.renderAddListItemCancelButton} onClick={() => setInputValue('')}>
							<CloseIcon2 />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
