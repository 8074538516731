import { ModalR } from '../index';
import Button from '../UI/Button/Button';
import cls from './DialogModal.module.scss';
import { isMobile } from 'react-device-detect';

const DialogModal = props => {
	const { openModal, setOpenModal, dialogHandler = () => { }, title = 'Are you sure?', proceedTitle = 'Proceed', cancelTitle = 'No, return back', loading } = props;

	const triggerCloseModal = () => setOpenModal(false);
	return (
		<>
			<ModalR isOpen={openModal} onClose={triggerCloseModal} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ border: '5px black', width: isMobile ? '92%' : '20%', minWidth: isMobile? '300px':'400px', backgroundColor: loading ? 'transparent' : '' }}>
				<div style={{ display: loading ? 'none' : '' }}>
					<p className={cls.logoutText}>{title}</p>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button title={proceedTitle} clicked={dialogHandler} loading={loading} />
						<Button title={cancelTitle} light clicked={triggerCloseModal} />
					</div>
				</div>
			</ModalR>
		</>
	);
};

export default DialogModal;
