import { useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { closeIcon } from '../../../assets/images/icons';
import { tutorialModalClose } from '../../../reduxStore/actions/modalsActions';
import { ModalR } from '../../common';
import './TutorialModal.scss';
const TutorialModal = props => {
	const { childrenWrapperStyle, onTutorialModalClose, isOpen, options } = props;
	const { tutorialTitle, onCancel } = options;
	const modalClosing = () => {
		onCancel && onCancel();
		if (isOpen && onTutorialModalClose) {
			onTutorialModalClose();
		}
	};
	const textAreaLabelStyle = 'position: absolute; top: 10%; left: 20px; right: 0;/*  bottom: 0, */ userSelect: none; pointerEvents: none; display: block; color: #9B9B9B; fontFamily: Quicksand; font-size: 16px; font-weight: 400; letter-spacing: 0; line-height: 20px;';
	const [textAreaValue, setTextAreaValue] = useState(`Maecenas semper commodo nisi non dapibus. Maecenas condimentum ligula semper, tincidunt sapien id, maximus orci.

    Etiam tincidunt ante felis, vel viverra sapien feugiat eu.Etiam vulputate lacus ultricies tellus semper, ac convallis urna dignissim.
    
    Aenean quis auctor turpis, sed accumsan dolor.Integer eu enim sed nulla commodo auctor at sed est.In auctor convallis rhoncus.Morbi finibus enim ac semper porttitor.Cras vitae leo sed magna tempus elementum.`);
	const [textAreaTouched, setTextAreaTouched] = useState(false);
	const textAreaErrorMessage = textAreaValue && textAreaValue?.length < 3 ? `${tutorialTitle} description is required and must have at least 3 characters` : '';

	return (
		<div style={{}}>
			<ModalR
				blured={true}
				scrollable={true}
				isOpen={isOpen}
				onClose={modalClosing}
				// modalWrapperStyle={modalWrapperStyle}
				shadowedContainerStyle={{ minWidth: '320px', width: '60%', height: 'auto' }}
			>
				<div style={{ position: 'absolute', top: '-18px', right: '10px' }}>
					<div onClick={modalClosing} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0 }}>
						<img alt="" src={closeIcon} style={{ width: '48px', height: '48px' }} />
					</div>
				</div>

				<div className={'firstDiv_T'} style={{ ...childrenWrapperStyle }}>
					<div className={'centered_T'}>
						<h1 className={'header_T'}>{tutorialTitle} </h1>
					</div>

					<div className={'contentContainer_T'}>
						<div className={'tutorialVideoContainerStyle_T'}>
							<ReactPlayer width="100%" url={'https://www.youtube.com/watch?v=xV-bOHvfbPQ'} className={'reactPlayerStyle_T'} />
						</div>

						<div className={'tutorialTextContainerStyle_T'}>
							<div className={'formContainer_T'}>
								<textarea
									readOnly
									type="text"
									name="textarea"
									rows={9}
									className={'textAreaStyle_T'}
									value={textAreaValue ?? ''}
									onBlur={e => {
										if (!textAreaValue || textAreaValue?.length < 1) {
											if (e.target.value !== '') e.target.nextSibling.style = 'display:none;';
											else e.target.nextSibling.style = textAreaLabelStyle;
										}
										setTextAreaTouched(true);
									}}
									onFocus={e => {
										if (textAreaValue?.length < 1) e.target.nextSibling.style = `${'display:none;'}`;
									}}
									onChange={e => {
										setTextAreaValue(e.target.value);
									}}
								/>
								{(!textAreaValue || textAreaValue?.length < 1) && <label className={'textAreaPlacehorder_T'}>{'Insert tutorial here'}</label>}

								{textAreaErrorMessage?.length > 0 && textAreaTouched && <p className={'textAreaErrorMessage_T'}>{textAreaErrorMessage}</p>}
							</div>
						</div>
					</div>
				</div>
			</ModalR>
		</div>
	);
};

const mapStateToProps = store => {
	const {
		tutorialModal: { isOpen, options },
	} = store.modalsReducer;
	return { isOpen, options };
};
const mapDispatchToProps = dispatch => {
	return {
		onTutorialModalClose: () => dispatch(tutorialModalClose()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(TutorialModal);
