import React, { useCallback, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import { RenderTooltip } from '../../TwoStatesFieldWrapper/FieldWrapper';
import './CustomCheckbox.scss';
const CustomCheckbox = props => {
	const { defaultChecked, label, onChange, tooltipText, proportioned, mainContainerStyle, checkMarkStyle, checkboxMarkContainerStyle, checkedContentStyle, labelStyle } = props;
	const [checked, setChecked] = useState(defaultChecked);
	const [labelHeight, setLabelHeight] = useState(32);
	const [resizedParHeight, setResizedParHeight] = useState(32);
	const labelRef = useRef();
	const settingLabelHeight = val => setLabelHeight(val);
	useEffect(() => {
		if (labelRef.current.clientHeight < 28) {
			settingLabelHeight(32);
		}
		if (labelRef.current.clientHeight < 51) {
			settingLabelHeight(labelRef.current.clientHeight);
		}
	}, [resizedParHeight]);

	useEffect(() => {
		if ([true, false, undefined].indexOf(!!defaultChecked) > -1 && defaultChecked != checked) {
			setChecked(!!defaultChecked);
		}
	}, [defaultChecked]);

	const containerClicked = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			setChecked(c => !c);
			if (onChange) onChange(!checked);
		},
		[checked]
	);
	const labelClicked = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			setChecked(c => !c);
			if (onChange) onChange(!checked);
		},
		[checked]
	);

	return (
		<div className={'checkboxMainContainer'} style={{ ...mainContainerStyle, overflow: 'visible' /* backgroundColor:'blue' */ }} onClick={e => containerClicked(e)}>
			<div
				className={'checkMarkContainer'}
				style={{
					...checkMarkStyle,
					backgroundColor: checked ? '#4a4a4a' : 'white',
					height: proportioned ? labelHeight : checkMarkStyle?.height ?? '32px',
					width: proportioned ? labelHeight : checkMarkStyle?.width ?? '32px',
					minWidth: proportioned ? labelHeight : checkMarkStyle?.width ?? '32px',
					...checkboxMarkContainerStyle,
				}}
			>
				{checked && (
					<h1 className={'ckeckHeader'} style={{ ...checkedContentStyle }}>
						&#x2714;
					</h1>
				)}
			</div>
			<div className={'labelContainer'} onClick={e => labelClicked(e)}>
				<ResizeObserver
					onResize={rect => {
						setResizedParHeight(rect.height);
					}}
				/>
				<p ref={labelRef} className={'label'} style={{ ...labelStyle }}>
					{label}
				</p>
			</div>
			{tooltipText?.length > 0 && <RenderTooltip tooltipText={tooltipText} />}
		</div>
	);
};

export default CustomCheckbox;
