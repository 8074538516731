import classNames from 'classnames';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { first } from 'rxjs/operators';
import { setAuthenticatedUser } from '../../../reduxStore/actions/authActions';
import { tutorialModalOpen } from '../../../reduxStore/actions/modalsActions';
import { sendOnboardingComplete, setCompanyDetails } from '../../../services/company.service';
import { Button, CompanyDataUpdate, ModalR, TutorialModal } from '../../common';
import { industryFields, numberOfEmployees } from '../../../shared/utility';
import cls from './Onboarding_basic_company.module.scss';

const BasicCompanyInformation = props => {
	const { modalVisible, onCancel, modalWrapperStyle, shadowedContainerStyle, childrenWrapperStyle, onPreviousButtonAction, onContinueButtonAction, onNextButtonAction, companyData, position } = props;

	const [continueDisabled, setContinueDisabled] = useState(true);
	const [fieldErrors, setFieldsErrors] = useState({
		name: '',
		display_name: '',
		founding_date: '',
		employees_no: '',
		industry: '',
	});
	const [tutorialOpen, setTutorialOpen] = useState(false);
	const { name, founding_date, employees_no, industry } = companyData;
	const [elementsTouched, setElementsTouched] = useState({
		name: companyData?.name?.length > 2,
		display_name: companyData?.display_name?.length > 2,
		founding_date: moment(companyData.founding_date, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD')) < 0,
		employees_no: numberOfEmployees.some(i => i.value === companyData.employees_no),
		industry: industryFields?.some(i => i.label == companyData.industry),
	});

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		elementsTouched.display_name && isInvalidPayload();
	}, [companyData, position]);

	const isInvalidPayload = useCallback(() => {
		setFieldsErrors({ name: '', display_name: '', founding_date: '', employees_no: '', industry: '', misc_info: '' });
		let isInvalid = false;

		if (companyData?.name?.length < 2) {
			setFieldsErrors(c => ({ ...c, name: 'Company name must have at least 2 characters' }));
			isInvalid = true;
		}
		if (companyData?.display_name?.length < 2) {
			setFieldsErrors(c => ({ ...c, display_name: 'Company display name must have at least 2 characters' }));
			isInvalid = true;
		}
		// if (moment(companyData.founding_date, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD')) > 0) {
		// 	setFieldsErrors(c => ({ ...c, founding_date: "Company's founding date is not a valid date" }));
		// 	isInvalid = true;
		// }
		if (companyData?.employees_no?.toString().length < 3 || !numberOfEmployees.some(i => i.value === companyData.employees_no)) {
			setFieldsErrors(c => ({ ...c, employees_no: `Employees' number is not valid. \nStored value (${companyData?.employees_no}) is not matching any selectable range` }));
			isInvalid = true;
		}
		// if (companyData?.industry?.length < 3 || !industryFields?.some(i => i.label == companyData.industry)) {
		// 	setFieldsErrors(c => ({ ...c, industry: 'Industry field entry is not valid' }));
		// 	isInvalid = true;
		// }
		if (companyData?.name?.length > 0) {
			if (name?.length > 0) {
				setContinueDisabled(false);
			} else {
				setContinueDisabled(true);
			}
		}
		return isInvalid;
	}, [companyData, employees_no, elementsTouched]);

	const onContinueButtonPressed = useCallback(() => {
		const companyPayload = {
			name: companyData?.name,
			display_name: companyData?.display_name,
			founding_date: moment(companyData?.founding_date ?? '1999-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
			employees_no: companyData?.employees_no,
			industry: companyData?.industry ?? '--',
		};
		if (isInvalidPayload()) return; // sendCompanyDetails({ name: companyData?.name }, 'skip');
		setLoading(true);
		setCompanyDetails(companyPayload)
			.pipe(first())
			.subscribe({
				next: data => {
					setLoading(false);
					onContinueButtonAction();
				},
				error: error => {
					console.log('setCompanyDetails, [error]', error);
					const foundErrors = { name: '', display_name: '', founding_date: '', employees_no: '', industry: '' };
					if (error.data.errors.name) {
						foundErrors.name = error.data.errors.name[0];
					}
					if (error.data.errors.display_name) {
						foundErrors.display_name = error.data.errors.display_name[0];
					}
					if (error.data.errors.founding_date) {
						foundErrors.founding_date = error.data.errors.founding_date[0];
					}
					if (error.data.errors.employees_no) {
						foundErrors.employees_no = error.data.errors.employees_no[0];
					}
					if (error.data.errors.industry) {
						foundErrors.industry = error.data.errors.industry[0];
					}
					setFieldsErrors(c => ({ ...c, ...foundErrors }));
				},
			});
	}, [companyData]);
	const onSkipThisStep = useCallback(() => {
		if (companyData?.name.length < 2) {
			return setFieldsErrors(c => ({ ...c, name: 'Company name field is required' }));
		}
		sendCompanyDetails({ name: companyData?.name }, 'skip');
	}, [companyData]);
	const sendCompanyDetails = (payload, mode) =>
		setCompanyDetails(payload)
			.pipe(first())
			.subscribe({
				next: data => {
					console.log('setCompanyDetails, [data]', data);
					sendOnboardingComplete().subscribe({
						next: data => {
							console.log('sendOnboardingComplete, [data]', data);
							if (mode === 'skip' && onNextButtonAction) {
								onNextButtonAction();
							}
							if (mode === 'continue' && onContinueButtonAction) {
								onContinueButtonAction();
							}
						},
						error: error => {
							console.log('sendOnboardingComplete, [error]', error);
						},
					});
				},
				error: error => {
					console.log('setCompanyDetails, [error]', error);
					const foundErrors = { name: '', display_name: '', founding_date: '', employees_no: '', industry: '' };
					if (error.data.errors.name) {
						foundErrors.name = error.data.errors.name[0];
					}
					if (mode === 'continue') {
						if (error.data.errors.display_name) {
							foundErrors.display_name = error.data.errors.display_name[0];
						}
						if (error.data.errors.founding_date) {
							foundErrors.founding_date = error.data.errors.founding_date[0];
						}
						if (error.data.errors.employees_no) {
							foundErrors.employees_no = error.data.errors.employees_no[0];
						}
						if (error.data.errors.industry) {
							foundErrors.industry = error.data.errors.industry[0];
						}
					}
					setFieldsErrors(c => ({ ...c, ...foundErrors }));
				},
			});

	return (
		<ModalR blured={true} scrollable={true} isOpen={modalVisible} onClose={onCancel} modalWrapperStyle={modalWrapperStyle} shadowedContainerStyle={shadowedContainerStyle}>
			<div className={cls.firstDiv_III} style={{ ...childrenWrapperStyle }}>
				<div className={cls.headerContainer_III}>
					<h1 className={cls.header_III}>{'2. Basic Company Information '}</h1>
				</div>
				<div className={classNames(cls.subheadingContainer_III, cls.justifiedLeft_III)}>
					<p className={cls.subheading_III}>{'Let’s fill out all the basic information your company requires. You can always update or change this information later in the Settings.'}</p>
				</div>
				<CompanyDataUpdate datePickerRC settingsContainerClass={cls.updateCompanyDataFieldsContainer_III} feValidation={{ ...fieldErrors }} bypassTriggerValidation={tutorialOpen} setElementsTouched={setElementsTouched} />
				<div className={cls.controls2}>
					<Button light clicked={onPreviousButtonAction} title={'Back'} />

					<Button disabled={continueDisabled} loading={loading ? loading : undefined} clicked={onContinueButtonPressed} title={'Continue'} />
				</div>
			</div>
		</ModalR>
	);
};
const mapStateToProps = store => {
	const {
		authenticatedUser: { position },
	} = store.authReducer;
	const { companyData, companyPositionsArray } = store.flowReducer;
	return { companyData, companyPositionsArray, position };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetAuthenticatedUserPosition: position => dispatch(setAuthenticatedUser(position)),
		onTutorialModalOpen: options => dispatch(tutorialModalOpen(options)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicCompanyInformation);
