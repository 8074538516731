import queryString from 'query-string';

import qsSimple from 'qs';
import * as R from 'ramda';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { setComapanyPinnedProcesses, setCompanyProcesses, setSelectedProcess, setLastUpdate, setFilters, updateCompanyProcesses, setMessageBar } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import moment, { now } from 'moment';
import { createNewModule, pinModule } from './module.service';
import { CLEAR_TO_SET_DATA } from '../shared/utility';
import { getAuthenticatedUser } from './user.service';
import { sessionService } from 'redux-react-session';

export const getCompanyProcesses = (page, forced = false) => {
	const { lastUpdates, companyProcesses } = store.getState().flowReducer;

	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.processes.getAllProcesses(last_access_token, page, moment.utc(forced == true? 0 : lastUpdates?.procedures ?? 0).format('YYYY-MM-DD HH:mm:ss')),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};

	//console.log('moment diff procedures', moment(now()).diff(moment(lastUpdates.procedures), 'minutes'), companyProcesses.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.procedures), 'minutes') > 10 || companyProcesses.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status == 200 && response?.data && response?.data?.data) {
						const ascendingProcesses = response?.data?.data?.length > 0 ? R.sortBy(R.prop('name'), response?.data?.data) : [];
						store.dispatch(setCompanyProcesses(ascendingProcesses, page));
						store.dispatch(setLastUpdate({ ...lastUpdates, procedures: now() }));
						subscriber.next(response?.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
export const getPinnedProcess = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.processes.getAllPinnedProcesses(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setComapanyPinnedProcesses(response.data.data));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const createNewProcess = (processName, processDescription, taskUseOnly) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('name', processName);
	if (processDescription?.length > 0) {
		data.append('description', processDescription);
	}
	data.append('type', taskUseOnly);
	const defaultConfig = { ...URLS.processes.createNewProcess(last_access_token) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	const { companyProcesses } = store.getState().flowReducer;
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const ascendingProcesses = companyProcesses.length > 0 ? R.sortBy(R.prop('name'), [...companyProcesses, response.data.data]) : [];

					store.dispatch(setCompanyProcesses(ascendingProcesses, 1));
					store.dispatch(setSelectedProcess({ ...response?.data?.data, registered: true }));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully created new process` }));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updatingProcessById = (processId, processName, processDescription, taskUseOnly) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	let data = qsSimple.stringify({
		name: processName,
		description: processDescription,
		type: taskUseOnly,
	});
	const defaultConfig = { ...URLS.processes.updateProcessById(last_access_token, processId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
	};

	const { companyProcesses } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setCompanyProcesses([...companyProcesses.filter(proc => proc.id !== processId), response?.data?.data], 1));
					store.dispatch(setSelectedProcess({ ...response?.data?.data, registered: true }));
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated ${processName}` }));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const pinProcess = processId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.processes.pinProcessById(last_access_token, processId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const unpinProcess = processId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.processes.unpinProcessById(last_access_token, processId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deleteProcessById = processId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.processes.deleteProcessById(last_access_token, processId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
	};

	const { companyProcesses } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
					store.dispatch(
						setCompanyProcesses(
							companyProcesses.filter(proc => proc.id !== processId),
							1
						)
					);
					store.dispatch(setMessageBar({ show: true, message: `You have successfully deleted process`, color: 'red' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const shareProcessToPosition = (process_id, position_id) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('position_id', position_id);

	const defaultConfig = { ...URLS.processes.shareProcessToPosition(last_access_token, process_id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const shareProcessToSeveralPositions = (process_id, positions_ids) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = JSON.stringify({ positions: positions_ids });

	const config = {
		...URLS.processes.shareProcessToSeveralPositions(last_access_token, process_id),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => subscriber.error(error))
	);
};
export const unshareProcessFromSeveralPositions = (process_id, positions_ids) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = JSON.stringify({ positions: positions_ids });

	const config = {
		...URLS.processes.unshareProcessFromSeveralPositions(last_access_token, process_id),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => subscriber.error(error))
	);
};
export const getProcessById = (processId, simple = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.processes.getProcessById(last_access_token, processId, simple),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(updateCompanyProcesses(response?.data?.data));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getProcessesFilters = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.processes.getProcessesFilters(last_access_token),
		handlerEnabled: true,
	};

	const { filters } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setFilters({ ...filters, processes: response?.data?.data }));
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getFilteredProcesses = sentData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = JSON.stringify({ filters: [...sentData] });
	const config = {
		...URLS.processes.getFilteredProcesses(last_access_token),
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
//ProcessSteps
export const createNewProcessStepUsingRawJson = sentData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	let data = JSON.stringify(sentData);
	const config = {
		...URLS.processSteps.createNewProcStepUsingRawJson(last_access_token),
		handlerEnabled: true,
		data: data,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if ([200, 201]?.indexOf(response?.status) > -1 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateProcessStepById = (sentData, stepId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	let data = JSON.stringify(sentData);
	const config = {
		...URLS.processSteps.updateProcessStepByIdUsingJson(last_access_token, stepId),
		handlerEnabled: true,
		data: data,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if ([200, 201]?.indexOf(response?.status) > -1 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated a step` }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateProcessStepByJson = (sentData, processId, responseWithAllSteps) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = JSON.stringify(sentData);
	const config = {
		...URLS.processSteps.updateProcessStepByIdUsingJson(last_access_token, processId.concat(responseWithAllSteps ? '?all=1' : '')),
		handlerEnabled: true,
		data: data,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if ([200, 201]?.indexOf(response?.status) > -1 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated a step` }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getStepsByProcessId = sentParameters => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const { processId, forStepId, pathways, parentsOnly, details } = sentParameters;
	const query = queryString.stringify(
		{ pathways: pathways, forStepId: forStepId, parentsOnly: parentsOnly, details: details },
		{
			skipNull: true,
		}
	);
	const config = {
		...URLS.processSteps.getStepsByProcessId(last_access_token, processId + (query?.length > 0 ? '?' + query : '')),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data)
					if (response?.data?.data) {
						subscriber.next(response.data.data);
						subscriber.complete();
					} else {
						subscriber.next(response.data);
						subscriber.complete();
					}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getAllSteps = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.processSteps.getAllSteps(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getAllStepsByProcId = processId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.processSteps.getAllStepsByProcessId(last_access_token, processId),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const attachStepToParent = (stepId, parent_step_id) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('parent_step_id', parent_step_id);

	const config = {
		...URLS.processSteps.attachStepToParent(last_access_token, stepId),
		handlerEnabled: true,
		data: data,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deleteStepById = stepId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.processSteps.deleteStepById(last_access_token, stepId),
		handlerEnabled: true,
	};

	const { companyProcesses } = store.getState().flowReducer;
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.message) {
					subscriber.next(response.data.message);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getInheritedComponentsForStep = sentData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	// const data = securedQS.stringify(sentData)

	const data = JSON.stringify(sentData);
	const config = {
		...URLS.processSteps.getAllInheritedStepsComponents(last_access_token),
		handlerEnabled: true,
		forwardAllErrors: true,
		data: data,
	};
	// console.log('getInheritedComponentsForStep, config:', config)
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data)
					if (response?.data?.data) {
						subscriber.next(response.data.data);
						subscriber.complete();
					} else {
						subscriber.next(response.data);
						subscriber.complete();
					}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getStepInheritedCompsFromParent = (taskId, stepId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('task_id', taskId);

	const config = {
		...URLS.processSteps.getStepInheritedComponentsFromParent(last_access_token, stepId),
		handlerEnabled: true,
		forwardAllErrors: true,
		data: data,
	};
	// console.log('getInheritedComponentsForStep, config:', config)
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data)
					if (response?.data?.data) {
						subscriber.next(response.data.data);
						subscriber.complete();
					} else {
						subscriber.next(response.data);
						subscriber.complete();
					}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getStepById = stepId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	// const data = securedQS.stringify(sentData)
	const config = {
		...URLS.processSteps.getStepById(last_access_token, stepId),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	// console.log('getInheritedComponentsForStep, config:', config)
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data)
					if (response?.data?.data) {
						subscriber.next(response.data.data);
						subscriber.complete();
					} else {
						subscriber.next(response.data);
						subscriber.complete();
					}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const createProcessTemplate = process => {
	const { companyProcesses } = store.getState().flowReducer;
	return new Observable(subs =>
		createNewProcess(`Template ${process.name}`, process.description, process?.type)
			.pipe(first())
			.subscribe({
				next: process_data => {
					let steps_array = [];
					let title = '';
					process.steps.map((step, index) => {
						createNewProcessStepUsingRawJson({
							name: step?.name,
							procedure_id: process_data.id,
							guidance: step?.guidance,
							rank: step?.rank,
							options: step?.options,
							is_start: step?.is_start ?? false,
							is_end: step?.is_end ?? false,
							component_blueprints: step?.component_blueprints?.map(component => {
								title = component?.selected_fields?.find(sf => sf.data_type_name === 'ID')?.field_name ?? title;
								return {
									...component,
									selected_fields: component?.selected_fields?.map(sf => {
										return {
											id: sf.id,
											mandatory: sf.mandatory,
											visible: sf.visible,
											index: sf.index,
										};
									}),
								};
							}),
						})
							.pipe(first())
							.subscribe({
								next: step_data => {
									steps_array.push(step_data);

									if (process.steps.length == index + 1) {
										createNewModule(`Template ${process.name}`, process_data.id, {
											title: [title],
											outputTo: 'component',
										}).subscribe({
											next: module_data => {
												process.steps.map((stp, ix) => {
													if (stp?.parent_steps?.length > 0 || stp?.pathway_steps?.length > 0) {
														updateProcessStepById(
															{
																parent_steps: stp?.parent_steps.map(ps => {
																	return steps_array.find(st => st.name === ps.name && st.rank === ps.rank).id;
																}),
																pathway_steps: stp?.pathway_steps.map(ps => {
																	return steps_array.find(st => st.name === ps.name && st.rank === ps.rank).id;
																}),
															},
															steps_array.find(st => st.name === stp.name && st.rank === stp.rank).id
														)
															.pipe(first())
															.subscribe({
																next: () => {
																	if (process.steps.length == ix + 1) {
																		store.dispatch(
																			setCompanyProcesses(
																				companyProcesses.filter(pro => pro.id !== process_data.id),
																				CLEAR_TO_SET_DATA
																			)
																		);
																		pinModule(module_data?.id)
																			.pipe(first())
																			.subscribe({
																				next: () => {
																					getAuthenticatedUser()
																						.pipe(first())
																						.subscribe({
																							next: data => {
																								sessionService.saveUser({ ...data });
																								subs.next(process_data);
																							},
																						});
																				},
																			});
																	}
																},
															});
													} else if (process.steps.length == ix + 1) {
														subs.next(process_data);
													}
												});
											},
											error: error => {
												if (error) {
													subs.error(error);
												}
											},
										});
									}
								},
								error: error => subs.error(error),
							});
					});
				},
				error: error => subs.error(error),
			})
	);
};
