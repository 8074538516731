import cls from './HeaderTopBar.module.scss';
import Spinner from '../UI/Spinner/Spinner';
const HeaderTopBar = props => {
	const { hasHeaderImg, headerTitle, headerImgSrc, targetProcessing } = props;
	return (
		<div className={cls.headerTopBar}>
			<div className={cls.headerTopBarContainer}>
				{hasHeaderImg && <img alt="" src={headerImgSrc} />}
				<p>{headerTitle}</p>
				{targetProcessing && (
					<span className={cls.loadingContainer}>
						<Spinner type={'Watch'} color="var(--app-color-gray-light)" height={15} width={`${30}px`} />
					</span>
				)}
			</div>
		</div>
	);
};

export default HeaderTopBar;
