import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { first } from 'rxjs/operators';
import { DoneMarkIcon, DropDownIco, HideIco, reset2, ShowIco } from '../../../../../assets/images/icons';
import { pat, useProcessContext } from '../../../../../context/processStep.context';
import { getComponentBlueprintById } from '../../../../../services/components.service';
import { devFocus } from '../../../../../shared/devFocus';
import { generateNewUUID, groupings } from '../../../../../shared/utility';
import { Button, ContainerWrapper, ModalR, RenderYesNoSwitcher, SelectWithSearch, SimpleContainerWrapper, UnfocusableWrapper } from '../../../../common';
import cls from './StepComponents.module.scss';
import { Table, Styles } from '../../../../common/UI/Table/Table';
import { keyDarkIcon, keyIcon } from '../../../../../assets/images/new-icons';
import { MultiSelectWithCheckboxesAndNestedItems_v2, SingleSelectWithCheckboxesAndNestedItems } from '../../../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RenderSelectedFields } from '../../../ModulesComp/AccordionModule';

const R = require('ramda');
export const RenderStepNewComponents = memo(props => {
	const { selectedProcess, blueprints, companyPositionsArray, showAddButton = true, onEditChange = () => { } } = props;
	const [procContext, setInProcContext] = useProcessContext();
	const [newComponents, setNewComponents] = useState([]);
	const [canAddOther, setCanAddOther] = useState(false);
	const recomputeFromContext = useCallback(() => {
		if (!procContext?.selectedStep?.component_blueprints?.length > 0) return setNewComponents([]);
		const newComponents = R.compose(R.map(R.assoc('hasStatus', R.pathOr('', ['status', 'id'])?.length > 0)), R.map(R.assoc('hasTitle', R.flip(R.prop('title'))?.length > 0)))(procContext.selectedStep.component_blueprints);

		setNewComponents([...newComponents.map(i => ({ value: i.id, uuid: i._uuid, title: i.title, component: i }))]);
	}, [procContext.selectedStep.component_blueprints]);

	useEffect(() => {
		recomputeFromContext();
	}, [procContext.selectedStep.component_blueprints, recomputeFromContext]);

	const onAddComponent = useCallback(
		comp => {
			getComponentBlueprintById(comp.value)
				.pipe(first())
				.subscribe({
					next: data => {
						const newComponent = {
							id: data.id,
							is_active: true,
							is_inherited: false,
							name: data.name,
							selected_fields: data.fields.map(i => ({
								data_type: i?.field_type?.data_type,
								data_type_name: i?.field_type?.name,
								field_name: i?.value ?? i?.field_type?.name,
								id: i.id,
								index: i.index,
								mandatory: false,
								options: i?.options ?? [],
								process_step_blueprint_field_id: i.id,
							})),
							status: { id: '', name: '' },
							title: '',
							hasStatus: false,
							hasTitle: false,
							_uuid: generateNewUUID(),
							new: true,
						};
						setInProcContext({ type: pat.addNewComponentAtStep, value: newComponent });
						setCanAddOther(c => !c);
					},
					error: _error => {
						/*  */
					},
				});
		},
		[setInProcContext]
	);

	return (
		<div className={cls.renderComponents}>
			{newComponents?.length > 0 &&
				newComponents?.map((i, idx) => {
					return (
						<RenderNewComponent
							onEditChange={edit => {
								onEditChange(edit, i.component.id);
							}}
							key={idx + i.uuid}
							value={i.value}
							compUUID={i.uuid}
							title={i.title?.length > 0 ? i.title : 'Select title...'}
							companyPositionsArray={companyPositionsArray}
						/>
					);
				})}
			{canAddOther ? (
				<RenderSearchComponents
					onComponentSelect={onAddComponent}
					blueprint_components={R.sortWith([R.ascend(R.prop('name'))])(
						blueprints.filter(bp => R.not(R.includes(bp.id, R.pipe(R.pluck('component_blueprints'), R.flatten, R.pipe(R.pluck('id')))(procContext.steps)))).filter(bp => R.not(R.includes(bp.id, R.pipe(R.pluck('value'))(newComponents))))
					)}
				/>
			) : (
				<div className={cls.noContentWrapperNewComponents} style={{ display: showAddButton == false ? 'none' : '' }}>
					<div className={cls.noContentWrapperContainerNewComponents}>
						{blueprints.length > 0 ? <Button style={{ height: '30px' }} title={'Attach Component'} clicked={() => setCanAddOther(c => !c)} /> : <p className={cls.noContentParagraphNewComponents}>{'There are no available components. Create one first!'}</p>}
					</div>
				</div>
			)}
		</div>
	);
});
const RenderNewComponent = memo(
	props => {
		const { value, title, compUUID, companyPositionsArray, show = false, onEditChange = () => { } } = props;
		const fieldContainerRef = useRef(null);
		const [procContext, setInProcContext] = useProcessContext();
		const [component, setComponent] = useState(R.find(R.allPass([R.propEq('_uuid', compUUID)]))(procContext?.selectedStep?.component_blueprints ?? []));
		const [errorMessages, setErrorMessages] = useState('');
		const [, setInit] = useState(false);
		const [showOnly, setShowOnly] = useState(component?.options?.showOnly?.length > 0 ?? false);
		const settingComponents = val => setComponent(val);
		const [loading, setLoading] = useState(false);
		const [showPage, setShowPage] = useState(false);
		const [editMode, setEditMode] = useState(component?.new ?? show);
		const [data, setData] = useState(() => {
			if (component?.selected_fields?.length === 0) {
				return [];
			}
			return R.sortWith([R.ascend(R.prop('index'))])(component.selected_fields).map((d, idx) => {
				return {
					...{
						id: `row-${d.id}`,
						component: (
							<RenderFieldOfNewComponent
								key={`field-${d?.id}${idx}`}
								fieldId={d?.id}
								compId={value}
								compUUID={compUUID}
								componentTitle={component?.title}
								isKeyClicked={() => {
									setComponent({ ...component, options: { ...component?.options, key: d.field_name } });
									setInProcContext({
										type: pat.updateComponentKeyValue,
										value: {
											compBluePrintUUID: compUUID,
											compBluePrintId: component.id,

											isInherited: false,
											options: { ...component.options, key: d.field_name },
										},
									});
								}}
								isKey={component?.options?.key === d.field_name}
								component={component}
							/>
						),
					},
				};
			});
		});

		useEffect(() => {
			const newComp = R.find(R.allPass([R.propEq('_uuid', compUUID)]))(procContext?.selectedStep?.component_blueprints ?? []);
			settingComponents(newComp);
		}, [procContext?.selectedStep?.component_blueprints, value]);

		const onRefreshComponent = useCallback(() => {
			setLoading(true);
			getComponentBlueprintById(component.id)
				.pipe(first())
				.subscribe({
					next: data => {
						setLoading(false);
						const newSelectedFields = data.fields.map(field => {
							const found = component.selected_fields.find(sf => sf.id === field.id);
							if (found) {
								found.options = field?.options;
								return found;
							} else {
								setErrorMessages({ title: 'Change in fields found, please save this step' });
								setEditMode(true);
								return {
									data_type: field?.field_type?.data_type,
									data_type_name: field?.field_type?.name,
									field_name: field?.value ?? field?.field_type?.name,
									id: field.id,
									index: field.index,
									mandatory: false,
									options: field?.options ?? [],
									process_step_blueprint_field_id: field.id,
								};
							}

						});
						if (newSelectedFields.length < component.selected_fields.length) {
							setErrorMessages({ title: 'A field was removed, please save this step' });
							setEditMode(true);
						}
						component.selected_fields = newSelectedFields;
						setInProcContext({ type: pat.updateComponentAtStep, value: component });
						setData(() => {
							if (component?.selected_fields?.length === 0) {
								return [];
							}
							return R.sortWith([R.ascend(R.prop('index'))])(component.selected_fields).map((d, idx) => {
								return {
									...{
										id: `row-${d.id}`,
										component: (
											<RenderFieldOfNewComponent
												key={`field-${d?.id}${idx}`}
												fieldId={d?.id}
												compId={value}
												compUUID={compUUID}
												componentTitle={component?.title}
												isKeyClicked={() => {
													setComponent({ ...component, options: { ...component?.options, key: d.field_name } });
													setInProcContext({
														type: pat.updateComponentKeyValue,
														value: {
															compBluePrintUUID: compUUID,
															compBluePrintId: component.id,

															isInherited: false,
															options: { ...component.options, key: d.field_name },
														},
													});
												}}
												isKey={component?.options?.key === d.field_name}
												component={component}
											/>
										),
									},
								};
							});
						});
						setComponent({ ...component, selected_fields: newSelectedFields });

					},
					error: _error => {
						setLoading(false);
					},
				});
		}, [setInProcContext]);

		const onDeleteNewComponent = useCallback(() => {
			setInProcContext({ type: pat.removeComponentFromStep, value: { uuid: compUUID } });
		}, [compUUID]);

		useEffect(() => {
			devFocus(fieldContainerRef?.current, true);
		}, [component?.title]);

		const columns = useMemo(
			() => [
				{
					Header: 'Component',
					columns: [
						{
							Header: 'Component',
							accessor: 'component',
						},
					],
				},
			],
			[]
		);

		const handleDragEnd = new_data => {
			new_data.map((d, index) => {
				setInProcContext({
					type: pat.updateComponentFieldIndexKeyValue,
					value: {
						compBluePrintUUID: d.component.props.compUUID,
						compBluePrintId: d.component.props.compId,
						fieldId: d.component.props.fieldId,
						index: index,
					},
				});
			});

			setData(new_data);
		};
		return (
			<UnfocusableWrapper
				key={value + title}
				className={cls.renderComponent}
				comp-in-edit-mode={editMode ? 'true' : null}
				app-variant={errorMessages.title?.length > 0 || errorMessages.status?.length > 0 ? 'error' : 'normal'}
				onClickOutside={() => {
					setInit(true);
				}}
			>
				<SimpleContainerWrapper
					title={`${component?.options?.allowMultipleInputs == true ? 'Transaction' : component?.options?.asReport === true ? 'Report' : component?.options?.asDashboard === true ? 'Dashboard' : component?.options?.asModuleList === true ? 'Main List' : 'Component'}:`}
				/>
				<div className={cls.wrapper}>
					<div className={cls.mainContainer}>
						<div className={cls.firstRow}>
							<p className={cls.pLabel} onClick={() => onEditChange(component.id)}>
								{component.name}
							</p>
							<div className={cls.firstRowControls}>

								{editMode && <Button light icon={<FontAwesomeIcon icon={faEye} style={{ width: '20px', height: '30px' }} />} style={{ height: '30px' }} clicked={() => setShowPage(true)} />}
								{editMode && <Button loadingIn={loading} light icon={<img src={reset2} />} style={{ height: '30px' }} clicked={() => onRefreshComponent()} />}
								{editMode && <Button light style={{ height: '30px' }} title={'Remove'} clicked={() => onDeleteNewComponent()} />}
								{/* {editMode && <Button light icon={<FontAwesomeIcon icon={faTrash} style={{ width: '20px', height: '30px' }} />} style={{ height: '30px' }} clicked={() => onDeleteNewComponent()} />} */}
								{/* {editMode && <Button light style={{ height: '30px' }} title={'View'} clicked={() => setShowPage(true)} />} */}
								<div
									className={cls.icoContainer}
									onClick={() => {
										setEditMode(c => !c);
									}}
								>
									<DropDownIco style={{ transform: editMode ? 'rotate(180deg)' : 'rotate(0deg)' }} />
								</div>
							</div>
						</div>
						{errorMessages.title?.length > 0 && <span className={cls.errorContainer}>{errorMessages.title}</span>}
					</div>
				</div>
				{showPage && <ModalR hasCloseButton onClose={() => setShowPage(false)} scrollable={true} shadowedContainerStyle={{ minWidth: '500px', width: '100%' }} isOpen={showPage} blured={true}>


					<div style={{ backgroundColor: 'rgb(246, 244, 236)', width: 'inherit' }}>
						<div className={cls.listContainer} style={{ display: 'flex', margin: '150px 50px 50px 50px' }}>
							<div className={cls.accordionContainer} style={{ margin: '30px 0 0 0' }}>
								<RenderSelectedFields
									component={component}
									selectedComponent={{ id: '', name: '' }}
									isDraft={true}
									setTaskProcessContext={() => { }}
									processTaskContext={{ components: [], newStepComponents: [] }}
								/>
							</div>

						</div>
					</div>
				</ModalR>}
				{editMode && (
					<div style={{ flex: 1, width: '100%' }}>
						<ContainerWrapper ref={fieldContainerRef} ungrouped title={'Drag to arrange Component Fields:'} columned withAckButton={false}>
							<br />
							<Styles>
								<Table columns={columns} data={data} component={component} onhandleDragEnd={handleDragEnd} />
							</Styles>
						</ContainerWrapper>

						<RenderYesNoSwitcher
							{...{
								id: 'moduleList',
								title: 'Use as Transactions List',
								ungrouped: true,
								withAckButton: false,
							}}
							style={{
								margin: '20px 2.5% 10px 5px',
								width: 'auto',
								display: procContext?.selectedStep?.options?.templateName ? 'none' : procContext?.selectedStep?.rank != 1 ? 'none' : component?.options?.asDashboard == true ? 'none' : component?.options?.asReport == true ? 'none' : '',
							}}
							value={component?.options?.asModuleList ?? false}
							toggleSelected={value => {
								setComponent({ ...component, options: { ...component?.options, asReport: false, asDashboard: false, asModuleList: value } });

								setInProcContext({
									type: pat.updateComponentKeyValue,
									value: {
										compBluePrintUUID: compUUID,
										compBluePrintId: component.id,

										isInherited: false,
										options: { ...component.options, asReport: false, asDashboard: false, asModuleList: value },
									},
								});
							}}
						/>

						<RenderYesNoSwitcher
							{...{
								id: 'asReport',
								title: 'Use as a report?',
								ungrouped: true,
								withAckButton: false,
							}}
							style={{
								margin: '20px 2.5% 10px 5px',
								width: 'auto',
								display: procContext?.selectedStep?.options?.templateName ? 'none' : procContext?.selectedStep?.rank != 1 ? 'none' : component?.options?.asModuleList == true ? 'none' : component?.options?.asDashboard == true ? 'none' : '',
							}}
							value={component?.options?.asReport ?? false}
							toggleSelected={value => {
								setComponent({ ...component, options: { ...component?.options, asModuleList: false, asDashboard: false, asReport: value } });

								setInProcContext({
									type: pat.updateComponentKeyValue,
									value: {
										compBluePrintUUID: compUUID,
										compBluePrintId: component.id,

										isInherited: false,
										options: { ...component.options, asModuleList: false, asDashboard: false, asReport: value },
									},
								});
							}}
						/>
						<RenderYesNoSwitcher
							{...{
								id: 'asDashboard',
								title: 'View as dashboard?',
								ungrouped: true,
								withAckButton: false,
							}}
							style={{
								margin: '20px 2.5% 10px 5px',
								width: 'auto',
								display: procContext?.selectedStep?.options?.templateName ? 'none' : procContext?.selectedStep?.rank != 1 ? 'none' : component?.options?.asModuleList == true ? 'none' : component?.options?.asReport == true ? 'none' : '',
							}}
							value={component?.options?.asDashboard ?? false}
							toggleSelected={value => {
								setComponent({ ...component, options: { ...component?.options, asReport: false, asModuleList: false, asDashboard: value } });

								setInProcContext({
									type: pat.updateComponentKeyValue,
									value: {
										compBluePrintUUID: compUUID,
										compBluePrintId: component.id,

										isInherited: false,
										options: { ...component.options, asReport: false, asModuleList: false, asDashboard: value },
									},
								});
							}}
						/>
						<RenderYesNoSwitcher
							{...{
								id: 'multipleInputs',
								title: 'Allow multiple inputs?',
								ungrouped: true,
								withAckButton: false,
							}}
							style={{
								margin: '20px 2.5% 10px 5px',
								width: 'auto',
								display: procContext?.selectedStep?.options?.templateName ? 'none' : component?.options?.asModuleList == true ? 'none' : component?.options?.asReport == true ? 'none' : component?.options?.asDashboard == true ? 'none' : (component.selected_fields.filter(sf => sf.options?.mapToItem).length > 0 && component.selected_fields.find(sf => sf.data_type_name === 'Transaction List')) ? '' : 'none',
							}}
							value={component?.options?.allowMultipleInputs ?? false}
							toggleSelected={value => {
								setComponent({ ...component, options: { ...component?.options, allowMultipleInputs: value } });

								setInProcContext({
									type: pat.updateComponentKeyValue,
									value: {
										compBluePrintUUID: compUUID,
										compBluePrintId: component.id,

										isInherited: false,
										options: { ...component.options, allowMultipleInputs: value },
									},
								});
							}}
						/>
						<RenderYesNoSwitcher
							{...{
								id: 'showAll',
								title: 'Show component to all?',
								ungrouped: true,
								withAckButton: false,
							}}
							style={{
								margin: '20px 2.5% 10px 5px',
								width: 'auto',
								display: procContext?.selectedStep?.options?.templateName ? 'none' : '',
							}}
							value={!showOnly}
							toggleSelected={value => {
								setShowOnly(!value);
							}}
						/>
						<ContainerWrapper style={{ display: procContext?.selectedStep?.options?.templateName ? 'none' : showOnly ? '' : 'none' }} ref={fieldContainerRef} ungrouped title={'Only show this component to:'} columned withAckButton={false}>
							<br />
							<MultiSelectWithCheckboxesAndNestedItems_v2
								tagEmplacement={'inlined'}
								searchable={false}
								items={companyPositionsArray}
								groupedBy={'department'}
								groupname={'department'}
								triggerType={'chevron'}
								initialSelectedValues={component?.options?.showOnly?.map(d => {
									return companyPositionsArray.find(item => item.value === d);
								})}
								className={cls.peoplePositionsSelect}
								inputClassName={cls.peoplePositionsSelectInput}
								molclassname={cls.peoplePositionsSelectMenuOverlay}
								moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
								onChange={selection => {
									setComponent({ ...component, options: { ...component?.options, showOnly: selection?.map(i => i?.value) } });
									setInProcContext({
										type: pat.updateComponentKeyValue,
										value: {
											compBluePrintUUID: compUUID,
											compBluePrintId: component.id,

											isInherited: false,
											options: { ...component.options, showOnly: selection?.map(i => i?.value) },
										},
									});
								}}
							/>
						</ContainerWrapper>
					</div>
				)}
			</UnfocusableWrapper>
		);
	},
	(prev, next) => next.props === prev.props
);

export const capitalizeString = stringValue => (stringValue?.length > 0 ? stringValue?.charAt(0)?.toUpperCase() + stringValue?.slice(1) : stringValue);
const findBlueprintComponentByUUID = (compUUID, blueprints = []) => R.find(R.propEq('_uuid', compUUID))(blueprints);
const findField = (fieldId, selected_fields = []) => R.find(R.propEq('id', fieldId))(selected_fields);

const RenderFieldOfNewComponent = memo(props => {
	const { fieldId, compId, compUUID, componentTitle, isKeyClicked, isKey = false, component } = props;
	const [procContext, setInProcContext] = useProcessContext();
	const [isReadOnly, setIsReadOnly] = useState(false);

	const getFieldObject = useCallback(() => {
		const blueprintObj = findBlueprintComponentByUUID(compUUID, procContext?.selectedStep?.component_blueprints ?? []);
		return findField(fieldId, blueprintObj?.selected_fields ?? []);
	}, [compId, fieldId, procContext?.selectedStep?.component_blueprints]);
	const settingReadOnly = val => setIsReadOnly(val);
	const [field, setField] = useState(getFieldObject());
	useEffect(() => {
		(() => setField(getFieldObject()))();
	}, [getFieldObject, procContext.selectedStep.component_blueprints]);

	const detectReadOnlyState = useCallback(() => {
		settingReadOnly(false);
		const isInBluePrintComponentTitle = (componentTitle?.split(', ') ?? [])?.some(i => i?.toLowerCase() == field?.field_name.toLowerCase());
		if (isInBluePrintComponentTitle) {
			settingReadOnly(true);
		}
	}, [componentTitle, field?.field_name]);

	useEffect(() => {
		detectReadOnlyState();
	}, []);
	useEffect(() => {
		settingReadOnly(false);
		const isInBluePrintComponentTitle = (componentTitle?.split(', ') ?? [])?.some(i => i?.toLowerCase() == field?.field_name.toLowerCase());
		if (isInBluePrintComponentTitle) {
			settingReadOnly(true);
		}
	}, [compId, componentTitle, detectReadOnlyState, field?.field_name, procContext?.selectedStep?.component_blueprints]);

	const settingVisibilityInContext = useCallback(
		visible => {
			if (!isReadOnly && compId?.length > 0) {
				setInProcContext({
					type: pat.updateComponentFieldKeyValue,
					value: {
						compBluePrintUUID: compUUID,
						compBluePrintId: compId,
						isInherited: false,
						fieldId: fieldId,
						visible: visible,
						index: field?.index,
						mandatory: visible == false ? false : field.data_type !== 'output' ? field?.mandatory : false,
					},
				});
			}
		},
		[compId, compUUID, field?.mandatory, fieldId, isReadOnly, setInProcContext, field?.index]
	);
	const settingMandatoryInContext = useCallback(
		mandatory => {
			if (!isReadOnly && compId?.length > 0) {
				setInProcContext({
					type: pat.updateComponentFieldKeyValue,
					value: {
						compBluePrintUUID: compUUID,
						compBluePrintId: compId,
						isInherited: false,
						fieldId: fieldId,
						mandatory: field.data_type !== 'output' ? mandatory : false,
						index: field?.index,
						visible: mandatory == true ? true : field?.visible,
					},
				});
			}
		},
		[compId, compUUID, field?.visible, fieldId, isReadOnly, setInProcContext, field?.index]
	);

	return (
		<div className={cls.renderFieldOfComponent} app-variant={isReadOnly ? 'read-only' : 'editable'} app-color={field?.visible ? 'active' : 'inactive'} tabIndex="0">
			<div className={cls.inheritedFieldNameContainer}>
				<p className={cls.inheritedFieldName}>{field?.field_name}</p>
				<p className={cls.inheritedFieldMandatoryMark}>{field?.mandatory && '*'}</p>
			</div>
			<p className={cls.typeText}>{field.data_type_name}</p>
			<span className={cls.optionsContainer}>
				<div
					className={cls.mandatoryContainer}
					onClick={() => {
						settingMandatoryInContext(!field?.mandatory);
					}}
				>
					{field.data_type !== 'output' && field.data_type_name !== 'ID' && <span className={cls.mandatoryIconContainer}>{field?.mandatory ? <DoneMarkIcon className={cls.mandatorySVG} /> : <div className={cls.notMandatory} />}</span>}
					{field.data_type !== 'output' && field.data_type_name !== 'ID' && <p className={cls.mandatoryText}>{!isReadOnly ? 'Required' : ''}</p>}
				</div>
				{field.data_type !== 'output' && field.data_type_name !== 'ID' && (
					<img src={procContext?.selectedStep?.component_blueprints?.find(c => c.id === compId)?.options?.key === field?.field_name ? keyIcon : keyDarkIcon} style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={isKeyClicked} />
				)}
				<div
					className={cls.visibilityContainer}
					onClick={() => {
						settingVisibilityInContext(!field?.visible);
					}}
				>
					{field?.visible == undefined ? <ShowIco /> : field?.visible ? <ShowIco /> : <HideIco />}
				</div>
			</span>
		</div>
	);
});
const RenderSearchComponents = props => {
	const { onComponentSelect, blueprint_components } = props;
	const [blueprints] = useState(blueprint_components);
	const [showSearchComponent, setShowSearchComponent] = useState(true);
	const [selectedComponent, setSelectedComponent] = useState({});

	return (
		<div className={cls.renderSearchComponentsHost}>
			{showSearchComponent ? (
				<div className={cls.showSearchComponentContainer} app-display-variant={showSearchComponent ? 'table-row' : 'none'}>
					<div className={cls.showSearchComponentSelectWithSearchContainer}>
						<SingleSelectWithCheckboxesAndNestedItems
							placeholder={'Select component to attach ...'}
							items={blueprints.map(i => {
								return { value: i?.id, label: i?.name, type: i?.readonly === true ? 'Template' : groupings(i?.name) };
							})}
							id={'select-step-new-blueprint-component'}
							initialSelectedValues={[]}
							groupedBy={'type'}
							groupname={'type'}
							inputStyle={{ cursor: 'pointer' }}
							className={cls.peoplePositionsSelectBorderless}
							inputClassName={cls.peoplePositionsSelectInput}
							molclassname={cls.peoplePositionsSelectMenuOverlay}
							moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
							onChange={val => {
								if (val?.length > 0) {
									setSelectedComponent(val[0]);
								} else {
									setSelectedComponent({ value: '', label: '' });
								}
							}}
						>
							<FontAwesomeIcon className="fa-icon" icon={faChevronDown} style={{ marginRight: '20px', color: 'gray' }} />
						</SingleSelectWithCheckboxesAndNestedItems>

						{selectedComponent?.value?.length > 0 && (
							<button type="button" className={cls.showSearchComponentSelectWithSearchAddComponentButton}>
								<DoneMarkIcon
									onClick={() => {
										onComponentSelect(selectedComponent);
										setShowSearchComponent(c => !c);
									}}
								/>
							</button>
						)}
					</div>
				</div>
			) : (
				<p style={{ textAlign: 'center', marginBottom: 'auto', color: 'var(--app-color-gray-light)', fontFamily: 'var(--app-text-main-font)', fontSize: '16px', marginTop: '20px', lineHeight: '32px' }}>{'Finish adding your previous component to add another.'}</p>
			)}
		</div>
	);
};
