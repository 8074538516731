import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { getUsersFilter } from '../../../services/user.service';
import { getProcessesFilters } from '../../../services/processes.service';
import { getComponentsFilters } from '../../../services/components.service';
import Button from '../UI/Button/Button';
import CheckboxR from '../UI/Checkbox/CheckboxR';
import Spinner from '../UI/Spinner/Spinner';
import SortByDropdown from '../SortByDropDown/SortByDropdown';
import cls from './LeftSideFilter.module.scss';
import { first } from 'rxjs/operators';
import { connect } from 'react-redux';

const LeftSideFilter = props => {
	const { topButton, display, onFilters, resetButton, filterFor, containerClassName, filters } = props;
	const { tb_title, tb_className, tb_icon, tb_iconClassName, tb_clicked } = topButton ?? {};
	const { rb_title, rb_className, rb_icon, rb_iconClassName, rb_clicked, rb_loading, rb_loaderRadius, rb_filteringActive } = resetButton ?? {};

	const { onDisplayChanged } = display ?? {};
	const { department, position, blueprint } = onFilters ?? {};
	const { onFilterByDepartments } = department ?? {};
	const { onFilterByPosition } = position ?? {};
	const { onFilterByBlueprints } = blueprint ?? {};
	const [loadingDepartments, setLoadingDepartments] = useState(false);
	const settingLoadingDeps = val => setLoadingDepartments(val);
	const [loadingPositions, setLoadingPositions] = useState(true);
	const settingLoadingPos = val => setLoadingPositions(val);
	const [filtersData, setFiltersData] = useState(filters[filterFor] === undefined ? [] : filters[filterFor]);
	const [resetFiltersTrigger, setResetFiltersTrigger] = useState(false);
	const [filteringActive, setFilteringActive] = useState(rb_filteringActive);
	const settingFilteringActive = val => setFilteringActive(val);
	useEffect(() => {
		settingLoadingDeps(true);
		settingLoadingPos(true);

		if (filterFor === 'users') {
			getUsersFilter()
				.pipe(first())
				.subscribe({
					next: data => {
						if (typeof data === 'object' && data !== null) {
							setFiltersData(filters.users);
						}
						settingLoadingDeps(false);
						settingLoadingPos(false);
					},
					error: _error => {
						settingLoadingDeps(false);
						settingLoadingPos(false);
					},
				});
		}
		if (filterFor === 'processes') {
			getProcessesFilters()
				.pipe(first())
				.subscribe({
					next: data => {
						if (typeof data === 'object' && data !== null) {
							setFiltersData(filters.processes);
						}
						settingLoadingDeps(false);
						settingLoadingPos(false);
					},
					error: _error => {
						settingLoadingDeps(false);
						settingLoadingPos(false);
					},
				});
		}
		if (filterFor === 'components') {
			getComponentsFilters()
				.pipe(first())
				.subscribe({
					next: data => {
						if (typeof data === 'object' && data !== null) {
							setFiltersData(filters.components);
						}
						settingLoadingDeps(false);
						settingLoadingPos(false);
					},
					error: _error => {
						settingLoadingDeps(false);
						settingLoadingPos(false);
					},
				});
		}
	}, []);
	useEffect(() => settingFilteringActive(rb_filteringActive), [rb_filteringActive]);
	const handleResetFilters = useCallback(() => {
		setResetFiltersTrigger(c => !c);
		rb_clicked && rb_clicked();
		setFilteringActive(false);
	}, [rb_clicked, setResetFiltersTrigger]);
	return (
		<div className={classNames(cls.host, containerClassName)}>
			{topButton && <Button title={tb_title} className={classNames(cls.topButton, tb_className)} icon={tb_icon && <img src={tb_icon} className={classNames(cls.topButtonIcon, tb_iconClassName)} alt="tbi" />} clicked={tb_clicked} />}
			{display && (
				<div className={cls.filterTitleContainser}>
					<p className={cls.filterTitle}>{'Display'}</p>
					<SortByDropdown onChange={onDisplayChanged} />
				</div>
			)}
			{onFilters && (
				<div className={cls.filterTitleContainser}>
					<p className={cls.filterTitle}> {`${filteringActive ? ' active' : ''}`} </p>
					{resetButton && filteringActive && (
						<Button
							light
							loaderRadius={rb_loaderRadius}
							loading={rb_loading}
							title={rb_title}
							className={classNames(cls.resetButton, rb_className)}
							icon={rb_icon && <img src={rb_icon} className={classNames(cls.resetButtonIcon, rb_iconClassName)} alt="rbi" />}
							clicked={() => handleResetFilters()}
						/>
					)}
					{department && filtersData.departments?.length > 0 && (
						<div className={cls.filterCategoryContainer}>
							<p className={cls.filterCategory}>{'Department'}</p>
							{loadingDepartments && (
								<span className={cls.filterCategoryLoaderContainer}>
									<Spinner type={'BallTriangle'} color="var(--app-color-gray-light)" height={15} width={`${30}px`} />
								</span>
							)}
							{filtersData.departments?.length > 0 &&
								filtersData.departments?.map((item, index) => {
									return (
										<CheckboxR
											key={index}
											labelclassname={cls.checkboxLabelClassname}
											className={cls.checkboxClassname}
											resetTrigger={resetFiltersTrigger}
											onChange={event => {
												setFilteringActive(true);
												onFilterByDepartments({ item, index, state: event.target.checked ?? false });
											}}
										>
											{item.name} {' ('}
											{item.user_count ?? item.process_count}
											{')'}
										</CheckboxR>
									);
								})}
						</div>
					)}
					{position && filtersData.positions?.length > 0 && (
						<div className={cls.filterCategoryContainer}>
							<p className={cls.filterCategory}>{'Position'}</p>
							{loadingPositions && (
								<span className={cls.filterCategoryLoaderContainer}>
									<Spinner type={'BallTriangle'} color="var(--app-color-gray-light)" height={15} width={`${30}px`} />
								</span>
							)}
							{filtersData?.positions?.length > 0 &&
								filtersData?.positions?.map((item, index) => {
									return (
										<CheckboxR
											checked={true}
											key={index}
											labelclassname={cls.checkboxLabelClassname}
											className={cls.checkboxClassname}
											resetTrigger={resetFiltersTrigger}
											onChange={event => {
												setFilteringActive(true);
												onFilterByPosition({ item, index, state: event.target.checked });
											}}
										>
											{item.name}
											{' ('}
											{item.user_count ?? item.process_count}
											{')'}
										</CheckboxR>
									);
								})}
						</div>
					)}
					{blueprint && filtersData.blueprints?.length > 0 && (
						<div className={cls.filterCategoryContainer}>
							<p className={cls.filterCategory}>{'Blueprint'}</p>
							{loadingPositions && (
								<span className={cls.filterCategoryLoaderContainer}>
									<Spinner type={'BallTriangle'} color="var(--app-color-gray-light)" height={15} width={`${30}px`} />
								</span>
							)}
							{filtersData?.blueprints?.length > 0 &&
								filtersData?.blueprints?.map((item, index) => {
									return (
										<CheckboxR
											checked={true}
											key={index}
											labelclassname={cls.checkboxLabelClassname}
											className={cls.checkboxClassname}
											resetTrigger={resetFiltersTrigger}
											onChange={event => {
												setFilteringActive(true);
												onFilterByBlueprints({ item, index, state: event.target.checked });
											}}
										>
											{item.name}
											{' ('}
											{item?.user_count ?? item?.process_count ?? item?.components_count}
											{')'}
										</CheckboxR>
									);
								})}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = store => {
	const { filters } = store.flowReducer;
	return { filters };
};

export default connect(mapStateToProps)(LeftSideFilter);

export const LeftSide = props => {
	const { buttons, containerClassName } = props;
	const { tb_title, tb_title2, tb_className, tb_icon, tb_iconClassName, tb_clicked, tb_clicked2 } = buttons ?? {};

	return (
		<div className={classNames(cls.host, containerClassName)}>
			{buttons && <Button title={tb_title} className={classNames(cls.topButton, tb_className)} icon={tb_icon && <img src={tb_icon} className={classNames(cls.topButtonIcon, tb_iconClassName)} alt="tbi" />} clicked={tb_clicked} />}
			<br />
			{buttons && <Button title={tb_title2} className={classNames(cls.topButton, tb_className)} icon={tb_icon && <img src={tb_icon} className={classNames(cls.topButtonIcon, tb_iconClassName)} alt="tbi" />} clicked={tb_clicked2} />}
		</div>
	);
};
