//auth

export const SET_SESSION_SETTINGS = 'SET_SESSION_SETTINGS';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SET_LAST_KNOWN_ROUTE = 'SET_LAST_KNOWN_ROUTE';
export const RESET_AUTH_REDUCER = 'RESET_AUTH_REDUCER';
//flowReducer
//--temporary flag--
export const SET_SERVER_FEATURE_NOT_READY_YET = 'SET_SERVER_FEATURE_NOT_READY_YET';
//--onboarding modals and org chart--
export const SET_COMPANY_POSSITIONS_ARRAY = 'SET_COMPANY_POSSITIONS_ARRAY';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_INVITE_COLLEGUES_LIST = 'SET_INVITE_COLLEGUES_LIST';
export const EDIT_INVITE_COLLEGUES_LIST = 'EDIT_INVITE_COLLEGUES_LIST';
export const SET_COMPANY_ORG_CHART_DATA = 'SET_COMPANY_ORG_CHART_DATA';
export const SET_COMPANY_DEPARTMENTS = 'SET_COMPANY_DEPARTMENTS';
export const SET_POSITION_INFO = 'SET_POSITION_INFO';
export const SET_DUPLICATE_POSITION_INFO = 'SET_DUPLICATE_POSITION_INFO';
export const SET_ONBOARDING_COMPLETED = 'SET_ONBOARDING_COMPLETED';
export const SET_PAGED_MODAL_OPEN = 'SET_PAGED_MODAL_OPEN';
export const SET_PAGED_MODAL_CLOSE = 'SET_PAGED_MODAL_CLOSE';

//--tasks--
export const SET_TASKS = 'SET_TASKS';
export const SET_COMPLETED_TASKS = 'SET_COMPLETED_TASKS';
export const DELETE_TASK = 'DELETE_TASK';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';

//--processes--
export const SET_COMPANY_PROCESSES = 'SET_COMPANY_PROCESSES';
export const UPDATE_COMPANY_PROCESSES = 'UPDATE_COMPANY_PROCESSES';
export const SET_SELECTED_PROCESS = 'SET_SELECTED_PROCESS';
export const SET_SELECTED_PROCESS_STEP = 'SET_SELECTED_PROCESS_STEP';
export const SET_SELECTED_PROCESS_STEP_OPTIONS = 'SET_SELECTED_PROCESS_STEP_OPTIONS';
export const SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT = 'SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT';
export const REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT = 'REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT';
export const RESET_SELECTED_PROCESS_STEP = 'RESET_SELECTED_PROCESS_STEP';
export const SET_BEGUN_PROCESS_TASK = 'SET_BEGUN_PROCESS_TASK';
export const CHANGE_TASK_PROCESS = 'CHANGE_TASK_PROCESS';
export const SET_ALL_MODULES = 'SET_ALL_MODULES';
export const SET_BEGUN_PROCESS_MODULE = 'SET_BEGUN_PROCESS_MODULE';
export const CHANGE_MODULE_PROCESS = 'CHANGE_MODULE_PROCESS';
//--components--
export const SET_COMPONENTS = 'SET_COMPONENTS';
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const SET_BLUEPRINT_COMPONENT_TYPES = 'SET_BLUEPRINT_COMPONENT_TYPES';
//--blueprints--
export const SET_BLUEPRINT_COMPONENTS = 'SET_BLUEPRINT_COMPONENTS';
export const SET_NEW_COMPONENT_BLUEPRINT = 'SET_NEW_COMPONENT_BLUEPRINT';
export const DELETE_COMPONENT_BLUEPRINT = 'DELETE_COMPONENT_BLUEPRINT';
export const SET_SELECTED_COMPONENT_BLUEPRINT = 'SET_SELECTED_COMPONENT_BLUEPRINT';
//--statutes--
export const SET_STATUTES = 'SET_STATUTES';
export const ADD_NEW_STATUS = 'ADD_NEW_STATUS';
//--company--
export const SET_USERS_ARRAY = 'SET_USERS_ARRAY';
export const SET_UNASSIGNED_USSERS_ARRAY = 'SET_UNASSIGNED_USSERS_ARRAY';
//--users--
export const DELETE_USER = 'DELETE_USER';

export const RESET_FLOW_REDUCER = 'RESET_FLOW_REDUCER';

//modals
export const SET_IS_ONBOARDING = 'SET_IS_ONBOARDING';
export const SET_ONBOARDING_VISIBLE = 'SET_ONBOARDING_VISIBLE';
export const SET_ONBOARDING_MODALS_VISIBILITY = 'SET_ONBOARDING_MODALS_VISIBILITY';

export const ERRORS_MODAL_OPEN = 'ERRORS_MODAL_OPEN';
export const ERRORS_MODAL_CLOSE = 'ERRORS_MODAL_CLOSE';

export const INFO_MODAL_OPEN = 'INFO_MODAL_OPEN';
export const INFO_MODAL_CLOSE = 'INFO_MODAL_CLOSE';

export const PREVIEW_CHART_MODAL_OPEN = 'PREVIEW_CHART_MODAL_OPEN';
export const PREVIEW_CHART_MODAL_CLOSE = 'PREVIEW_CHART_MODAL_CLOSE';

export const TUTORIAL_MODAL_OPEN = 'TUTORIAL_MODAL_OPEN';
export const TUTORIAL_MODAL_CLOSE = 'TUTORIAL_MODAL_CLOSE';

export const SET_USER_OVERVIEW_MODAL_OPEN = 'SET_USER_OVERVIEW_MODAL_OPEN';
export const SET_USER_OVERVIEW_MODAL_CLOSE = 'SET_USER_OVERVIEW_MODAL_CLOSE';
export const RESET_MODALS_REDUCER = 'RESET_MODALS_REDUCER';

export const SET_LAST_UPDATE = 'SET_LAST_UPDATE';
export const SET_LAST_COMPONENT_UPDATE = 'SET_LAST_COMPONENT_UPDATE';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const EDIT_TASK = 'EDIT_TASK';

export const ADD_FAVORITE = 'ADD_FAVORITE';
export const SET_FAVORITES = 'SET_FAVORITES';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';

export const SET_MESSAGE_BAR = 'SET_MESSAGE_BAR';
export const SET_SHOW_MENU = 'SET_SHOW_MENU';
export const SET_SHOW_SIDE_MENU = 'SET_SHOW_SIDE_MENU';
export const SET_CART = 'SET_CART';
export const SET_BILLABLE = 'SET_BILLABLE';
export const SET_BILLING_PRODUCTS = 'SET_BILLING_PRODUCTS';
export const SET_PAYABLE = 'SET_PAYABLE';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
