import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartThreeState {
  series: number[];
}



export type ChartThreeProps = {
  series: any;
  labels: any;
}

const ChartThree = (props: ChartThreeProps) => {
  const { series, labels } = props
  const [state, setState] = useState<ChartThreeState>({
    series: series,
  });

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'donut',
    },
    colors: ['#3C50E0', '#6577F3', '#8FD0EF', '#0FADCF'],
    labels: labels,
    legend: {
      show: false,
      position: 'bottom',
    },

    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 380,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      series: series,
    }));
  };
  handleReset;

  return (
    <div className="mb-2">
      <div id="chartThree" className="mx-auto flex justify-center">
        <ReactApexChart
          options={options}
          series={state.series}
          type="donut"
        />
      </div>
    </div>
  );
};

export default ChartThree;
