import React, { useState, useCallback } from 'react';
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
} from '@daily-co/daily-react';

import './Call.css';
import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';
import Username from '../Username/Username';
import { isMobile } from 'react-device-detect';

export default function Call() {
  /* If a participant runs into a getUserMedia() error, we need to warn them. */
  const [getUserMediaError, setGetUserMediaError] = useState(false);

  /* We can use the useDailyEvent() hook to listen for daily-js events. Here's a full list
   * of all events: https://docs.daily.co/reference/daily-js/events */
  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  /* This is for displaying remote participants: this includes other humans, but also screen shares. */
  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  /* This is for displaying our self-view. */
  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantIds.length < 1 ;

  const renderCallScreen = () => (
    <div className={'call'} style={{display: 'flex', flexDirection: isMobile ? 'column': 'row'}}>
      {/* Your self view */}
      {localSessionId && (
        
        <Tile
          id={localSessionId}
          isLocal
          isAlone={isAlone}
        />
        
      )}
      {/* Videos of remote participants and screen shares */}
      {remoteParticipantIds.length > 0 || screens.length > 0 ? (
        <>
          {remoteParticipantIds.map((id) => (
            <Tile key={id} id={id} />
          ))}
          
        </>
      ) : (
        // When there are no remote participants or screen shares
        <div className="info-box">
          <p>{'Waiting for the other participant'}</p>
        </div>
      )}
    </div>
  );

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
}
