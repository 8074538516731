import moment, { now } from 'moment';
import { APPEND_TO_DATA, CLEAR_TO_SET_DATA } from '../../shared/utility';
import {
	ADD_COMPONENT,
	ADD_NEW_STATUS,
	ADD_NEW_TASK,
	UPDATE_COMPONENT,
	DELETE_COMPONENT,
	DELETE_COMPONENT_BLUEPRINT,
	DELETE_TASK,
	DELETE_USER,
	EDIT_INVITE_COLLEGUES_LIST,
	REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
	RESET_SELECTED_PROCESS_STEP,
	SET_BEGUN_PROCESS_TASK,
	SET_BLUEPRINT_COMPONENTS,
	SET_COMPANY_DATA,
	SET_COMPANY_DEPARTMENTS,
	SET_COMPANY_ORG_CHART_DATA,
	SET_COMPANY_POSSITIONS_ARRAY,
	SET_COMPANY_PROCESSES,
	UPDATE_COMPANY_PROCESSES,
	SET_COMPLETED_TASKS,
	SET_COMPONENTS,
	SET_BLUEPRINT_COMPONENT_TYPES,
	SET_DUPLICATE_POSITION_INFO,
	SET_INVITE_COLLEGUES_LIST,
	SET_NEW_COMPONENT_BLUEPRINT,
	SET_ONBOARDING_COMPLETED,
	SET_POSITION_INFO,
	SET_SELECTED_COMPONENT_BLUEPRINT,
	SET_SELECTED_PROCESS,
	SET_SELECTED_PROCESS_STEP,
	SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
	SET_SELECTED_PROCESS_STEP_OPTIONS,
	SET_SERVER_FEATURE_NOT_READY_YET,
	SET_STATUTES,
	SET_TASKS,
	SET_USERS_ARRAY,
	CHANGE_TASK_PROCESS,
	RESET_FLOW_REDUCER,
	SET_BEGUN_PROCESS_MODULE,
	CHANGE_MODULE_PROCESS,
	SET_ALL_MODULES,
	SET_LAST_UPDATE,
	SET_FILTERS,
	SET_CURRENT_TASK,
	SET_PRODUCTS,
	ADD_PRODUCT,
	DELETE_PRODUCT,
	SET_TRANSACTIONS,
	ADD_TRANSACTION,
	DELETE_TRANSACTION,
	EDIT_TASK,
	ADD_FAVORITE,
	SET_FAVORITES,
	DELETE_FAVORITE,
	SET_MESSAGE_BAR,
	SET_SHOW_MENU,
	SET_SHOW_SIDE_MENU,
	SET_LAST_COMPONENT_UPDATE,
	SET_CART,
	SET_BILLABLE,
	SET_BILLING_PRODUCTS,
	SET_PAYABLE,
	SET_CURRENT_LOCATION
} from '../actions/types';
import { appDefaultReducer } from './defaultReducer';
const R = require('ramda');

const INITIAL_STATE = appDefaultReducer.flowReducer;

export default function flowReducer(state = INITIAL_STATE, action) {
	const { type, payload, is_resumed, blueprints } = action;
	switch (type) {
		case SET_INVITE_COLLEGUES_LIST:
			return { ...state, inviteColleguesList: [...payload] };
		case EDIT_INVITE_COLLEGUES_LIST:
			return { ...state, inviteColleguesList: [...state.inviteColleguesList, payload] };
		case SET_COMPANY_DATA:
			return { ...state, companyData: { ...state.companyData, ...payload, start: true } };
		case SET_COMPANY_DEPARTMENTS:
			return { ...state, companyDepartments: payload };
		case SET_COMPANY_POSSITIONS_ARRAY:
			return { ...state, companyPositionsArray: payload };
		case SET_POSITION_INFO:
			return { ...state, positionInfo: { ...state.positionInfo, ...payload } };
		case SET_DUPLICATE_POSITION_INFO:
			return { ...state, duplicatePositionInfo: payload };
		case SET_USERS_ARRAY:
			return { ...state, companyUsersArray: payload };
		case SET_ONBOARDING_COMPLETED:
			return { ...state, onBoardingCompleted: payload };
		case SET_COMPANY_ORG_CHART_DATA:
			return { ...state, companyOrgChart: payload };
		case SET_COMPANY_PROCESSES:
			if (action.page === CLEAR_TO_SET_DATA) {
				return { ...state, companyProcesses: payload };
			} else if (action.page === APPEND_TO_DATA) {
				const ids = payload.map(com => com.id);
				const clean_companyProcesses = state.companyProcesses.filter(item => ids.indexOf(item.id) === -1);

				return {
					...state,
					companyProcesses: clean_companyProcesses.concat(payload).filter(proc => proc.readonly !== true),
					workflowTemplates: clean_companyProcesses.concat(payload).filter(proc => proc.readonly === true),
				};
			} else
				return {
					...state,
					companyProcesses: state.companyProcesses.concat(payload).filter(proc => proc.readonly !== true),
					workflowTemplates: state.companyProcesses.concat(payload).filter(proc => proc.readonly === true),
				};
		case UPDATE_COMPANY_PROCESSES:
			if (payload?.readonly === true) {
				return {
					...state,
					workflowTemplates: [...state.workflowTemplates.filter((item, _index) => item.id !== payload.id), payload],
				};
			} else {
				return {
					...state,
					companyProcesses: [...state.companyProcesses.filter((item, _index) => item.id !== payload.id), payload],
				};
			}

		case SET_SELECTED_PROCESS:
			return { ...state, selectedProcess: payload };
		case SET_SELECTED_PROCESS_STEP:
			return { ...state, selectedProStep: { ...state.selectedProStep, ...(payload ?? {}) } };
		case SET_SELECTED_PROCESS_STEP_OPTIONS:
			return {
				...state,
				selectedProStep: { ...state.selectedProStep, options: { ...state.selectedProStep.options, ...payload } },
			};
		case SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT:
			return {
				...state,
				selectedProStep: {
					...state.selectedProStep,
					component_blueprints: [...state.selectedProStep.component_blueprints.filter(i => i.id != payload?.id), payload],
				},
			};
		case REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT: {
			return {
				...state,
				selectedProStep: {
					...state.selectedProStep,
					component_blueprints: [...state.selectedProStep.component_blueprints.filter(i => i.id != payload)],
				},
			};
		}
		case RESET_SELECTED_PROCESS_STEP:
			return { ...state, selectedProStep: { ...INITIAL_STATE.selectedProStep, isNewStep: true } };

		case SET_NEW_COMPONENT_BLUEPRINT: {
			return { ...state, newComponentBlueprint: { ...state.newComponentBlueprint, ...payload } };
		}
		case SET_SELECTED_COMPONENT_BLUEPRINT: {
			return { ...state, selectedBlueprintComponent: payload };
		}
		case SET_BEGUN_PROCESS_TASK:
			return { ...state, begunProcess: { ...state.begunProcess, processTask: payload, isStarted: true, is_resumed } };
		case CHANGE_TASK_PROCESS:
			return { ...state, begunProcess: { ...state.begunProcess, processTask: { ...state.begunProcess.processTask, process: payload } } };
		case SET_TASKS:
			return { ...state, allTasks: payload };
		case DELETE_TASK:
			return { ...state, allTasks: state.allTasks.filter((item, _index) => item.id !== payload) };
		case ADD_NEW_TASK:
			return { ...state, allTasks: [...state.allTasks, payload] };
		case EDIT_TASK:
			return payload.completed_at ? {
				...state,
				allTasks: [...state.allTasks.filter((item, _index) => item.id !== payload.id)],
			} :
				{
					...state,
					allTasks: [...state.allTasks.filter((item, _index) => item.id !== payload.id), payload],
				};
		case SET_COMPLETED_TASKS:
			if (action.page === 1) {
				return { ...state, allCompletedTasks: payload, allCompletedTasksPaginated: payload.data };
			} else
				return {
					...state,
					allCompletedTasks: payload,
					allCompletedTasksPaginated: state.allCompletedTasksPaginated.concat(payload.data),
				};
		case SET_STATUTES:
			return { ...state, allStatutes: payload };
		case DELETE_USER:
			return { ...state, companyUsersArray: state.companyUsersArray.filter((item, _index) => item.id !== payload) };
		case UPDATE_COMPONENT: {

			let procedure = { id: '' };
			let componentBlueprints = [];
			let selectedFields = [];


			payload.component_fields.map(cf => {
				if (payload.procedure_id !== process?.id) {
					procedure = state.companyProcesses.find(proc => proc.id === payload.procedure_id);
					if (procedure?.steps) {
						componentBlueprints = R.pipe(R.pluck('component_blueprints'), R.flatten)(procedure?.steps);
						selectedFields = R.pipe(R.pluck('selected_fields'), R.flatten)(componentBlueprints);
					}

				}
				const sf = selectedFields.find(sf => sf.procedure_step_blueprint_field_id === cf.procedure_step_blueprint_field_id)
				if (sf) {
					cf.data_type_name = sf.data_type_name;
					cf.data_type = sf.data_type;
					cf.field_name = sf.field_name;
					cf.options = sf.options;
					cf.component_blueprint_id = componentBlueprints.find(cb => cb.selected_fields.filter(sf => sf.procedure_step_blueprint_field_id === cf.procedure_step_blueprint_field_id).length > 0)?.id
				}
			})

			return {
				...state,
				components: [...state.components.filter((item, _index) => item.id !== payload.id), payload],
			};
		}
		case ADD_COMPONENT:
			return {
				...state,
				components: [...state.components, payload],
			};
		case SET_COMPONENTS: {

			let procedure = { id: '' };
			let componentBlueprints = [];
			let selectedFields = [];

			const updateCmp = (comp) => {
				(comp?.component_fields ?? []).map(cf => {
					if (comp.procedure_id !== process?.id) {
						procedure = state.companyProcesses.find(proc => proc.id === comp.procedure_id);
						if (procedure?.steps) {
							componentBlueprints = R.pipe(R.pluck('component_blueprints'), R.flatten)(procedure?.steps);
							selectedFields = R.pipe(R.pluck('selected_fields'), R.flatten)(componentBlueprints);
						}

					}
					const sf = selectedFields.find(sf => sf.procedure_step_blueprint_field_id === cf.procedure_step_blueprint_field_id)
					if (sf) {
						cf.data_type_name = sf.data_type_name;
						cf.data_type = sf.data_type;
						cf.field_name = sf.field_name;
						cf.options = sf.options;
						cf.component_blueprint_id = componentBlueprints.find(cb => cb.selected_fields.filter(sf => sf.procedure_step_blueprint_field_id === cf.procedure_step_blueprint_field_id).length > 0)?.id;
						if (cf.data_type_name == 'Date' && cf.options?.auto == true) {
							if (moment(now()).diff(moment(cf?.value), 'days') > 0) {
								comp.status = 'Expired';
							}
						}

					}
				})
				if ((comp?.component_fields ?? []).length ===0){
					comp.component_fields= [];
				}
			}

			R.map(updateCmp, payload);

			if (action.page === 1) {
				return {
					...state,
					components: payload,
				};
			} else {
				const ids = payload.map(com => com.id);
				const clean_components = state.components.filter(item => ids.indexOf(item.id) === -1);

				return {
					...state,
					components: clean_components.concat(payload),
				};
			}
		}
		case SET_BLUEPRINT_COMPONENTS: {
			if (action.page === CLEAR_TO_SET_DATA) {
				return { ...state, blueprints: payload.data };
			} else
				return {
					...state,
					blueprints: [...state.blueprints, payload],
				};
		}
		case SET_BLUEPRINT_COMPONENT_TYPES: {
			return { ...state, componentTypes: payload };
		}
		case DELETE_COMPONENT_BLUEPRINT:
			return {
				...state,
				blueprints: state.blueprints.filter((item, _index) => item.id !== payload),
			};
		case DELETE_COMPONENT:
			return {
				...state,
				components: state.components.filter((item, _index) => item.id !== payload),
			};

		case ADD_NEW_STATUS:
			return { ...state, allStatutes: [...state.allStatutes, payload] };
		case SET_SERVER_FEATURE_NOT_READY_YET:
			return { ...state, serverFeatureNotReadyYet: payload };
		case RESET_FLOW_REDUCER: {
			const onboardingCompleted = state.onBoardingCompleted ?? state.companyData.onboarding_status === true;
			return {
				...state,
				...INITIAL_STATE,
				companyData: { ...INITIAL_STATE.companyData, onboarding_status: false },
				onBoardingCompleted: onboardingCompleted,
			};
		}
		case SET_BEGUN_PROCESS_MODULE:
			return { ...state, begunModule: { ...state.begunModule, processModule: payload } };
		case CHANGE_MODULE_PROCESS:
			return { ...state, begunModule: { ...state.begunModule, processModule: { ...state.begunModule.processModule, ...payload } } };
		case SET_ALL_MODULES:
			if (action.page === 1) {
				return { ...state, allModules: payload };
			} else {
				const ids = payload.map(com => com.id);
				const clean_modules = state.allModules.filter(item => ids.indexOf(item.id) === -1);

				return {
					...state,
					allModules: clean_modules.concat(payload),
				};
			}
		case SET_LAST_UPDATE:
			return { ...state, lastUpdates: { ...payload } };
		case SET_LAST_COMPONENT_UPDATE:
			return {
				...state,
				// lastUpdates: {
				// 	...state.lastUpdates,
				// 	componentsByModule: {
				// 		...state.lastUpdates.componentsByModule,
				// 		...payload,
				// 	},
				// },
			};
		case SET_FILTERS:
			return { ...state, filters: payload };
		case SET_CURRENT_TASK:
			return { ...state, currentTask: payload };
		case SET_PRODUCTS:
			return { ...state, products: payload };
		case ADD_PRODUCT:
			return {
				...state,
				products: [...state.products, payload],
			};
		case DELETE_PRODUCT:
			return {
				...state,
				products: state.products.filter((item, _index) => item.id !== payload),
			};
		case SET_TRANSACTIONS:
			return { ...state, transactions: payload };
		case ADD_TRANSACTION:
			return {
				...state,
				transactions: [...state.transactions, payload],
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				transactions: state.transactions.filter((item, _index) => item.id !== payload),
			};

		case SET_FAVORITES:
			return { ...state, favorites: payload };
		case ADD_FAVORITE:
			return {
				...state,
				favorites: [...state.favorites, payload],
			};
		case DELETE_FAVORITE:
			return {
				...state,
				favorites: state.favorites.filter((item, _index) => item.id !== payload),
			};
		case SET_MESSAGE_BAR:
			return {
				...state,
				showMessage: payload,
			};
		case SET_SHOW_MENU:
			return {
				...state,
				showMenu: payload,
			};
		case SET_SHOW_SIDE_MENU:
			return {
				...state,
				showSideMenu: payload,
			};
		case SET_CART:
			return {
				...state,
				cart: payload,
			};
		case SET_BILLABLE:
			return {
				...state,
				billable: payload,
			};
		case SET_BILLING_PRODUCTS:
			return {
				...state,
				billProducts: payload,
			};
		case SET_PAYABLE:
			return {
				...state,
				payable: payload,
			};
		case SET_CURRENT_LOCATION:
			return {
				...state,
				currentLocation: payload,
			};
		default:
			return state;
	}
}
