import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { sessionReducer } from 'redux-react-session';
import authReducer from './authReducer';
import flowReducer from './flowReducer';
import modalsReducer from './modalsReducer';
export const reducersPersistConfig = {
	key: 'persistConfig',
	storage: storage,
	whitelist: ['authReducer', 'sessionReducer', 'flowReducer'], // which reducer want to store
	blacklist: ['modalsReducer'],
};

export const appReducer = combineReducers({
	authReducer: authReducer,
	modalsReducer: modalsReducer,
	flowReducer: flowReducer,
	sessionReducer: sessionReducer,
});
