import { useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { setCompanyData } from '../../../reduxStore/actions/flowActions';
import { Button, CheckboxR, ModalR } from '../../common';
import cls from './Onboarding_intro.module.scss';
const videoURL = 'https://www.youtube.com/watch?v=xV-bOHvfbPQ';
const Intro = props => {
	const { modalVisible, onCancel, modalWrapperStyle, shadowedContainerStyle, childrenWrapperStyle, loading, onContinueButtonAction, title = '', subtitle = '', video, checkbox } = props;
	const [commitChecked, setCommitChecked] = useState(checkbox ? false : true);
	const handleCheckbox = checked => {
		setCommitChecked(checked);
	};
	return (
		<div style={{}}>
			<ModalR blured={true} scrollable={true} isOpen={modalVisible} onClose={onCancel} modalWrapperStyle={modalWrapperStyle} shadowedContainerStyle={shadowedContainerStyle}>
				<div className={cls.firstDiv_I} style={{ ...childrenWrapperStyle }}>
					<div className={cls.headerContainer_I}>
						<h1 className={cls.header_I}>{title}</h1>
					</div>
					<div id={'video-container'} className={cls.videoContainer_I}>
						{video && <ReactPlayer controls={true} width="100%" height="100%" url={video} className={cls.reactPlayerStyle_I} />}
						{props.children}
					</div>
					<div className={cls.checkboxContainer_I}>
						{checkbox && (
							<CheckboxR value="agree" checked={commitChecked} onChange={e => handleCheckbox(e.nativeEvent?.target?.checked)} className={cls.checkboxOnboarding_I} labelclassname={cls.checkboxLabelOnboarding_I} style={{ minWidth: '24px', width: '24px', height: '24px' }}>
								<p className={cls.checkboxChild_I}>{checkbox}</p>
							</CheckboxR>
						)}
					</div>
					<div className={cls.buttonContainer_I}>
						<Button
							disabled={!commitChecked}
							loading={loading ? loading : undefined}
							title={'Continue'}
							clicked={e => {
								onContinueButtonAction(e);
							}}
						></Button>
					</div>
				</div>
			</ModalR>
		</div>
	);
};

const mapStateToProps = store => {
	const { authenticatedUser } = store.authReducer;
	return { authenticatedUser };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetCompanyDataField: field => dispatch(setCompanyData(field)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Intro);
