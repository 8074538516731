import cls from './TaskBoardPanel.module.scss';

const TaskBoardPanel = props => {
	const { iconSrc, cardTitle, children, style, endLabel = '' } = props;
	return (
		<div className={cls.taskBoardPanelHost} style={style}>
			<div className={cls.container}>
				<div className={cls.titleContainer}>
					{iconSrc && <img alt="" src={iconSrc} />}
					{cardTitle && <p className={cls.cardTitle}>{cardTitle}</p>}
				</div>
				<div style={{ fontSize: '12px', textAlign: 'right' }}>{endLabel}</div>
				{children}
			</div>
		</div>
	);
};

export default TaskBoardPanel;
