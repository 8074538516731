import { createReducerContext } from 'react-use';
const R = require('ramda');
export const modulesContextAction = {
	setModules: 'SET_MODULES',
	removeModule: 'DELETE_MODULE',
	addModule: 'ADD_MODULE',
	setSelectedModule: 'SET_SELECTED_MODULE',
	updateModule: 'UPDATE_MODULE',
};
export const lensMatch = propName => key => R.lens(R.find(R.propEq(propName, key)), (val, arr, idx = R.findIndex(R.propEq(propName, key))(arr)) => R.update(idx > -1 ? idx : R.length(arr), val, arr));
const selectContextReducer = (state, payload) => {
	const { action, value } = payload;
	switch (action) {
		case modulesContextAction.setModules:
			return { ...state, modules: R.sortBy(R.prop('name'))(value) };
		case modulesContextAction.removeModule:
			return { ...state, modules: R.reject(R.propEq('id', value))(state.modules) };
		case modulesContextAction.addModule:
			return { ...state, modules: R.sortBy(R.prop('name'))([...state.modules, value]) };
		case modulesContextAction.setSelectedModule:
			return {
				...state,
				selectedModule: value,
			};
		case modulesContextAction.updateModule:
			return {
				...state,
				modules: R.sortBy(R.prop('name'))(R.map(R.ifElse(R.propEq('id', value.id), R.assoc('name', value.name), item => item))(state.modules)),
			};
		default:
			return { ...state };
	}
};

export const [useModulesContext, TheModulesContext] = createReducerContext(selectContextReducer, { modules: [], selectedModule: { id: '', name: '', procedure_id: '', options: '' } });
