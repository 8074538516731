import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { setCompanyData, setPositionInfo } from '../../../reduxStore/actions/flowActions';
import { Button, ModalR, Spinner } from '../../common';
import CompanyOrganizationalChart from '../CompanyOrganizationalChart/CompanyOrganizationalChart';
import { getAllPositions, getPositionById } from '../../../services/company.service';
import { setOnBoardingModalsVisibility, setOnBoardingVisible } from '../../../reduxStore/actions/modalsActions';
import classNames from 'classnames';
import cls from './Onboarding_basic_company.module.scss';
import { store } from '../../../reduxStore/store';

const OnboardingOrgChart = props => {
	const { onSetOnBoardingVisible, onToggleModalVisibility, onPreviousButtonAction, onContinueButtonPressed } = props;
	const [aContainerDim] = useState({ width: 0, height: 0 });
	const aContainerRef = useRef();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getAllPositions().subscribe({});
	}, []);

	const onPositionPressed = positionId => {
		setLoading(true);
		getPositionById(positionId).subscribe({
			next: () => {
				setLoading(false);
				onToggleModalVisibility({ positionModalVisible: true });
				onSetOnBoardingVisible(true);
			},
			error: () => {
				setLoading(false);
			},
			complete: () => {},
		});
	};
	return (
		<ModalR
			modalWrapperStyle={props?.modalWrapperStyle}
			shadowedContainerStyle={{ width: '100%', height: 'auto', minHeight: '100%' }}
			scrollable={true}
			backdropStyle={{ height: props.backdropStyle ? props.backdropStyle : '1000px' }}
			isOpen={props.modalVisible}
			modalClosed={() => props.onCancel()}
			blured={props.blured}
		>
			<div className={cls.firstDiv_III}>
				<div className={cls.headerContainer_III}>
					<h1 className={cls.header_III}>{'4. Your Organization '}</h1>
				</div>
				<div className={classNames(cls.subheadingContainer_III, cls.justifiedLeft_III)}>
					<p className={cls.subheading_III}>
						{
							'The organization contains roles and positions that allow you access to functions and create workflows. By default two key positions are created and they have complete access to the company. A third position, Superadmin, from which this user is assigned to, is also created but not displayed here. The previous step, Create Department, will likewise create head positions for the departments created. After creating the positions, you can assign these positions to the Users that you intend to invite or create.   Let’s fill out all the basic roles and positions your company requires. Click Add Position to begin adding roles. You can always update or change this information later in the Company menu.'
						}
					</p>
				</div>
				<div ref={aContainerRef} className={cls.chartContainer}>
					<div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
						<CompanyOrganizationalChart dimmensions={aContainerDim} onPositionPressed={onPositionPressed}>
							{loading && <Spinner size={80} type={'ThreeDots'} />}
						</CompanyOrganizationalChart>
					</div>
					<div className={cls.buttonWrapper_III}>
						<Button light clicked={onPreviousButtonAction} title={'Back'} />
						<div style={{ display: 'flex' }}>
							<Button
								light
								title={'Add Role/Position'}
								clicked={() => {
									store.dispatch(
										setPositionInfo({
											name: '',
											id: '',
											parent: undefined,
											parent_position: undefined,
											department_id: '',
											is_managerial: false,
											children: [],
											users: [],
											shared_users: [],
										})
									);
									onToggleModalVisibility({ positionModalVisible: true });
									onSetOnBoardingVisible(true);
								}}
								style={{ width: '180px' }}
							/>
							<Button clicked={onContinueButtonPressed} title={'Continue'} />
						</div>
					</div>
				</div>
			</div>
		</ModalR>
	);
};

const mapStateToProps = store => {
	const { inviteColleguesList, companyData } = store.flowReducer;
	const { isOnboarding } = store.modalsReducer;
	return { inviteColleguesList, companyData, isOnboarding };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetCompanyDataField: field => dispatch(setCompanyData(field)),
		onSetOnBoardingVisible: visible => dispatch(setOnBoardingVisible(visible)),
		onToggleModalVisibility: modalVisible => dispatch(setOnBoardingModalsVisibility(modalVisible)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingOrgChart);
