import moment from 'moment';
import { sessionService } from 'redux-react-session';
import { Observable } from 'rxjs';
import { resetAuthReducer, setSessionSettings } from '../reduxStore/actions/authActions';
import { resetFlowReducer, setMessageBar, setShowMenu } from '../reduxStore/actions/flowActions';
import { resetModalsReducer } from '../reduxStore/actions/modalsActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

const dispatch = fn => store.dispatch(fn);
export const userRegistration = userData => {
	const data = new FormData();
	data.append('email', userData?.email);
	data.append('username', userData?.username);
	data.append('password', userData?.password);
	data.append('password_confirmation', userData?.password);
	data.append('invitation_id', userData?.invitation_id);
	data.append('timezone', userData?.timezone);
	data.append('validity', userData?.validity);
	Object.keys(userData?.options ?? {}).length > 0 && data.append('options', userData?.options);

	const config = {
		...URLS.auth.register,
		data: data,
		handlerEnabled: true,
		rememberMe: false,
		retrieveAuthenticatedUser: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const forgetPasswordRequest = email => {
	const data = new FormData();
	data.append('email', email);
	const config = {
		...URLS.auth.forgotPassword,
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => subscriber.error(error))
	);
};
export const resetPassword = (email, password, confirmPassword, token) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('token', token ?? last_access_token);
	data.append('email', email);
	data.append('password', password);
	data.append('password_confirmation', confirmPassword);

	const config = {
		...URLS.auth.resetPassword,
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => subscriber.error(error))
	);
};
export const userLogin = userData => {
	const data = new FormData();
	data.append('email', userData?.email);
	data.append('password', userData?.password);
	data.append('remember', 1);

	const config = {
		...URLS.auth.login,
		data: data,
		headers: { ...URLS.auth.login.headers },
		handlerEnabled: true,
		rememberMe: userData.rememberMe,
		retrieveAuthenticatedUser: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				try {
					if (response?.status == 200 && response?.data) {
						const sessionSettings = {
							rememberMe: userData?.rememberMe,
							expirationDate: moment()
								.add(+response.data.expires_in, 'seconds')
								.format('YYYY-MM-DD hh:mm:ss:ssss'),
							last_access_token: response?.data?.access_token,
							last_expires_in: response?.data?.expires_in,
							last_refresh_token: response?.data?.refresh_token,
						};
						dispatch(setSessionSettings(sessionSettings));
						const actualSesion = store?.getState()?.sessionReducer ?? {};

						sessionService?.saveSession?.({ ...actualSesion, ...sessionSettings }).then(() => {
							try {
								const authenticatedUser = store?.getState()?.authReducer?.authenticatedUser;
								authenticatedUser.isSingleApp = authenticatedUser.positions.filter(pos => pos.standards === '1').length > 0;
								authenticatedUser.app =
									authenticatedUser.positions
										.filter(pos => pos.standards === '1')
										?.map(pos => {
											return pos.name;
										}) ?? [];
								sessionService?.saveUser?.(authenticatedUser).then(() => {
									subscriber.next({ ...response.data, isSingleApp: authenticatedUser?.isSingleApp });
									subscriber.complete();
								});
							} catch (error) {
								subscriber.error(error);
							}
						});
					} else {
						subscriber.error(response);
					}
				} catch (ex) {
					subscriber.error(ex);
				}
			})
			.catch(error => subscriber.error(error))
	);
};
export const userLogout = (immediate = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const {
		lastUpdates
	} = store.getState().flowReducer;
	const config = {
		...URLS.auth.logout(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber => {
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					const sessionSett = {
						expirationDate: moment(),
						last_access_token: '',
						last_refresh_token: response?.data?.refresh_token,
					};
					lastUpdates.componentsByModule = {};
					dispatch(setSessionSettings(sessionSett));
					sessionService.deleteSession().then(() => sessionService.deleteUser());
					dispatch(resetAuthReducer());
					dispatch(resetFlowReducer());
					dispatch(resetModalsReducer());
					dispatch(setShowMenu(false));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
				sessionService.deleteSession().then(() => sessionService.deleteUser());
			});
		if (immediate == true) {
			subscriber.next();
		}
	});
};
export const resetApplication = () => {
	//console.log('Reset Application');
	const sessionSett = {
		expirationDate: moment(),
		last_access_token: '',
		last_refresh_token: '',
	};
	dispatch(setSessionSettings(sessionSett));
	sessionService.deleteSession().then(() => sessionService.deleteUser());
	dispatch(resetAuthReducer());
	dispatch(resetFlowReducer());
	dispatch(resetModalsReducer());
};
export const checkAuthTimeout = expirationTime => _dispatch => {
	setTimeout(() => {
		userLogout();
	}, expirationTime * 1000);
};
export const authCheckState = () => _dispatch => {
	const token = localStorage.getItem('token');
	if (!token) {
		userLogout();
	} else {
		const expirationDate = new Date(localStorage.getItem('expirationDate'));
		if (expirationDate <= new Date()) {
			userLogout();
		}
	}
};
export const gettingInvitationByHash = hash => {
	const config = {
		...URLS.auth.getInvitationByHash(hash),
		handlerEnabled: true,
		rememberMe: true,
		noTokenRetrival: true,
	};
	let observable = new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					const sessionSettings = {
						rememberMe: true,
						//  user_id: userData?.email,
						expirationDate: moment()
							.add(+response.data.expires_in * 1000, 'seconds')
							.format('YYYY-MM-DD hh:mm:ss:ssss'),
						last_access_token: response?.data?.access_token,
						last_expires_in: response?.data?.expires_in,
						last_refresh_token: response?.data?.refresh_token,
					};
					dispatch(setSessionSettings(sessionSettings));
					//  localStorage.setItem('expirationDate', expDate);

					const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };
					sessionService?.saveSession?.(currentSession).then(() => {
						const authenticatedUser = store.getState().authReducer.authenticatedUser;
						sessionService?.saveUser?.(authenticatedUser).then(() => {
							//  dispatch(setLastUsedCredentials(response.data));
							//  dispatch(setLatestLogin({ email: userData.email, password: userData.password }));
							subscriber.next(response?.data?.data);
							subscriber.complete();
						});
					});
				}
			})
			.catch(error => subscriber.error(error))
	);
	return observable;
};
export const gettingInvitationById = invitationId => {
	const config = {
		...URLS.auth.getInvitationById(invitationId),
		handlerEnabled: true,
		rememberMe: true,
	};
	let observable = new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					const sessionSettings = {
						rememberMe: true,
						//  user_id: userData?.email,
						expirationDate: moment()
							.add(+response.data.expires_in * 1000, 'seconds')
							.format('YYYY-MM-DD hh:mm:ss:ssss'),
						last_access_token: response?.data?.access_token,
						last_expires_in: response?.data?.expires_in,
						last_refresh_token: response?.data?.refresh_token,
					};
					dispatch(setSessionSettings(sessionSettings));
					const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };
					sessionService?.saveSession?.(currentSession).then(() => {
						const authenticatedUser = store.getState().authReducer.authenticatedUser;
						sessionService?.saveUser?.(authenticatedUser).then(() => {
							//  dispatch(setLastUsedCredentials(response.data));
							//  dispatch(setLatestLogin({ email: userData.email, password: userData.password }));
							subscriber.next(response.data);
							subscriber.complete();
						});
					});
				}
			})
			.catch(error => subscriber.error(error))
	);
	return observable;
};
