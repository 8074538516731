import cls from './Card.module.scss';
import classNames from 'classnames';

export const Card = props => {
	const { onClick, cardTitle, subtitle = '', className } = props;
	return (
		<div className={classNames(cls.statusCardContainer, className)} style={{ display: 'inline-block' }} onClick={() => onClick()}>
			<div className={cls.statusCard}>
				<div style={{ padding: '15px' }}>
					<div className={cls.statusTitleContainer}>
						<p>{cardTitle}</p>
					</div>
					<div className={cls.statusDescriptionContainer}>
						<p>{subtitle}</p>
					</div>
				</div>
			</div>
		</div>
	);
};
