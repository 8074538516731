import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, ModalR } from '../../../common';
import TaskForm from '../TaskForm/TaskForm';
import ModuleTaskForm from '../TaskForm/ModuleTaskForm';
import { isMobile } from 'react-device-detect';
import Select from '../../../common/UI/Select/Select';
import { DropdownIndicator } from '../../../../shared/utility';
import { connect } from 'react-redux';
import { getFilteredUsers } from '../../../../services/user.service';
import { first } from 'rxjs';
import { setUsersArray } from '../../../../reduxStore/actions/flowActions';
import { store } from '../../../../reduxStore/store';
const TaskModal = props => {
	const { isEdit, modalVisible, heading, onClose, isProcess, setIsProcessBegin, task, priority = true, shadowedContainerStyle, statuses, start_date, span, labels = {}, onUpdate = () => { }, save=true, companyUsersArray } = props;

	const [taskLabels, setTaskLabels] = useState(labels);
	const [moduleSelected, setModuleSelected] = useState(isProcess);
	const [header, setHeader] = useState(heading);

	useEffect(() => {

		let userArray = [];

		if (task) {
			if (!companyUsersArray.find(usr => usr.id === task?.creator?.id)) {
				userArray = userArray.concat(task.creator?.id);
			}
			if (!companyUsersArray.find(usr => usr.id === task?.assignee?.id)) {
				userArray = userArray.concat(task.assignee?.id);
			}


			if (userArray.length > 0) {


				getFilteredUsers(userArray)
					.pipe(first())
					.subscribe({
						next: data => {
							store.dispatch(setUsersArray(companyUsersArray.concat(data)));

						},

					});
			}
		}

	}, []);



	return (
		<div>
			<ModalR scrollable={true} shadowedContainerStyle={shadowedContainerStyle ? shadowedContainerStyle : { width: isMobile ? '96%' : '35%' }} isOpen={modalVisible} onClose={() => { }} blured={false}>
				{moduleSelected ? <div>
					<p
						style={{
							fontFamily: 'var(--app-text-main-font)',
							fontSize: isMobile? '20px':'30px',
							fontWeight: 'bold',
							color: '#4A4A4A',
							marginTop: '50px',
							marginBottom: '5px',
							textAlign: 'center',
						}}
					>
						{task?.completed_at ? `Completed Task` : header.toUpperCase()}
					</p>

					<ModuleTaskForm labels={taskLabels} showPriority={priority} statuses={statuses} task={task}
						setIsProcessBegin={setIsProcessBegin} setDisplayModal={onClose} isProcess={moduleSelected}
						isEdit={isEdit} onClose={() => onClose()} start_date={start_date} span={span}
						onUpdate={onUpdate} 
						save={save} />
				</div>
					:
					<div>
						<p
							style={{
								fontFamily: 'var(--app-text-main-font)',
								fontSize: '2rem',
								fontWeight: 'bold',
								color: '#4A4A4A',
								marginTop: '10px',
								marginBottom: '5px',
								textAlign: 'center',
							}}
						>
							{task?.completed_at ? `Completed Task` : header}
						</p>

						{isEdit && (
							<p
								style={{
									fontFamily: 'var(--app-text-main-font)',
									fontSize: '15px',
									fontWeight: '700',
									color: '#4A4A4A',
									marginTop: '5px',
									marginBottom: '5px',
									textAlign: 'center',
								}}
							>
								{`Task ID: ${task.id.slice(-6)}`}
							</p>
						)}

						<TaskForm showPriority={priority} statuses={statuses} task={task} setIsProcessBegin={setIsProcessBegin}
							setDisplayModal={onClose} isProcess={moduleSelected} isEdit={isEdit} onClose={() => onClose()} start_date={start_date}
							span={span} setTaskLabels={setTaskLabels}
							onModuleSelect={(mod) => {
								setModuleSelected(true);
								setHeader(`New Task for ${mod.name}`)
							}} />
					</div>}

			</ModalR>
		</div>
	);
};

const mapStateToProps = store => {
	const { allModules, begunModule, companyUsersArray } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { allModules, user, begunModule, companyUsersArray };
};

export default connect(mapStateToProps)(TaskModal);

