import { Reset2 } from '../../../assets/images/icons';
import cls from './LoadMoreButton.module.scss';
import classNames from 'classnames';

export const LoadMoreButton = props => {
	const { loadMorePressed, apiRequest } = props;

	return (
		<div className={cls.host}>
			<button className={cls.button} type={'button'} onClick={() => loadMorePressed()}>
				<p className={cls.paragraph}>
					<span>
						<Reset2 className={classNames(cls.icon, { [cls.animated]: apiRequest })} />{' '}
					</span>
					{' Load More'}
				</p>
			</button>
		</div>
	);
};
