import queryString from 'query-string';
import { Observable } from 'rxjs';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

export const searchAll = searchString => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const query = queryString.stringify(
		{ query: searchString },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);
	const config = {
		...URLS.search.searchAll(last_access_token, query?.length > 0 ? '?' + query : ''),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				console.log('onSearchPressed', response);
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const searchUsers = (searchString, order = 'desc') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const query = queryString.stringify(
		{ query: searchString, order: order },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);
	const config = {
		...URLS.search.searchUsers(last_access_token, query?.length > 0 ? '?' + query : ''),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const searchTasks = (searchString, order = 'desc') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const query = queryString.stringify(
		{ query: searchString, order: order },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);
	const config = {
		...URLS.search.searchTasks(last_access_token, query?.length > 0 ? '?' + query : ''),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const searchProcesses = (searchString, order = 'desc') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const query = queryString.stringify(
		{ query: searchString, order: order },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);
	const config = {
		...URLS.search.searchProcesses(last_access_token, query?.length > 0 ? '?' + query : ''),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const searchComponents = (searchString, order = 'desc') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const query = queryString.stringify(
		{ query: searchString, order: order },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);
	const config = {
		...URLS.search.searchComponents(last_access_token, query?.length > 0 ? '?' + query : ''),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const geminiAI = analyze => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		analyze: analyze,
	};
	const defaultConfig = { ...URLS.search.geminiAI(last_access_token) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
