import React from 'react';

export default props => {
	const onDragStart = (event, nodeType) => {
		event.dataTransfer.setData('application/reactflow', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	return (
		<aside>
			<div className="title">{props?.title ?? 'Workflow'}</div>
			<div className="subtitle">{props?.subtitle ?? 'Hello world'}</div>
			<div className="description">Drag box to the right pane to add a new step.</div>
			<br />
			<div className="dndnode input" onDragStart={event => onDragStart(event, 'input')} draggable>
				New Step
			</div>
			<br />
			<br />
			<br />
			<div className="description">You can connect a step to another step by dragging the handles of the box bottom on top of the handle of the target step. A plus sign should appear. A saved step message will appear if the connection is allowed.</div>
			<br />
			<div className="description">To rearrange the positions of the step in the layout, make sure to unlock the lower left lock button. Drag the step to your desired location.</div>
			<br />
			<div className="description">To delete a connection, click the line(or its label), then press delete (or backspace for some keyboards) The line should disappear and a saved step message should appear.</div>
		</aside>
	);
};
