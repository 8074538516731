import moment from 'moment';
import { applyMiddleware, legacy_createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { sessionService } from 'redux-react-session';
import thunk from 'redux-thunk';
import { appReducer, reducersPersistConfig } from './reducers';
const logger = createLogger({
	predicate: (getState, action) => ['SET_AUTHENTICATED_USER'].indexOf(action.type) > -1,
	collapsed: true,

	duration: false,
	timestamp: true,

	logErrors: true,

	diff: false,
});

const pReducer = persistReducer(reducersPersistConfig, appReducer);
const middleware = composeWithDevTools(applyMiddleware(thunk, logger));
const store = legacy_createStore(pReducer, middleware);
const persistor = persistStore(store);

const validateSession = session => {
	return !!(session?.expirationDate && session?.last_access_token && moment(session.expirationDate, 'YYYY-MM-DD hh:mm:ss:ssss')?.valueOf() > moment().valueOf());
};
const options = {
	refreshOnCheckAuth: true,
	redirectPath: store.getState().flowReducer?.onBoardingCompleted ? (store.getState().authReducer?.lastKnownRoute?.length > 0 ? store.getState().authReducer?.lastKnownRoute : '/') : '/',
	driver: 'COOKIES',
	validateSession,
};

sessionService
	.initSessionService(store, options)
	.then(() => {
		sessionService
			.loadSession(store.getState().sessionReducer)
			.then(_currentSession => {
				const redirect = store.getState().authReducer?.lastKnownRoute;
				if (redirect?.length > 0) {
					/*   */
				}
			})
			.catch(err => {});
		sessionService.refreshFromLocalStorage();
	})
	.catch(() => {});

export { persistor, store };

export type StoreType = {
	authReducer?: typeof store.authReducer,
	modalsReducer?: typeof store.modalsReducer,
	flowReducer?: typeof store.flowReducer,
	sessionReducer?: typeof store.sessionReducer,
};
