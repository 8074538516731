import React, { useEffect, useState } from 'react';
import cls from './MessageBar.module.scss';
import { useSelector } from 'react-redux';
import { store } from '../../reduxStore/store';
import { isMobile } from 'react-device-detect';
import { CloseIconWhite } from '../../assets/images/new-icons';
import { setMessageBar } from '../../reduxStore/actions/flowActions';
import { Spinner } from '../common';
import { APP_NAME } from '../../shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const MessageBar = () => {
	const { showMessage } = useSelector(store => store.flowReducer);

	useEffect(() => {
		if (showMessage?.noTimeout !== undefined && showMessage?.noTimeout == false) {
			setTimeout(() => store.dispatch(setMessageBar({ show: false, message: '' })), showMessage?.timeout ?? 10000);
		}
	}, []);

	return (
		<div
			className={cls.host}
			style={showMessage?.bottom == true ? { left: '0px', bottom: '0px', backgroundColor: showMessage?.color ?? '' } : { top: showMessage?.top ? showMessage?.top : '0px', backgroundColor: showMessage?.color ?? '' }}
			onClick={() => {
				if (showMessage?.action) {
					showMessage?.action();
				} else {
					store.dispatch(setMessageBar({ show: false, message: '' }));
				}
			}}
		>
			{showMessage?.spinner && <Spinner type={'BallTriangle'} color={showMessage?.color === 'white' ? 'black' : 'white'} height={20} width={`20px`} style={{ padding: '0 0 0 10px' }} />}
			<div className={cls.headerTopBarContainer} style={{ display: 'flex', flexDirection: 'row' }}>
				<p style={{ fontSize: isMobile ? '12px' : '', color: showMessage?.color === 'white' ? 'black' : '' }}>{showMessage?.message ?? APP_NAME}</p>
			</div>
			{!showMessage?.hasCloseIcon && (
				<div className={cls.closeContainer}>
					<div
						className={cls.closeElement}
						onClick={() => {
							if (showMessage?.action) {
								showMessage?.action();
							} else {
								store.dispatch(setMessageBar({ show: false, message: '' }));
							}
						}}
					>
						{isMobile ? <FontAwesomeIcon icon={faX} style={{ display: 'none', color: 'white', marginRight: '5px' }} /> : <CloseIconWhite className={cls.closeIcon} />}
						{showMessage?.rightspin && <Spinner type={'BallTriangle'} color={showMessage?.color === 'white' ? 'black' : 'white'} height={20} width={`20px`} style={{ padding: '0 10px 0 0px' }} />}
					</div>
				</div>
			)}
		</div>
	);
};

export default MessageBar;
