import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import classNames from 'classnames';
import cls from './Onboarding_basic_company.module.scss';
import { store } from '../../../reduxStore/store';
import StepModal from '../../SideMenuEntries/BlueprintsComps/ProcessesTab/StepModal';

const OnboardingNewStep = props => {
	const { onPreviousButtonAction, onContinueButtonPressed, title = '', subtitle = '' } = props;

	return (
		<StepModal visible={true} shadowedContainerStyle={{ width: '100%', height: 'auto', minHeight: '100%', padding: '50px 100px 0 100px' }} onClose={onPreviousButtonAction} onSave={onContinueButtonPressed}>
			<div className={cls.headerContainer_III}>
				<h1 className={cls.header_III}>{title}</h1>
			</div>
			<div className={classNames(cls.subheadingContainer_III, cls.justifiedLeft_III)}>
				<p className={cls.subheading_III}>{subtitle}</p>
			</div>
		</StepModal>
	);
};

const mapStateToProps = store => {
	const { companyData, selectedProcess } = store.flowReducer;
	const { isOnboarding } = store.modalsReducer;
	return { companyData, isOnboarding, selectedProcess };
};

export default connect(mapStateToProps)(OnboardingNewStep);
