import classNames from 'classnames';
import { useCallback, useState, memo } from 'react';
import cls from './ToggleSwitch.module.scss';

const ToggleSwitch = memo(props => {
	const { value, toggleSelected, name } = props;
	const [selected, setSelected] = useState(value);
	const toggle = useCallback(() => {
		setSelected(c => !c);
		if (/* !selected != value  &&*/ props?.toggleSelected) {
			toggleSelected(!selected);
		}
	}, [selected]);
	return (
		<div key={name} className={cls.toggleContainer} onClick={toggle}>
			<div className={classNames(cls.dialogButton, { [cls.switched]: selected })}>{selected ? 'YES' : 'NO'}</div>
		</div>
	);
});
export default ToggleSwitch;
