export const modalsDefault = {
	isOnboarding: false,
	onBoardingModalsVisibility: {
		firstModalVisible: false,
		secondModalVisible: false,
		thirdModalVisible: false,
		fourthModalVisible: false,
		fifthhModalVisible: false,
		sixthModalVisible: false,
		seventhModalVisible: false,
		eightModalVisible: false,
		ninthModalVisible: false,
		tenthModalVisible: false,
		eleventhModalVisible: false,
		twelvthModalVisible: false,
		thirteenthModalVisible: false,
		lastModalVisible: false,
		positionModalVisible: false,
	},
	onBoardingVisible: false,

	errorsModal: {
		isOpen: false,
		options: {},
	},
	infoModal: {
		isOpen: false,
		options: {},
	},
	previewChartModal: {
		isOpen: false,
		options: {},
	},
	tutorialModal: {
		isOpen: false,
		options: {},
	},
	pagedModal: {
		isOpen: false,
		options: {},
	},
};
