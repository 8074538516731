import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import ModulesOptionsModal from '../../SideMenuEntries/ModulesComp/ModulesOptionsModal';
import classNames from 'classnames';
import cls from './Onboarding_basic_company.module.scss';
import { store } from '../../../reduxStore/store';
import { isMobile } from 'react-device-detect';

const OnboardingNewModule = props => {
	const { onPreviousButtonAction, onContinueButtonPressed, selectedProcess, title = '', subtitle = '' } = props;

	return (
		<div className={cls.new_process_container}>
			<ModulesOptionsModal
				onClose={onPreviousButtonAction}
				isOpen={true}
				onSave={onContinueButtonPressed}
				modalStyle={{ maxWidth: '500px' }}
				selectedProcessId={selectedProcess?.id}
				shadowedContainerStyle={{ width: isMobile ? '100%' : '100%', height: 'auto', minHeight: '100%', padding: '50px 100px 0 100px' }}
			>
				<div className={cls.headerContainer_III}>
					<h1 className={cls.header_III}>{title}</h1>
				</div>
				<div className={classNames(cls.subheadingContainer_III, cls.justifiedLeft_III)}>
					<p className={cls.subheading_III}>{subtitle}</p>
				</div>
			</ModulesOptionsModal>
		</div>
	);
};

const mapStateToProps = store => {
	const { companyData, selectedProcess } = store.flowReducer;
	const { isOnboarding } = store.modalsReducer;
	return { companyData, isOnboarding, selectedProcess };
};

export default connect(mapStateToProps)(OnboardingNewModule);
