let focusWatched = false;
const focusAttr = 'data-dev-focus';
export const defaultFocusSel = 'a[href], button, input:not([type="hidden"]), [tabindex="0"], select, textarea';

// https://bugs.webkit.org/show_bug.cgi?id=178583 fixed with Safari 15.4?
let supportsPreventScrollOption = false;

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
	try {
		const focusElem = document.createElement('div');
		focusElem.addEventListener(
			'focus',
			function (event) {
				event.preventDefault();
				event.stopPropagation();
			},
			true
		);
		focusElem.focus(
			Object.defineProperty({}, 'preventScroll', {
				get: function () {
					supportsPreventScrollOption = true;
				},
			})
		);
	} catch (e) {
		// continue
	}
}
export const devFocus = (elem?: HTMLElement | null, queryChild?: string | boolean | null, ifScrollPreventable = false) => {
	if (!elem || (ifScrollPreventable && !supportsPreventScrollOption)) {
		return;
	}

	if (queryChild) {
		if (queryChild === true) {
			queryChild = defaultFocusSel;
		}

		elem = elem.querySelector(queryChild) as HTMLElement | null;
	}

	if (elem == null) {
		return;
	}

	requestAnimationFrame(() => {
		if (elem) {
			if (elem.tabIndex == -1 && elem.getAttribute('tabindex') == null) {
				elem.tabIndex = -1;
			}

			if (!isFocusVisible()) {
				elem.setAttribute(focusAttr, '');
				if (!focusWatched) {
					startWatching();
				}
			}

			elem.focus({ preventScroll: true });
		}
	});
};

export const isFocusVisible = (dom: HTMLElement = document.activeElement as HTMLElement) => {
	try {
		return dom.matches(':focus-visible');
	} catch (er) {
		// continue
	}

	return false;
};

const blurHandler = (e: Event) => {
	const target = e.target && 'getAttribute' in e.target && e.target as HTMLElement;

	if (target && target.getAttribute(focusAttr) != null) {
		requestAnimationFrame(() => {
			target.removeAttribute(focusAttr);
		});
	}
};

const startWatching = () => {
	if (!focusWatched) {
		focusWatched = true;
		document.addEventListener('blur', blurHandler, true);
	}
};
