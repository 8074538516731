import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { StoreType } from '../reduxStore/store';

export const perm = [
	'dashboard-view',
	'dashboard-update',
	'dashboard-tasks-read',
	'dashboard-tasks-update',
	'dashboard-tasks-delete',
	'pinned-process-view',
	'pinned-process-update',
	'tasks-view',
	'tasks-dashboard-create',
	'tasks-dashboard-view',
	'tasks-dashboard-update',
	'tasks-dashboard-delete',
	'tasks-history-view',
	'tasks-history-view',
	'process-create',
	'process-view',
	'process-update',
	'process-delete',
	'process-filters',
	'component-create',
	'component-view',
	'component-update',
	'component-delete',
	'component-filters',
	'company-view',
	'company-users-unasigned-view',
	'company-users-unasigned-update',
	'cochart-update',
	'cochart-view',
	'company-departments-add',
	'company-departments-edit',
	'company-departments-delete',
	'company-users-add',
	'company-users-view',
	'company-users-edit',
	'company-users-delete',
	'company-users-edit-own',
	'company-users-edit-own-department',
	'company-filters',
	'blueprints-view',
	'blueprints-process-create',
	'blueprints-process-view',
	'blueprints-process-update',
	'blueprints-process-delete',
	'blueprints-components-create',
	'blueprints-components-view',
	'blueprints-components-update',
	'blueprints-components-delete',
	'blueprints-statuses-create',
	'blueprints-statuses-view',
	'blueprints-statuses-update',
	'blueprints-statuses-delete',
	'search-view',
	'myprofile-view',
	'company-profile-view',
	'company-profile-update',
	'billing-view',
	'billing-update',
	'adminsmanagement-view',
	'adminsmanagement-update',
	'adminsmanagement-delete',
	'tracker-view',
];
export const po = {
	dashboard: {
		view: 'dashboard-view',
		update: 'dashboard-update',
		tasks: {
			read: 'dashboard-tasks-read',
			update: 'dashboard-tasks-update',
			delete: 'dashboard-tasks-delete',
		},
	},
	pinnedProcess: {
		view: 'pinned-process-view',
		update: 'pinned-process-update',
	},
	tasks: {
		view: 'tasks-view',
		dashboard: {
			create: 'tasks-dashboard-create',
			view: 'tasks-dashboard-view',
			update: 'tasks-dashboard-update',
			delete: 'tasks-dashboard-delete',
		},
		history: {
			view: 'tasks-history-view',
		},
	},
	processes: {
		create: 'process-create',
		view: 'process-view',
		update: 'process-update',
		delete: 'process-delete',
		filter: 'process-filters',
	},
	components: {
		create: 'component-create',
		view: 'component-view',
		update: 'component-update',
		delete: 'component-delete',
		filter: 'component-filters',
	},
	company: {
		view: 'company-view',

		orgChart: {
			update: 'cochart-update',
			view: 'cochart-view',
		},
		departments: {
			add: 'company-departments-add',
			edit: 'company-departments-edit',
			delete: 'company-departments-delete',
		},
		users: {
			unnasigned: {
				view: 'company-users-unasigned-view',
				update: 'company-users-unasigned-update',
			},
			add: 'company-users-add',
			view: 'company-users-view',
			edit: 'company-users-edit',
			delete: 'company-users-delete',
		},
		filter: 'company-filters',
		profile: {
			view: 'company-profile-view',
			update: 'company-profile-update',
		},
	},
	blueprints: {
		view: 'blueprints-view',
		process: {
			create: 'blueprints-process-create',
			view: 'blueprints-process-view',
			update: 'blueprints-process-update',
			delete: 'blueprints-process-delete',
		},
		components: {
			create: 'blueprints-components-create',
			view: 'blueprints-components-view',
			update: 'blueprints-components-update',
			delete: 'blueprints-components-delete',
		},
		statuses: {
			create: 'blueprints-statuses-create',
			view: 'blueprints-statuses-view',
			update: 'blueprints-statuses-update',
			delete: 'blueprints-statuses-delete',
		},
	},
	search: {
		view: 'search-view',
	},
	myprofile: {
		view: 'myprofile-view',
	},
	billing: {
		view: 'billing-view',
		update: 'billing-update',
	},
	adminsManagement: {
		view: 'adminsmanagement-view',
		update: 'adminsmanagement-update',
		delete: 'adminsmanagement-delete',
	},
	tracker: { view: 'tracker-view' },
};
export const userTypes = ['superadmin', 'admin', 'manager', 'nonmanager'];

export function usePermissions() {
	const user = useSelector((store: StoreType) => store.authReducer?.authenticatedUser ?? store?.sessionReducer?.user);
	const [permissions, setPermissions] = useState({ isAdmin: user.is_admin === true });

	useEffect(() => {
		(() => {
			const permiss = perm?.map(i => {
				switch (i) {
					//blueprints
					case po.blueprints.view:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.process.create:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.process.view:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.process.update:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.process.delete:
						return { [i]: ['manager', 'nonmanager'].indexOf(user.user_type) === -1 };

					case po.blueprints.components.create:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.components.view:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.components.update:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.components.delete:
						return { [i]: ['manager', 'nonmanager'].indexOf(user.user_type) === -1 };

					case po.blueprints.statuses.create:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.statuses.view:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.statuses.update:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.blueprints.statuses.delete:
						return { [i]: ['manager', 'nonmanager'].indexOf(user.user_type) === -1 };
					// pinnedProcess
					case po.pinnedProcess.update:
						return { [i]: user.user_type !== 'nonmanager' };
					//tracker
					case po.tracker.view:
						return { [i]: user.user_type === 'superadmin' };
					//components
					case po.components.delete:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.components.filter:
						return { [i]: true };
					//company
					case po.company.profile.view:
						return { [i]: true };
					case po.company.profile.update:
						return { [i]: user.user_type === 'admin' };
					case po.company.users.edit:
						return { [i]: user.user_type === 'admin' };
					case po.company.users.delete:
						return { [i]: user.user_type === 'admin' };
					case po.company.users.add:
						return { [i]: user.user_type === 'admin' };
					case po.company.orgChart.update:
						return { [i]: user.user_type !== 'nonmanager' };
					case po.company.departments.delete:
						return { [i]: user.user_type === 'admin' };
					case po.company.departments.edit:
						return { [i]: user.user_type === 'admin' };
					case po.company.filter:
						return { [i]: true };
					//billing
					case po.billing.view:
						return { [i]: user.user_type == 'admin' };
					case po.billing.update:
						return { [i]: user.user_type == 'admin' };
					default:
						return { [i]: true };
				}
			});
			setPermissions(R.reduce((acc, value) => R.merge(value, acc), { isAdmin: user.is_admin === true }, permiss));
		})();
	}, [user]);

	// eslint-disable-next-line no-extra-parens
	const availableFeat: (p: string) => boolean = useCallback(feature => (permissions as any)[feature], [permissions]);

	return [permissions, availableFeat];
}
