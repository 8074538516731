import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCompanyOrgChartPositions, getPositionById } from '../../../services/company.service';
import { first } from 'rxjs/operators';
import { Button, PagedModal, Spinner } from '../../common';
import { TheListItemsRenderContext } from '../../../context/appRender.context';
import './chartStyles.scss';
import cls from './CompanyOrganisationalChart.module.scss';
import { Tree, TreeNode } from 'react-organizational-chart';
import UserCard from '../../common/UserCard/UserCard';
const R = require('ramda');

const CompanyOrganizationalChart = props => {
	const navigate = useNavigate();
	const location = useLocation();
	const [selected, setSelected] = useState('');
	const { onPositionPressed = () => {}, children, showAsChart = true } = props;

	const { companyOrgChart, companyData, companyPositionsArray } = useSelector(store => store.flowReducer);
	const gManContainerRef = useRef();
	useEffect(() => {
		const positionId = new URLSearchParams(location?.search).get('positionId');
		if (positionId) {
			getPositionById(positionId).subscribe({
				complete: () => {
					navigate({ pathname: '/role', state: {} });
				},
			});
		}
		if (gManContainerRef?.current?.tagName?.toLowerCase() === 'div') {
			setTimeout(
				() =>
					gManContainerRef?.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'center',
					}),
				50
			);
		}
	}, [location]);
	useLayoutEffect(() => {
		getCompanyOrgChartPositions().pipe(first()).subscribe({});
	}, []);

	const Tree_Component = props => {
		const { component } = props;

		return (
			<TreeNode
				label={
					<div className={cls.topRows}>
						<div
							ref={gManContainerRef}
							onClick={() => {
								setSelected(component?.id);
								onPositionPressed(component?.id);
							}}
							className={cls.cardContainerBase}
						>
							<p>
								{component.users.length > 0 && <div>{`${component.name} (${component.users.length})`}</div>}
								{component.users.length == 0 && <div>{component.name}</div>}
							</p>
						</div>
					</div>
				}
			>
				{component.children?.length > 0 && component.children.map((ch, index) => <Tree_Component key={index} component={ch} />)}
				{component.department && component.children?.length == 0 && <TreeNode label={<div className={cls.tree}>{component.department?.name}</div>} />}
			</TreeNode>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '100px' }}>
			{children}
			{showAsChart ? (
				<div id={'chart'} style={{ margin: '20px, 10px 0px,10px', padding: '50px 50px 50px 50px', display: 'inline-block' }}>
					<div style={{ overflow: 'visible', justifyContent: 'space-between', display: '' }}>
						{companyOrgChart.children ? (
							<Tree
								lineWidth={'2px'}
								lineColor={'green'}
								lineBorderRadius={'10px'}
								label={
									<div className={cls.topRows}>
										<div ref={gManContainerRef} className={cls.topContainerBase}>
											<p>{companyData.name}</p>
										</div>
									</div>
								}
							>
								{!R.isEmpty(companyOrgChart) && <Tree_Component component={companyOrgChart} />}
							</Tree>
						) : (
							<Spinner size={60} type={'ThreeDots'} />
						)}
					</div>
				</div>
			) : (
				<div className={cls.userPanelHost}>
					{companyPositionsArray.map((position, index) => {
						return (
							<div key={index} style={{ padding: '10px' }}>
								<UserCard
									noPhoto={true}
									firstName={position?.label}
									position={position?.department}
									cardClicked={() => {
										setSelected(position?.value);
										onPositionPressed(position?.value);
									}}
								/>
							</div>
						);
					})}
				</div>
			)}
			<TheListItemsRenderContext>
				<PagedModal />
			</TheListItemsRenderContext>
		</div>
	);
};

export default CompanyOrganizationalChart;
