import React, { Fragment } from 'react';
import classNames from 'classnames';
import { useCombobox, useMultipleSelection } from 'downshift';
import { ComponentProps, forwardRef, useEffect, useState } from 'react';
import { Add, Checked, Chevron, CloseXCircle, DropDownArrow, backIcon } from '../../../../assets/images/icons';
import cls from './MultiSelectWithCheckboxesAndNestedItems.module.scss';
import { Card } from '../../../SideMenuEntries/BlueprintsComps/ProcessesTab/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import ModuleLists from '../../../SideMenuEntries/ModulesComp/ModuleLists';
import Button from '../Button/Button';
const R = require('ramda');
type SimpleItem = { value: string; label: string; icon?: string; grouping?: string };
type ComplexItem = SimpleItem[];
export type MultiSelectWithCheckboxesAndNestedItemsProps = {
	items: SimpleItem[];
	groupname?: string;
	groupedBy?: keyof SimpleItem;
	title?: string;
	placeholder?: string;
	id?: string;
	hasMandatoryIndicator?: boolean;
	searchable?: boolean;
	initialSelectedValues: SimpleItem[];
	errorMessage?: string;
	onChange?: (selectedItems?: SimpleItem[]) => void;
	notags?: boolean;
	tagEmplacement: 'top' | 'bottom' | 'inlined';
	inputValue: string;
	triggerType?: 'chevron' | 'downArrow' | 'eyeglass';
	inputClassName?: string;
	molclassname?: string;
	moliclassname?: string;
	inputStyle: Record<string, unknown>;
	menuOverlayStyle: Record<string, unknown>;
	menuOverlayListItemStyle: Record<string, unknown>;
} & ComponentProps<'div'>;
export type MultiSelectWithTilesAndNestedItemsProps = {
	items: SimpleItem[];
	selectedItems: SimpleItem[];
	onChange?: (selectedItems?: any) => void;
	onGroupChange?: (selectedItems?: any) => void;
	groupname?: string;
	groupedBy?: keyof SimpleItem;
	errorMessage?: string;
	moliclassname?: string;
	columns?: string[];
	columnsG?: string[];
	initialSelected?: string;
	showAll?: boolean;
} & ComponentProps<'div'>;

const groupBy = (propName: string, array: SimpleItem[]): ComplexItem => R.valuesIn(R.groupBy(R.prop(propName))(R.sortWith([R.ascend(R.prop(propName))])(array)));

const MultiSelectWithCheckboxesAndNestedItems = (props: MultiSelectWithCheckboxesAndNestedItemsProps) => {
	const {
		title,
		hasMandatoryIndicator,
		id = title?.replace(/ /g, '-'),
		triggerType,
		items,
		groupname = 'department_name',
		groupedBy,
		initialSelectedValues,
		onChange,
		errorMessage,
		placeholder,
		children,
		className,
		inputClassName,
		molclassname,
		moliclassname,
		inputStyle,
		menuOverlayStyle,
		menuOverlayListItemStyle,
		notags,
		...rest
	} = props;
	const [searchFilteredItems, setSearchFilteredItems] = useState(items);
	const [grouped] = useState(groupBy(groupedBy ?? '', items));
	const {
		getSelectedItemProps,
		getDropdownProps,
		addSelectedItem,
		removeSelectedItem,
		selectedItems,
	} = useMultipleSelection<SimpleItem>({
		initialSelectedItems: items?.filter(i => initialSelectedValues?.filter(e => e?.value == i?.value)?.length > 0),
	});
	useEffect(() => {
		onChange?.(selectedItems);
	}, [selectedItems]);

	const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu, selectItem } = useCombobox<SimpleItem | null>({
		items: searchFilteredItems,
		id,
		stateReducer: (state, actionAndChanges) => {
			const { type, changes } = actionAndChanges;
			switch (type) {
				case useCombobox.stateChangeTypes.ToggleButtonClick:
				case useCombobox.stateChangeTypes.InputBlur:
					return {
						...changes,
						inputValue: '',
					};
				case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick: {
					if (state?.highlightedIndex > -1) {
						if (selectedItems.includes(items[state?.highlightedIndex])) {
							removeSelectedItem(items[state?.highlightedIndex]);
						} else {
							addSelectedItem(items[state?.highlightedIndex]);
						}
					}
					return {
						...state,
						isOpen: true, //keep menu open after selection
						highlightedIndex: state.highlightedIndex,
					};
				}
			}
			return changes;
		},
		onStateChange: ({ type, selectedItem }) => {
			switch (type) {
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					//console.log('useCombobox.stateChangeTypes.ItemClick selectedItem: ', selectedItem);
					if (selectedItem) {
						if (selectedItems.includes(selectedItem)) {
							removeSelectedItem(selectedItem);
						} else {
							addSelectedItem(selectedItem);
						}
						selectItem(null);
					}
					break;
				default:
					break;
			}
		},
		onInputValueChange: ({ inputValue }) => {
			setSearchFilteredItems(inputValue ? items?.filter(item => item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())) : items);
		},
	});
	return (
		<div className={classNames(cls.host, className)} {...rest}>
			<Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
				{title}
			</Label>
			{!notags && (
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{selectedItems.map((selectedItem, index) => (
						<Tag
							hasCloseButton
							key={`selected-item${index}`}
							{...getSelectedItemProps({ selectedItem, index })}
							onClick={() => {
								removeSelectedItem(selectedItem);
							}}
						>
							{selectedItem?.icon && <img alt="item-icon" className={cls.itemLeftIcon} src={selectedItem?.icon} />}
							{selectedItem.label}
						</Tag>
					))}
				</div>
			)}
			<InputContainer {...getComboboxProps()} isActive={isOpen} hasError={errorMessage != null}>
				<Input
					{...getInputProps({
						placeholder,
						'aria-invalid': errorMessage != null || undefined,
						onFocus: () => {
							if (!isOpen) {
								openMenu();
							}
						},
					})}
					className={inputClassName}
					style={inputStyle}
				/>
				<ToggleMenuInputButton {...getToggleButtonProps({ ...getDropdownProps({ preventKeyAction: isOpen }) })} isOpen={isOpen} triggerType={triggerType}>
					{children}
				</ToggleMenuInputButton>
			</InputContainer>
			<MenuOverlayList {...getMenuProps()} className={molclassname} sstyle={menuOverlayStyle} style={{ display: 'none' }}>
				{isOpen &&
					grouped.map((its: any, idx: number) => {
						return (
							<CollapsibleHeader
								isVisible={its.filter((item: SimpleItem) => searchFilteredItems.includes(item))?.length > 0}
								searchFilteredItems={searchFilteredItems}
								label={its?.[0]?.[`${groupname}`] ?? 'undefined'}
								items={its}
								selectSource={items}
								moliclassname={moliclassname}
								style={menuOverlayListItemStyle}
								key={`${its?.length}${idx}`}
								highlightedIndex={highlightedIndex}
								selectedItems={selectedItems}
								getItemProps={getItemProps}
							/>
						);
					})}
			</MenuOverlayList>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
};
export type LabelProps = { hasMandatoryIndicator?: boolean } & ComponentProps<'label'>;
export const Label = (props: LabelProps) => {
	const { hasMandatoryIndicator, children, className, style, ...rest } = props;
	return (
		<label className={classNames(cls.customLabel, className)} style={style} {...rest}>
			{children}
			{hasMandatoryIndicator && <span style={{ color: 'var(--app-color-error)' }}>*</span>}
		</label>
	);
};
export type TagProps = { hasCloseButton?: boolean } & ComponentProps<'div'>;
export const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
	const { children, style = {}, className = '', hasCloseButton, ...rest } = props;
	return (
		<div ref={ref} tabIndex={hasCloseButton ? 0 : undefined} className={classNames(cls.hostCustomTagDefault, className)} style={style} {...rest}>
			{children}
			{hasCloseButton && <FontAwesomeIcon icon={faClose} className={cls.customTagCloseIcon} />}
		</div>
	);
});
export const ErrorMessage = (props: ComponentProps<'p'>) => {
	return <p className={cls.customErrorMessage} {...props} />;
};
export type ToggleMenuInputButtonProps = { isOpen: boolean; triggerType?: 'chevron' | 'downArrow' } & ComponentProps<'button'>;
export const ToggleMenuInputButton = forwardRef<HTMLButtonElement, ToggleMenuInputButtonProps>((props, ref) => {
	const { children, isOpen, className, triggerType, ...rest } = props;
	return (
		<>
			{triggerType ? (
				<button ref={ref} type="button" aria-label="toggle-menu" className={classNames(cls.customToggleMenuInputButton, className)} app-variant={isOpen ? 'open' : 'closed'} app-icon={triggerType ? triggerType : ''} {...rest}>
					{children}
					{triggerType === 'chevron' && <Chevron className={cls.customToggleMenuInputButtonIcon} />}
					{triggerType === 'downArrow' && <DropDownArrow className={cls.customToggleMenuInputButtonIcon} />}
				</button>
			) :
				children
			}
		</>
	);
});
export const InputContainer = forwardRef<HTMLDivElement, { isActive: boolean; hasError: boolean } & ComponentProps<'div'>>((props, ref) => {
	const { isActive, hasError, className, style, ...rest } = props;
	return <div ref={ref} {...rest} className={classNames(cls.customInputContainer, className)} style={{ ...style }} app-state={isActive ? 'active' : hasError ? 'error' : ''} />;
});
export const Input = forwardRef<HTMLInputElement, ComponentProps<'input'>>((props, ref) => {
	const { className, ...rest } = props;
	return <input ref={ref} className={classNames(cls.customInput, className)} {...rest} />;
});
export const MenuOverlayList = forwardRef<HTMLUListElement, ComponentProps<'ul'>>((props, ref) => {
	const { className, style, ...rest } = props;
	return <ul ref={ref} {...rest} className={classNames(cls.customMenuOverlayList, className)} style={{ ...style }} />;
});
export type MenuOverlayListItemProps = {
	isVisible: boolean;
	isHighlighted?: boolean;
	isSelected?: boolean;
	itemType?: 'checkboxes' | 'normal';
} & ComponentProps<'li'>;
export const MenuOverlayListItem = forwardRef<HTMLLIElement, MenuOverlayListItemProps>((props, ref) => {
	const { itemType, children, className, isSelected, isHighlighted, isVisible, style, ...rest } = props;
	if (!isVisible) return null;
	return (
		<li ref={ref} className={classNames(cls.customMenuOverlayListItem, className)} app-variant={isSelected ? 'selected' : isHighlighted ? 'highlighted' : ''} app-item-type={itemType ? itemType : ''} app-item-display={itemType ? itemType : ''} style={style} {...rest}>
			{itemType === 'checkboxes' && (
				<span>
					<div className={classNames(cls.customMenuOverlayListItemCheckbox)}>{isSelected && <Checked className={cls.customMenuOverlayListItemCheckboxCheckedMark} />}</div>
				</span>
			)}
			<span className={cls.customMenuOverlayListItemChild}>{children}</span>
			{itemType === 'normal' && <span>{isSelected ? <Checked className={cls.customMenuOverlayListItemNormalCheckedMark} /> : <Add className={cls.customMenuOverlayListItemNormalCheckedMark} />}</span>}
		</li>
	);
});

const CollapsibleHeader = (props: SimpleItem & any) => {
	const { label, items, selectSource, isVisible, searchFilteredItems, moliclassname, menuOverlayListItemStyle, highlightedIndex, selectedItems, getItemProps, itemType = 'checkboxes' } = props;
	const [open, setOpen] = useState(false);
	return (
		<div className={cls.collapsibleHeaderContainer} app-variant={open ? 'open' : 'closed'} app-display={isVisible ? 'flex' : 'none'}>
			<button
				className={cls.collapsibleHeaderButton}
				onClick={() => {
					setOpen(!open);
				}}
			>
				<span className={cls.collapsibleHeaderText}>{label}</span>
				<Chevron className={cls.collapsibleHeaderChevron} />
			</button>
			{open &&
				items?.map((item: any) => (
					<MenuOverlayListItem
						itemType={itemType}
						isVisible={items.filter((item: SimpleItem) => searchFilteredItems.includes(item))?.length > 0}
						className={moliclassname}
						style={menuOverlayListItemStyle}
						key={`${item?.value}${selectSource?.indexOf(item)}`}
						isHighlighted={highlightedIndex === selectSource?.indexOf(item)}
						isSelected={selectedItems.filter((it: any) => it.value === item.value).length > 0}
						{...getItemProps({ item: item, index: selectSource?.indexOf(item) })}
					>
						{item.label}
					</MenuOverlayListItem>
				))}
		</div>
	);
};

export default MultiSelectWithCheckboxesAndNestedItems;

export const MultiSelectWithCheckboxesAndNestedItems_v2 = (props: MultiSelectWithCheckboxesAndNestedItemsProps) => {
	const {
		title,
		hasMandatoryIndicator,
		id = title?.replace(/ /g, '-'),
		triggerType,
		items,
		groupname = 'department_name',
		groupedBy,
		initialSelectedValues,
		onChange,
		errorMessage,
		placeholder,
		children,
		className,
		inputClassName,
		molclassname,
		moliclassname,
		inputStyle,
		menuOverlayStyle,
		menuOverlayListItemStyle,
		notags,
		tagEmplacement,
		searchable = true,
		...rest
	} = props;
	const searchFilteredItems = items;
	const grouped = groupBy(groupedBy ?? '', items);
	const {
		getSelectedItemProps,
		getDropdownProps,
		addSelectedItem,
		removeSelectedItem,
		selectedItems,
	} = useMultipleSelection<SimpleItem>({
		initialSelectedItems: items?.filter(i => initialSelectedValues?.filter(e => e?.value == i?.value)?.length > 0),
	});
	useEffect(() => {
		onChange?.(selectedItems);
	}, [selectedItems]);

	const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu, selectItem } = useCombobox<SimpleItem | null>({
		items: searchFilteredItems,
		id,
		stateReducer: (state, actionAndChanges) => {
			const { type, changes } = actionAndChanges;
			switch (type) {
				//clear the input when clicking outside or toggle button
				case useCombobox.stateChangeTypes.ToggleButtonClick:
				case useCombobox.stateChangeTypes.InputBlur:
					return {
						...changes,
						inputValue: '',
					};
				case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick: {
					if (state?.highlightedIndex > -1) {
						if (selectedItems.includes(items[state?.highlightedIndex])) {
							removeSelectedItem(items[state?.highlightedIndex]);
						} else {
							addSelectedItem(items[state?.highlightedIndex]);
						}
					}
					return {
						...state,
						isOpen: true, //keep menu open after selection
						highlightedIndex: state.highlightedIndex,
					};
				}
			}
			return changes;
		},
		onStateChange: ({ type, selectedItem }) => {
			switch (type) {
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					if (selectedItem) {
						if (selectedItems.includes(selectedItem)) {
							removeSelectedItem(selectedItem);
						} else {
							addSelectedItem(selectedItem);
						}
						selectItem(null);
					}
					break;
				default:
					break;
			}
		},
		onInputValueChange: () => {

		},
	});
	return (
		<div className={classNames(cls.host, className)} {...rest}>
			<Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
				{title}
			</Label>
			<div className={cls.inputAndTagsWrapper} app-tag-emplacement={tagEmplacement}>
				{!notags && (
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{selectedItems.map((selectedItem, index) => (
							<Tag
								hasCloseButton
								key={`selected-item${index}`}
								{...getSelectedItemProps({ selectedItem, index })}
								onClick={() => {
									removeSelectedItem(selectedItem);
								}}
							>
								{selectedItem?.icon && <img alt="item-icon" className={cls.itemLeftIcon} src={selectedItem?.icon} />}
								{selectedItem.label}
							</Tag>
						))}
					</div>
				)}
				<InputContainer {...getComboboxProps()}
					app-renedered-elements={tagEmplacement == 'inlined' ? 'toggle-only' : 'input-toggle'}
					isActive={isOpen} hasError={errorMessage != null}>
					<Input
						{...getInputProps({
							placeholder,
							'aria-invalid': errorMessage != null || undefined,
							onFocus: () => {
								if (!isOpen) {
									openMenu();
								}
							},
						})}
						disabled={!searchable}
						className={inputClassName}
						style={inputStyle}
					/>
					<ToggleMenuInputButton {...getToggleButtonProps({ ...getDropdownProps({ preventKeyAction: isOpen }) })} isOpen={isOpen} triggerType={triggerType}>
						{children}
					</ToggleMenuInputButton>
				</InputContainer>
			</div>
			<MenuOverlayList {...getMenuProps()} className={molclassname} style={menuOverlayStyle}>
				{isOpen &&
					grouped.map((its: any, idx: number) => {
						return (
							<CollapsibleHeader
								isVisible={its.filter((item: SimpleItem) => searchFilteredItems.includes(item))?.length > 0}
								searchFilteredItems={searchFilteredItems}
								label={its?.[0]?.[`${groupname}`] ?? 'undefined'}
								items={R.uniq(its)}
								selectSource={items}
								moliclassname={moliclassname}
								style={menuOverlayListItemStyle}
								key={`${its?.length}${idx}`}
								highlightedIndex={highlightedIndex}
								selectedItems={selectedItems}
								getItemProps={getItemProps}
							/>
						);
					})}
			</MenuOverlayList>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
};

export const SingleSelectWithCheckboxesAndNestedItems = (props: MultiSelectWithCheckboxesAndNestedItemsProps) => {
	const {
		title,
		id = title?.replace(/ /g, '-'),
		triggerType,
		items,
		groupname = 'department_name',
		groupedBy,
		initialSelectedValues,
		onChange,
		errorMessage,
		placeholder,
		children,
		className,
		inputClassName,
		molclassname,
		moliclassname,
		inputStyle,
		menuOverlayStyle,
		menuOverlayListItemStyle,
		...rest
	} = props;
	const searchFilteredItems = items;
	const grouped = groupBy(groupedBy ?? '', items);
	const {
		getDropdownProps,
		addSelectedItem,
		removeSelectedItem,
		selectedItems,
		setSelectedItems

	} = useMultipleSelection<SimpleItem>({
		initialSelectedItems: items?.filter(i => initialSelectedValues?.filter(e => e?.value == i?.value)?.length > 0),
	});
	useEffect(() => {
		onChange?.(selectedItems);
	}, [selectedItems]);

	const { isOpen, getToggleButtonProps, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu, selectItem } = useCombobox<SimpleItem | null>({
		items: searchFilteredItems,
		id,
		stateReducer: (state, actionAndChanges) => {
			const { type, changes } = actionAndChanges;
			switch (type) {
				//clear the input when clicking outside or toggle button
				case useCombobox.stateChangeTypes.ToggleButtonClick:
				case useCombobox.stateChangeTypes.InputBlur:
					return {
						...changes,
						inputValue: '',
					};
				case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick: {

					setSelectedItems([items[state?.highlightedIndex]]);
					selectItem(null);
					return {
						...state,
						isOpen: false, //menu open after selection???
						highlightedIndex: state.highlightedIndex,
					};
				}
			}
			return changes;
		},
		onStateChange: ({ type, selectedItem }) => {
			switch (type) {
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					if (selectedItem) {
						if (selectedItems.includes(selectedItem)) {
							removeSelectedItem(selectedItem);
						} else {
							addSelectedItem(selectedItem);
						}
					}
					break;
				default:
					break;
			}
		},
		onInputValueChange: () => {

		},
	});
	return (
		<div className={classNames(cls.host, className)} {...rest}>
			<InputContainer {...getComboboxProps()} isActive={isOpen} hasError={errorMessage != null} onClick={() => {
				if (!isOpen) {
					openMenu();
				}
			}}>

				<Input spellCheck={false}
					{...getInputProps({
						placeholder,
						'aria-invalid': errorMessage != null || undefined,
						onFocus: () => {
							if (!isOpen) {
								openMenu();
							}
						},
					})}
					className={inputClassName}
					style={inputStyle}
					value={inputStyle?.blank ? '' : selectedItems[0]?.label ?? ''}
				/>
				<ToggleMenuInputButton {...getToggleButtonProps({ ...getDropdownProps({ preventKeyAction: isOpen }) })} isOpen={isOpen} triggerType={triggerType}>
					{children}
				</ToggleMenuInputButton>
			</InputContainer>

			<MenuOverlayList {...getMenuProps()} className={molclassname} style={menuOverlayStyle}>
				{isOpen &&
					grouped.map((its: any, idx: number) => {
						return (
							<CollapsibleHeader
								isVisible={its.filter((item: SimpleItem) => searchFilteredItems.includes(item))?.length > 0}
								searchFilteredItems={searchFilteredItems}
								label={its?.[0]?.[`${groupname}`] ?? 'undefined'}
								items={its}
								selectSource={items}
								moliclassname={moliclassname}
								style={menuOverlayListItemStyle}
								key={`${its?.length}${idx}`}
								highlightedIndex={highlightedIndex}
								selectedItems={selectedItems}
								getItemProps={getItemProps}
								itemType={''}
							/>
						);
					})}
			</MenuOverlayList>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
};

export const SingleSelectWithTilesAndNestedItems = (props: MultiSelectWithTilesAndNestedItemsProps) => {
	const {
		items,
		selectedItems,
		groupname = 'department_name',
		groupedBy,
		errorMessage,
		className,
		moliclassname,
		onChange,
		onGroupChange,
		columns,
		columnsG,
		initialSelected,
		showAll = false,
		...rest
	} = props;
	const grouped = groupBy(groupedBy ?? '', items);
	const [selectedGroup, setSelectedGroup] = useState(initialSelected ? undefined : '');
	
	useEffect(() => {
		onGroupChange?.(selectedGroup);	
	}, [selectedGroup]);

	return (
		<Fragment>
			<div style={{ width: 'inherit' }} >
			{initialSelected && selectedItems.length == 0 && selectedGroup === undefined &&
					<ModuleLists
						rowStyleSelected={{ border: '2px solid var(--app-color-gray-light)' }}
						initialSelected={0}
						modern={true}
						isReport={false}
						app-variant-is-mobile={'true'}
						columns={columns}

						list={items.filter(it=>it.value === initialSelected.split(',')[1])}
						onListItemClick={(selection: any) => {
							onChange?.(selection);
						}}
					/>
				}

				{(showAll == true || selectedGroup !== '') &&
					<ModuleLists
						rowStyleSelected={{ border: '2px solid var(--app-color-gray-light)' }}
						modern={true}
						isReport={false}
						app-variant-is-mobile={'true'}
						columns={columns}
						list={selectedItems}
						onListItemClick={(selection: any) => {
							onChange?.(selection);
						}}
					/>
				}

				{selectedGroup === '' && showAll == false &&
					<ModuleLists
						rowStyleSelected={{ border: '1px solid red' }}
						modern={true}
						isReport={false}
						withColumns={false}
						app-variant-is-mobile={'true'}
						columns={columnsG}
						list={grouped.map((grp: any) => {
							return grp[0];
						})}
						onListItemClick={(selection: any) => {
							setSelectedGroup(selection.grouping);
						}}
					/>
				}
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{selectedGroup !== '' && showAll == false &&
						<Button
							light
							title={`Back`}
							className={moliclassname}
							clicked={() => {
								setSelectedGroup('');
							}}
						/>
					}
				</div>
			</div>
		</Fragment>
	);
};