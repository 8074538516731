import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import ProcessOptionsModal from '../../SideMenuEntries/BlueprintsComps/ProcessesTab/ProcessOptionsModal';
import classNames from 'classnames';
import cls from './Onboarding_basic_company.module.scss';
import { store } from '../../../reduxStore/store';
import { isMobile } from 'react-device-detect';
import ComponentsForm from '../../SideMenuEntries/BlueprintsComps/ComponentsTab/ComponentsForm';
import { ModalR } from '../../common';

const OnboardingNewBlueprint = props => {
	const { onPreviousButtonAction, onContinueButtonPressed, blueprints } = props;

	return (
		<ModalR
			modalWrapperStyle={props?.modalWrapperStyle}
			shadowedContainerStyle={{ width: '100%', height: '100%' }}
			scrollable={true}
			backdropStyle={{ height: props.backdropStyle ? props.backdropStyle : '1000px' }}
			isOpen={true}
			modalClosed={() => props.onCancel()}
			blured={props.blured}
		>
			<div className={cls.firstDiv_III}>
				<div className={cls.headerContainer_III}>
					<h1 className={cls.header_III}>{props.title}</h1>
				</div>
				<div className={classNames(cls.subheadingContainer_III, cls.justifiedLeft_III)}>
					<p className={cls.subheading_III}>{props.subtitle}</p>
				</div>
				<ComponentsForm showEdit={false} visible={true} onClose={onPreviousButtonAction} onUpdate={onContinueButtonPressed} />
				{props.children}
			</div>
		</ModalR>
	);
};

const mapStateToProps = store => {
	const { companyData, blueprints } = store.flowReducer;
	const { isOnboarding } = store.modalsReducer;
	return { companyData, isOnboarding, blueprints };
};

export default connect(mapStateToProps)(OnboardingNewBlueprint);
