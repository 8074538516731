import React from 'react';
import Select from 'react-select';
import { defaultSelectStyles, DropdownIndicator, sortColleaguesValues, ValueContainer } from '../../../shared/utility';

function SortByDropdown(props) {
	const sortTypesStyle = {
		...defaultSelectStyles,
		control: provided => ({
			...provided,
			position: 'relative',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			alignSelf: 'center',
			height: '50px',
			minWidth: '250px',
			width: '100%',
			border: '#D2D2D2 solid 1px',
			boxShadow: 'none',
			'&:hover': { border: '#D2D2D2 solid 1px' },
			borderRadius: '8px',
			// fontSize: '16px',
			fontSize: '1.4em',
			fontFamily: 'var(--app-text-main-font)',
			color: '#4A4A4A',
			textAlign: 'left',
			padding: '3px',
			margin: '0px 5px 0 0',
		}),
		input: provided => ({
			...provided,
			margin: '0px 0 0px 0px ',
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '1.2em',
			lineHeight: '20px',
			color: 'var(--app-color-gray-light)',
			border: 'none',
			outline: 'none',
			alignSelf: 'center',
			padding: '0',
			paddingLeft: '25px',
			width: '100%',
			textAlign: 'left',
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return {
				...provided,
				opacity,
				transition,
				marginLeft: '0px',
				fontSize: '0.75em',
				lineHeight: '20px',
				color: '#4A4A4A',
				paddingLeft: '25px',
			};
		},
		placeholder: provided => ({
			...provided,
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '1rem',
			lineHeight: '1.3rem',
			color: 'var(--app-color-gray-light)',
			paddingLeft: '25px',
		}),
		indicatorsContainer: styles => ({
			...styles,
			color: '#4A4A4A',
			size: 7,
			':hover': { color: 'gray' },
			width: '65px',
			height: '45px',
		}),
	};
	return <Select {...props} isClearable isSearchable={false} key={'sort-types'} styles={sortTypesStyle} components={{ DropdownIndicator, ValueContainer }} placeholder="Sort by..." options={sortColleaguesValues} />;
}

export default SortByDropdown;
