import { Observable } from 'rxjs';
import { store } from '../reduxStore/store';
import { BACKEND_URL, URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

export const getAllNotifications = page => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const pageRequest = page ? `?page=${page}` : '/';
	const config = {
		...URLS.notifications.getAllNotifications(last_access_token, pageRequest),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getUnreadNotifications = page => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const pageRequest = page ? `?page=${page}` : '/';
	const config = {
		...URLS.notifications.getUnreadNotifications(last_access_token, pageRequest),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getUnreadNotificationsCount = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.notifications.getUnreadNotificationsCount(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const markAsReadAllNotifications = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.notifications.markAsReadAllNotifications(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const markAsReadNotificationById = notificationId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.notifications.markAsReadNotificationById(last_access_token, notificationId),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const unreadCountFetch = () => {
	try {
		const url = BACKEND_URL + 'notifications/count';
		return genericFetch(url, 'GET');
	} catch (er) {
		if (er) {
			throw er;
		}
	}
};

export const unreadFetch = page => {
	try {
		const url = `${BACKEND_URL}notifications/unread${page ? '?page=' + page : ''}`;
		return genericFetch(url, 'GET', { accept: 'application/json', 'content-type': 'text/plain' });
	} catch (er) {
		if (er) {
			throw er;
		}
	}
};

export const allFetch = page => {
	try {
		const url = `${BACKEND_URL}notifications${page ? '?page=' + page : ''}`;
		return genericFetch(url, 'GET', { accept: 'application/json', 'content-type': 'text/plain' });
	} catch (er) {
		if (er) {
			return er;
		}
	}
};

export const readAllFetch = () => {
	try {
		const url = `${BACKEND_URL}notifications/read-all`;
		return genericFetch(url, 'POST', { accept: 'application/json', 'content-type': 'text/plain' });
	} catch (er) {
		if (er) {
			return er;
		}
	}
};

export const readNotifFetch = notificationId => {
	try {
		const url = `${BACKEND_URL}notifications/${notificationId}/read`;
		return genericFetch(url, 'POST', { accept: 'application/json', 'content-type': 'text/plain' });
	} catch (er) {
		if (er) {
			return er;
		}
	}
};

export const genericFetch = async (url, method, header) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	try {
		const configC = {
			method: method,
			headers: {
				// accept: 'application/json',
				...header,
				Authorization: `Bearer ${last_access_token} `,
			},
			redirect: 'follow',
		};
		const response = await fetch(url, configC);
		if (response.ok) {
			const body = await response.json();
			return body;
		}
		return response;
	} catch (er) {
		if (er) {
			throw er;
		}
	}
};
