import { createReducerContext } from 'react-use';
const R = require('ramda');
type keysString = { [key: string]: string };
type keysBool = { [key: string]: boolean };
type keys = string | boolean | keysBool | keysString | { [key: string]: string | boolean | keysBool | keysString };

export type TheBlueprintsComponentsContextData =
	| {
			open: boolean;
			activeElement: string;
			[key: string]: string | boolean;
	  }
	| keys
	| { [key: string]: keys | { [key: string]: keys } };
const clean = (obj: obje) => obj ? filterObject(obj, error => error === true) : {};
const blueprintsComponentsContextReducer = (state: any, payload: any) => {
	const { open, activeElement, errors } = payload;
	const newErrors = errors ? clean(errors) : {};
	return {
		...state,
		open: open,
		activeElement: activeElement,
		errors: { ...clean(state?.errors), ...newErrors },
	};
};
export const [useBlueprintsComponentContext, TheBlueprintsComponentsContex] = createReducerContext(blueprintsComponentsContextReducer, { open: false, activeElement: '', errors: {} });

/********************************************************************************** */

export type BluePrintFieldTypeProps = {
	id: string;
	name: string;
	index: number;
	icon: string;
	data_type: string;
	options: string[] | null;
};
export type BluePrintFieldProps = {
	id: string;
	component_blueprint_id?: string;
	value: string;
	index: number;
	options?: string[] | null | any;
	field_type: BluePrintFieldTypeProps;
};
export type BluePrintSubStepsProps = {
	id: string;
	name: string;
	description: string | null;
};
export type BluePrintComponentProps = {
	id: string;
	name: string;
	icon: string;
	fields: BluePrintFieldProps[];
	components: BluePrintSubStepsProps[];
};
type BlueprintComponentsActions2 = {
	[callerType: string]:
		| 'SET_ENTIRE_CONTEXT_BLUEPRINTS_COMPONENTS_AT_ONCE'
		| 'SET_SELECTED_BLUEPRINT_COMPONENT'
		| 'RESET_SELECTED_BLUEPRINT_COMPONENT'
		| 'SET_SELECTED_BLUEPRINT_COMPONENT_NAME'
		| 'SET_SELECTED_BLUEPRINT_COMPONENT_ICON'
		| 'SET_SELECTED_BLUEPRINT_COMPONENT_FIELDS_TYPES'
		| 'RESET_SELECTED_BLUEPRINT_COMPONENT_NEW_FIELD'
		| 'RESET_SELECTED_BLUEPRINT_COMPONENT_SPECIFIC_FIELD'
		| 'SET_SELECTED_FIELD_FROM_SELECTED_BLUEPRINT_COMPONENT';
};
export const bcat = {
	setAllBlueprintsComponents: 'SET_ENTIRE_CONTEXT_BLUEPRINTS_COMPONENTS_AT_ONCE',
	setSelectedBlueprintComponent: 'SET_SELECTED_BLUEPRINT_COMPONENT',
	resetSelectedBlueprintComponent: 'RESET_SELECTED_BLUEPRINT_COMPONENT',
	setSelectedBlueprintComponentName: 'SET_SELECTED_BLUEPRINT_COMPONENT_NAME',
	setSelectedBlueprintComponentIcon: 'SET_SELECTED_BLUEPRINT_COMPONENT_ICON',
	setAllBlueprintComponentFieldsTypes: 'SET_SELECTED_BLUEPRINT_COMPONENT_FIELDS_TYPES',
	setSelectedBlueprintComponentNewField: 'RESET_SELECTED_BLUEPRINT_COMPONENT_NEW_FIELD',
	setSelectedBlueprintComponentSpecificField: 'RESET_SELECTED_BLUEPRINT_COMPONENT_SPECIFIC_FIELD',
	setSelectedFieldFromSelectedBlueprintComponent: 'SET_SELECTED_FIELD_FROM_SELECTED_BLUEPRINT_COMPONENT',
	removeSelectedFieldFromSelectedBlueprintComponent: 'REMOVE_SELECTED_FIELD_FROM_SELECTED_BLUEPRINT_COMPONENT',
} as const;

type Reducer<State, Action> = (state: State, action: Action) => State;
type ContextType = {
	allFieldTypes: BluePrintFieldTypeProps[];
	allBlueprintComponents: BluePrintComponentProps[];
	selectedBlueprintComponent: BluePrintComponentProps;
	selectedFieldFromSelectedBlueprintComponent: BluePrintFieldProps | null;
	validation: { name: boolean; icon: boolean; fields: string[] };
};

type ContextPayloadType = {
	readonly type: keyof BlueprintComponentsActions2;
	readonly value: (ContextType['allBlueprintComponents'] | ContextType['selectedBlueprintComponent']) | any;
};
const lensMatching = (pred: (a: () => boolean) => boolean) => (toF: (arg0: any) => readonly any[]) => (entities: readonly any[]) => {
	const index = R.findIndex(pred, entities);
	return R.map((entity: any) => R.update(index, entity, entities), toF(entities[index]));
};

export const lensById = R.compose(lensMatching, R.propEq('id'));
const lensMatch = (propName: string) => (key: string) => R.lens(R.find(R.propEq(propName, key)), (val: any, arr: [], idx = R.findIndex(R.propEq(propName, key))(arr)) => R.update(idx > -1 ? idx : R.length(arr), val, arr));

const procedureContextReducer: Reducer<ContextType, ContextPayloadType> = (state: ContextType, payload: ContextPayloadType) => {
	const { type, value } = payload;
	switch (type) {
		case bcat.setAllBlueprintsComponents:
			return {
				...state,
				allBlueprintComponents: R.sortWith([R.ascend(R.prop('name'))])(value),
			};
		case bcat.setSelectedBlueprintComponent:
			return {
				...state,
				selectedBlueprintComponent: value,
				validation: {
					name: value?.name?.length < 1,
					fields: R.reject(R.isNil)(value?.fields?.map((i: BluePrintFieldProps) => isFieldDataInvalid(i) ? i.id?.toString() : null)),
				},
			};

		case bcat.resetSelectedBlueprintComponent:
			return {
				...state,
				selectedBlueprintComponent: {
					id: '',
					name: '',
					icon: '',
					fields: [],
					components: [],
				},
				selectedFieldFromSelectedBlueprintComponent: null,
				validation: { name: true, icon: true, fields: [] },
			};
		case bcat.setAllBlueprintComponentFieldsTypes:
			return {
				...state,
				allFieldTypes: R.sortWith([R.ascend(R.prop('index'))])(value),
			};
		case bcat.setSelectedBlueprintComponentName:
			return {
				...state,
				selectedBlueprintComponent: {
					...state.selectedBlueprintComponent,
					name: value,
				},
				validation: { ...state.validation, name: value?.length < 1 },
			};
		case bcat.setSelectedBlueprintComponentIcon:
			return {
				...state,
				selectedBlueprintComponent: {
					...state.selectedBlueprintComponent,
					icon: value,
				},
				validation: { ...state.validation, icon: value?.length < 1 },
			};
		case bcat.setSelectedBlueprintComponentNewField: {
			let newField: BluePrintFieldProps = {
				field_type: value?.field_type,
				value: value?.label,
				id: state.selectedBlueprintComponent.fields?.length?.toString(),
				index: state.selectedBlueprintComponent.fields.length,
			};
			if (value?.options) {
				newField = { ...newField, options: { ...value?.options } };
			}
			const updatedFields =  [newField, ...state.selectedBlueprintComponent.fields];
			return {
				...state,
				selectedBlueprintComponent: {
					...state.selectedBlueprintComponent,
					fields: updatedFields,
				},
				validation: {
					...state.validation,
					fields: R.reject(R.isNil)(updatedFields?.map((i: BluePrintFieldProps) => isFieldDataInvalid(i) ? i.id?.toString() : null)),
				},
			};
		}
		case bcat.setSelectedBlueprintComponentSpecificField: {
			const idLens = lensMatch('id');
			const setNameById = (id: string, newValue: string) => R.set(R.compose(idLens(id), R.lensProp('value')), newValue);
			const setOptionsById = (id: string, newOptions: string) => R.set(R.compose(idLens(id), R.lensProp('options')), newOptions);

			let newFieldValues = setNameById(value?.id, value?.value)(state.selectedBlueprintComponent.fields);
			if (value.options) {
				newFieldValues = setOptionsById(value?.id, value?.options)(newFieldValues);
			}
			const updatedFields = /* R.sortWith([R.ascend(R.path(['field_type', 'index']))]) */ newFieldValues;
			return {
				...state,
				selectedBlueprintComponent: {
					...state.selectedBlueprintComponent,
					fields: updatedFields,
				},
				selectedFieldFromSelectedBlueprintComponent: null,
				validation: {
					...state.validation,
					fields: R.reject(R.isNil)(updatedFields?.map((i: BluePrintFieldProps) => isFieldDataInvalid(i) ? i.id?.toString() : null)),
				},
			};
		}
		case bcat.setSelectedFieldFromSelectedBlueprintComponent:
			return {
				...state,
				selectedFieldFromSelectedBlueprintComponent: value,
			};
		case bcat.removeSelectedFieldFromSelectedBlueprintComponent: {
			const updatedFields = R.compose(
				R.map(([index, item]: [number, BluePrintFieldProps]) => ({ ...item, id: item?.id?.length > 0 ? item?.id : index?.toString() })),
				R.toPairs
			)(R.reject(R.propEq('id', value.id?.toString()))(state.selectedBlueprintComponent.fields));
			return {
				...state,
				selectedBlueprintComponent: {
					...state.selectedBlueprintComponent,
					fields: updatedFields,
				},
				selectedFieldFromSelectedBlueprintComponent: null,
				validation: {
					...state.validation,
					fields: R.reject(R.isNil)(updatedFields?.map((i: BluePrintFieldProps) => isFieldDataInvalid(i) ? i.id?.toString() : null)),
				},
			};
		}

		default:
			return { ...state };
	}
};
const isFieldDataInvalid = (field: BluePrintFieldProps) => {
	let isValid = true;
	if (field.value?.length < 1) {
		isValid = false;
	}
	const field_type_options = field?.field_type?.options;
	if (field_type_options && field_type_options?.length > 0) {
		if (R.has('options')(field) && field.options !== null) {
			field_type_options?.forEach(i => {
				if (!R.has(i?.toString())(field.options)) {
					isValid = false;
				}
				if (!field?.options[i] || (field?.options?.[i] && field?.options[i]?.length < 1)) {
					isValid = false;
				}
			});
		} else {
			isValid = false;
		}
	}
	return !isValid;
};
export const [useBlueprintComponentsContext, TheBlueprintComponentsContext] = createReducerContext<Reducer<ContextType, ContextPayloadType>>(procedureContextReducer, {
	allFieldTypes: [],
	allBlueprintComponents: [],
	selectedBlueprintComponent: {
		id: '',
		name: '',
		icon: '',
		fields: [],
		components: [],
	},
	selectedFieldFromSelectedBlueprintComponent: null,
	validation: { name: false, icon: false, fields: [] },
});

type obje = { [key: string]: boolean | null | undefined };
export const filterObject = (obj: obje, predicate: (entry: boolean | null | undefined) => void) =>
	Object.keys(obj)
		.filter(key => predicate(obj[key]))
		.reduce((res: obje, key) => {
			res[key] = obj[key];
			return res;
		}, {});
