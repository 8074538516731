import { useCallback, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckButton from 'react-validation/build/button';
import Form from 'react-validation/build/form';
import processmakerLogoFinal from '../../assets/images/branding/processmakerLogoFinal.png';
import { resetPasswordIcon } from '../../assets/images/icons';
import { resetPassword } from '../../services/auth.service';
import { Button } from '../common';
import './ChangePassword.scss';
import { OutlinedInput } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
const ChangePassword = () => {
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}

	const navigate = useNavigate();
	const form = useRef();
	const checkBtn = useRef();
	const [showPassword, setShowPassword] = useState(false);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [, setShowHelp] = useState(false);
	const [message, setMessage] = useState('');
	const [sent, setSent] = useState(false);
	let query = useQuery();
	const token = query.get('token');
	const email = query.get('email');

	const handleRequest = useCallback(
		e => {
			e.preventDefault();
			setLoading(true);
			form.current.validateAll();
			if (checkBtn.current.context._errors.length === 0) {
				resetPassword(email, password, confirmPassword, token).subscribe({
					next: _data => {
						setSent(true);
						setMessage('');
					},
					error: error => {
						setSent(false);
						setMessage(error.data);
						setLoading(false);
					},
					complete: () => {
						setLoading(false);
					},
				});
			} else {
				setLoading(false);
			}
		},
		[email, password, confirmPassword]
	);

	return (
		<div id="change-password-container">
			<GlobalCss />
			<div className={'first-container'}>
				<div
					style={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							alignSelf: 'flex-start',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							alignItems: 'center',
						}}
					>
						<div style={{ alignSelf: 'flex-start', width: '400px' }}>
							<h2
								style={{
									color: '#4A4A4A',
									fontFamily: 'var(--app-text-main-font)',
									fontSize: '32px',
									fontWeight: 500,
									letterSpacing: '0',
									marginBottom: '0',
								}}
							>
								{sent ? 'Your password has been changed!' : 'Choose a New Password'}
							</h2>
							<p
								style={{
									color: '#4A4A4A',
									fontFamily: 'var(--app-text-main-font)',
									fontSize: '16px',
									fontWeight: 500,
								}}
							>
								{sent ? '' : 'Create a strong password & a more secure account. The password must be at least 8 characters.'}
							</p>
						</div>
						{!sent && (
							<div style={{ paddingTop: '30px' }}>
								<Form onSubmit={handleRequest} ref={form}>
									<label style={{ display: 'inline-flex', alignItems: 'center' }}>
										<OutlinedInput
											className="input-style"
											style={{ outline: 'none' }}
											type={showPassword ? 'text' : 'password'}
											name="password"
											placeholder="New Password"
											value={password}
											onChange={e => setPassword(e.target.value)}
											endAdornment={
												<InputAdornment className="showHideButton" onClick={() => setShowPassword(!showPassword)} position="end">
													{showPassword ? 'Hide' : 'Show'}
												</InputAdornment>
											}
										/>
									</label>
									<label style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
										<OutlinedInput
											className="input-style"
											style={{ outline: 'none' }}
											type={showPassword ? 'text' : 'password'}
											name="password"
											placeholder="Retype New Password"
											value={confirmPassword}
											onFocus={() => setMessage('')}
											onChange={e => setConfirmPassword(e.target.value)}
											endAdornment={
												<InputAdornment className="showHideButton" onClick={() => setShowPassword(!showPassword)} position="end">
													{showPassword ? 'Hide' : 'Show'}
												</InputAdornment>
											}
										/>
									</label>
									{message?.password ? (
										<div className="form-group">
											<div className="message-container" role="alert">
												{message?.password}
											</div>
										</div>
									) : null}
									{message?.email ? (
										<div className="form-group">
											<div className="message-container" role="alert">
												{message?.email}
											</div>
										</div>
									) : null}
									<div
										style={{
											marginTop: sent ? '0px' : '50px',
											width: '400px',
										}}
									>
										<Button disabled={loading} loading={loading} clicked={handleRequest} title={'Change Password'} style={{ margin: '0', width: '100%' }} />
									</div>
									{/* {message && !message.email && !message?.password.length (
                                    <div className="message-container" role="alert">
                                        {message}
                                    </div>
                                )}*/}
									<CheckButton style={{ display: 'none' }} ref={checkBtn} />
								</Form>
							</div>
						)}
						{sent && (
							<div style={{ marginTop: '20px', width: '100%' }}>
								<Button disabled={loading} loading={loading} clicked={() => navigate('/')} title={'Sign In'} style={{ margin: '0', width: '100%' }} />
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				className={'second-container'}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setShowHelp(false);
				}}
			>
				<div
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setShowHelp(true);
					}}
					style={{
						cursor: 'pointer',
						alignSelf: 'flex-end',
						marginRight: '91px',
						display: 'flex',
						position: 'fixed',
						bottom: '0',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'stretch',
						borderRadius: '8px 8px 0 0',
						backgroundColor: ' #FFFFFF',
						boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.1)',
					}}
				></div>
			</div>
		</div>
	);
};

export const GlobalCss = withStyles({
	// @global is handled by jss-plugin-global.
	'@global': {
		// You should target [class*="MuiButton-root"] instead if you nest themes.
		'.MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'var(--app-color-gray-lighter)',
			},
			'&:hover fieldset': {
				borderColor: 'var(--app-color-gray-lighter)!important',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'var(--app-color-gray-lighter)!important',
			},
		},
	},
})(() => null);
export default ChangePassword;
