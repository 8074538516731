import classNames from 'classnames';
import 'rc-datetime-picker/dist/picker.css';
import { useCallback, useLayoutEffect, useRef, useState, useEffect, memo } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { first } from 'rxjs/operators';

import { add, attention, backIcon, closeIcon2, delete2, DeleteBin, successDone } from '../../../assets/images/icons';
import { createNewCompanyDepartment, deleteCompanyDepartment, getCompanyDepartments, updateCompanyDepartment, updateCompanyDepartmentsOrder } from '../../../services/company.service';
import { Button, ErrorMessage, ModalR, TutorialModal, CustomCheckbox, PermissionWrapper, po } from '../../common';
import cls from './CreateDepartments.module.scss';
import { isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faEdit, faEllipsis, faEllipsisVertical, faTrash } from '@fortawesome/free-solid-svg-icons';

let randomColor = require('randomcolor');

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min;
}

const CreateDepartments = props => {
	const { modalVisible, onCancel, childrenWrapperStyle, onPreviousButtonAction, onContinueButtonClick, loading, shadowedContainerStyle } = props;
	const { onBoardingCompleted, companyDepartments } = useSelector(store => store.flowReducer);
	const [, setDeleteDepartment] = useState('');
	const [departments, setDepartments] = useState([
		...(companyDepartments
			?.sort((a, b) => (a.render_index < b.render_index ? -1 : a.render_index > b.render_index ? 1 : 0))
			?.map((i, index) => {
				let randomNumberString = String(getRandomInt(10, 245));
				return {
					...i,
					render_index: index,
					color: `rgb(${randomNumberString},${randomNumberString},${randomNumberString})` || randomColor({}),
				};
			}) ?? []),
	]);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [editingDepartment, setEditingDepartment] = useState(false);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showDeleteResult, setShowDeleteResult] = useState({ visible: false, message: '' });

	const [editableRow, toggleEditableRow] = useState(false);
	const [ndValue, setNdValue] = useState('');
	const [dragId, setDragId] = useState();
	const [responseError, setResponseError] = useState();

	useLayoutEffect(() => {
		retreiveDepartmentsFromServer();
	}, []);
	const retreiveDepartmentsFromServer = () => {
		getCompanyDepartments()
			.pipe(first())
			.subscribe({
				next: data => {
					setDepartments([
						...(data
							?.sort((a, b) => (a.render_index < b.render_index ? -1 : a.render_index > b.render_index ? 1 : 0))
							?.map((i, index) => {
								let randomNumberString = String(getRandomInt(10, 245));
								return {
									...i,
									render_index: index,
									color: `rgb(${randomNumberString},${randomNumberString},${randomNumberString})` || randomColor({}),
								};
							}) ?? []),
					]);
				},
			});
	};
	const onAddNewDepartment = useCallback(() => {
		setResponseError('');
		createNewCompanyDepartment(ndValue, departments?.length + 1).subscribe({
			next: data => {
				setDepartments([
					...(data
						?.sort((a, b) => (a.render_index < b.render_index ? -1 : a.render_index > b.render_index ? 1 : 0))
						?.map((i, index) => {
							let randomNumberString = String(getRandomInt(10, 245));
							return {
								...i,
								render_index: index,
								color: `rgb(${randomNumberString},${randomNumberString},${randomNumberString})` || randomColor({}),
							};
						}) ?? []),
				]);
				toggleEditableRow(false);
				setNdValue('');
				setEditingDepartment(false);
			},
			error: error => {
				setResponseError(error.data);
			},
		});
	}, [ndValue, selectedDepartment]);
	const onEditDepartment = useCallback(() => {
		setResponseError('');
		updateCompanyDepartment(ndValue, selectedDepartment.id).subscribe({
			next: data => {
				setDepartments(c => [
					...(c?.map((i, _index) => {
						let randomNumberString = String(getRandomInt(10, 245));
						return i.id === data.id
							? {
									...data,
									color: `rgb(${randomNumberString},${randomNumberString},${randomNumberString})` || randomColor({}),
							  }
							: {
									...i,
									color: `rgb(${randomNumberString},${randomNumberString},${randomNumberString})` || randomColor({}),
							  };
					}) ?? []),
				]);
				toggleEditableRow(false);
				setNdValue('');
				setEditingDepartment(false);
			},
			error: error => {
				setResponseError(error.data);
			},
		});
	}, [ndValue, selectedDepartment]);
	const onDeleteDepartment = useCallback(() => {
		setResponseError('');
		setShowDeleteModal(true);
	}, [selectedDepartment]);

	const onConfirmDeletePressed = useCallback(
		() =>
			deleteCompanyDepartment(selectedDepartment)
				.pipe(first())
				.subscribe({
					next: data => {
						setDepartments(c => [
							...(c
								?.filter(i => i.id !== selectedDepartment.id)
								?.map((i, idx) => ({
									...i,
									render_index: idx,
								})) ?? []),
						]);
						toggleEditableRow(false);
						setNdValue('');
						setEditingDepartment(false);
						setSelectedDepartment(null);
						setDeleteDepartment('');

						setShowDeleteModal(false);
						setShowDeleteResult({
							visible: true,
							message: 'The department has been succesfully deleted.',
						});
					},
					error: error => {
						setResponseError(error.data);
						setShowDeleteModal(false);
						setShowDeleteResult({
							visible: false,
							message: '',
						});
					},
				}),
		[selectedDepartment]
	);
	const selectDepartment = useCallback(department => setSelectedDepartment(department), []);
	const handleDragStart = useCallback(ev => {
		if (ev.currentTarget.style.cursor !== 'grabbing') {
			ev.currentTarget.style.cursor = 'grabbing';
		}
		ev.currentTarget.focus();
		setDragId(ev.currentTarget.id);
	});

	const handleDrop = useCallback(ev => {
		ev.currentTarget.style.cursor = 'grabbing';
		const dragBox = departments.find(box => box.id === dragId);
		const dropBox = departments.find(box => box.id === ev.currentTarget.id);
		const dragBoxOrder = dragBox?.render_index;
		const dropBoxOrder = dropBox?.render_index;

		let newBoxState = [];
		if (dragBoxOrder + 1 == dropBoxOrder || dragBoxOrder - 1 == dropBoxOrder) {
			newBoxState = departments
				?.map(box => {
					if (box.id === ev.currentTarget.id) {
						box.render_index = dragBoxOrder;
					}
					if (box.id === dragId) {
						box.render_index = dropBoxOrder;
					}
					return box;
				})
				?.sort((a, b) => (a.render_index < b.render_index ? -1 : a.render_index > b.render_index ? 1 : 0))
				.map((item, index) => ({ ...item, render_index: index }));
		} else {
			//const dragBoxIndex = departments.findIndex((box) => box.id === dragId);
			const dropBoxIndex = departments.findIndex(box => box.id === ev.currentTarget.id);
			if (dragBoxOrder > dropBoxOrder) {
				const array = [...departments.filter(i => i.id != dragBox?.id)];
				array.splice(dropBoxIndex + 1, 0, { ...dragBox, render_index: dropBoxIndex + 1 });
				newBoxState = array?.map((i, idx) => ({ ...i, render_index: idx }));
			} else {
				const array = [...departments.filter(i => i.id != dragBox?.id)];
				array.splice(dropBoxIndex - 1, 0, { ...dragBox, render_index: dropBoxIndex - 1 });
				newBoxState = array?.map((i, idx) => ({ ...i, render_index: idx }));
			}
		}
		updateCompanyDepartmentsOrder([
			...(newBoxState?.map(i => ({
				id: i.id,
				render_index: i.render_index?.toString(),
			})) ?? []),
		]).subscribe({
			next: () => {
				setDepartments(newBoxState);
			},
		});
	});
	const DraggableCard = memo(props => {
		const containerRef = useRef();
		const { department, selected, editDepartment } = props;
		return (
			<div
				ref={containerRef}
				draggable={true}
				id={department?.id}
				onDragOver={ev => {
					ev.preventDefault();
					ev.stopPropagation();
				}}
				onDragStart={e => {
					handleDragStart(e);
				}}
				onDrop={e => {
					handleDrop(e);
				}}
				onMouseMove={e => {
					if (e.currentTarget.style.cursor === 'grabbing') {
						handleDragStart(e);
					}
				}}
				onMouseEnter={e => {
					if (e.currentTarget.style.cursor !== 'pointer') {
						e.currentTarget.style.cursor = 'pointer';
					}
				}}
				onMouseLeave={e => {
					if (e.currentTarget.style.cursor === 'grab') {
						e.currentTarget.style.cursor = '';
					}
				}}
				onMouseDown={e => {
					e.currentTarget.style.cursor = 'grabbing';
				}}
				onClick={e => {
					if (e.defaultPrevented) {
						return;
					}
					e.currentTarget.focus();
					e.preventDefault();
					e.stopPropagation();
					selectDepartment(department);
				}}
				className={cls.host_DC}
			>
				<div className={cls.wrapperStyle_DC} style={{ backgroundColor: selected ? 'lightGray' : 'white' }}>
					<div className={cls.mainContainerStyle_DC}>
						<div className={cls.departmentWrapperStyle_DC}>
							<div className={cls.departmentNameContainer_DC}>
								<p className={cls.departmentNameStyle_DC}>{department.name}</p>
							</div>
						</div>
						{selected && (
							<div name="annotationWrapper" className={cls.annotationWrapperStyle_DC}>
								<PermissionWrapper permissions={[po.company.departments.edit]}>
									<div name="renameButton" className={cls.cancelButtonStyle_DC} onClick={() => editDepartment()}>
										<FontAwesomeIcon icon={faEllipsis} style={{ width: '40px', height: '40px' }} />
									</div>
								</PermissionWrapper>
								<PermissionWrapper permissions={[po.company.departments.delete]}>
									<div
										name="deleteButton"
										className={cls.cancelButtonStyle_DC}
										onClick={() => {
											setDeleteDepartment('You are about the delete the ' + department.name);
											onDeleteDepartment();
										}}
									>
										<FontAwesomeIcon icon={faTrash} />
									</div>
								</PermissionWrapper>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	});

	return (
		<>
			{showDeleteResult?.visible && (
				<DeleteDepartmentResult
					isOpen={showDeleteResult?.visible}
					message={showDeleteResult?.message}
					onClose={() => {
						setShowDeleteResult({ visible: false, message: '' });
						setShowDeleteModal(false);
					}}
				/>
			)}
			<ModalR blured={true} scrollable={true} isOpen={modalVisible} onClose={onCancel} onContentPress={() => selectDepartment(null)} shadowedContainerStyle={shadowedContainerStyle}>
				<div className={cls.firstDiv_VII} style={{ ...childrenWrapperStyle }} onClick={() => selectDepartment(null)}>
					<div className={cls.headerContainer_VII}>
						<h1 className={cls.header_VII}>{onBoardingCompleted ? ' Create Departments ' : ' 3. Create Departments'}</h1>
					</div>
					<div className={classNames(cls.subheadingContainer_VII, cls.justifiedLeft_VII)}>
						<p className={cls.subheading_VII}>{'Start by adding the departments your company has. This will help you define positions in the next step. You can also edit by clicking the deprtment name and press the edit icon'}</p>
					</div>
					<div className={cls.departmentsContainer_VII}>
						<div style={{ flex: '1 1 auto', maxHeight: '500px', overflowY: 'auto', width: '100%' }}>
							{departments?.length > 0 &&
								departments?.map((item, index) => {
									return (
										<DraggableCard
											key={item?.id + index}
											department={item}
											selected={selectedDepartment?.id == item?.id}
											editDepartment={() => {
												toggleEditableRow(true);
												setNdValue(selectedDepartment.name);
												setEditingDepartment(true);
											}}
										/>
									);
								})}
						</div>
						{editableRow && (
							<div className={cls.wrapperStyle_VII}>
								<div className={cls.mainContainerStyle_VII}>
									<div className={cls.departmentWrapperStyle_VII}>
										<div className={cls.departmentColorStyle_VII} app-variant-add-dep={'true'} />
										<input autoFocus={true} className={cls.departmentInputStyle_VII} value={ndValue} onChange={e => e.target.value?.length < 48 && setNdValue(e.target.value)} />
									</div>
									<div className={cls.annotationWrapperStyle_VII}>
										<div className={cls.saveButtonStyle_VII} onClick={() => (editingDepartment ? onEditDepartment() : onAddNewDepartment())}>
											<p
												style={{
													fontFamily: 'var(--app-text-main-font)',
													fontSize: '16px',
													color: '#4A4A4A',
													paddingRight: '10px',
												}}
											>
												{'Save'}
											</p>
										</div>
										<div
											className={cls.cancelButtonStyle_VII}
											onClick={() => {
												toggleEditableRow(false);
												setNdValue('');
												setEditingDepartment(false);
											}}
										>
											<img alt="" src={closeIcon2} style={{ width: '100%', height: '100%' }} />
										</div>
									</div>
								</div>
							</div>
						)}
						{responseError && <ErrorMessage>{responseError}</ErrorMessage>}
						<div className={cls.addDepartmentButtonContainer_VII}>
							<div
								onClick={() => {
									toggleEditableRow(true);
									setSelectedDepartment(null);
								}}
								className={cls.addDepartmentButton_VII}
							>
								<img alt="" src={add} className={cls.addDepartmentButtonIcon_VII} />
								<p className={cls.addDepartmentButtonText_VII}>{'Add Department'}</p>
							</div>
						</div>
					</div>

					<div className={cls.buttonWrapper_VII}>
						<div className={onBoardingCompleted ? cls.buttonContainer_VII : cls.buttonWrapper_III}>
							{!onBoardingCompleted && onPreviousButtonAction && <Button light clicked={onPreviousButtonAction} title={'Back'} />}
							{onContinueButtonClick && <Button disabled={departments.length > 0 ? false : true} loading={loading ? loading : undefined} clicked={onContinueButtonClick} style={{}} title={'Continue'} />}
						</div>

						<div className={cls.skipThisStepContainer_VII} style={{ pointerEvents: props.skipDisabled ? 'none' : undefined }} onClick={onContinueButtonClick}>
							{onBoardingCompleted && <Button clicked={onCancel} title={'Close'} style={{ width: '120px' }} />}
						</div>
					</div>
				</div>
			</ModalR>

			<TutorialModal />
			{showDeleteModal && (
				<DeleteDepartmentComponent
					selectedDepartment={selectedDepartment}
					onConfirmDeletePressed={onConfirmDeletePressed}
					isOpen={showDeleteModal}
					onClose={p => {
						setShowDeleteModal(false);
						if ([true, false].indexOf(p?.success) > -1) {
							setShowDeleteResult({
								visible: true,
								message: p.message,
							});
						}
					}}
				/>
			)}
		</>
	);
};
const DeleteDepartmentComponent = props => {
	const { isOpen, onClose, selectedDepartment, onConfirmDeletePressed } = props;
	const [checkedFirst, setCheckedFirst] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCase, setShowCase] = useState(0);
	const [warningAnnotation, setWarningAnnotation] = useState(false);
	const settingWarningAnnotation = val => {
		setWarningAnnotation(val);
	};

	useEffect(() => {
		if (showCase == 1) {
			var wa = `You are about to delete ${selectedDepartment.name}`;
			const sps = selectedDepartment?.positions_count;
			if (sps > 0) {
				if (checkedFirst) wa += `You can only delete a department if all its positions are deleted before.`;
			} else {
				wa += '.';
			}
			settingWarningAnnotation(wa);
		}
	}, [showCase]);

	const onBackToDepartmentsPressed = () => {
		setLoading(false);
		setShowCase(0);
		onClose({ succes: undefined, message: 'User canceled.' });
	};
	const onDeletePressed = () => {
		setShowCase(1);
	};
	const onConfirmDeletion = useCallback(() => {
		onConfirmDeletePressed?.();
	}, [checkedFirst]);
	return (
		<ModalR
			shadowedContainerStyle={{ maxWidth: '700px', minWidth: '475px', width: '70%' }}
			onClose={() => {
				onClose({ succes: undefined, message: 'User canceled.' });
			}}
			isOpen={isOpen}
			backdropColor={true}
			blured={true}
			hasCloseButton={showCase === 1 ? true : false}
		>
			<div className={cls.deleteModalContainer}>
				{showCase == 0 && (
					<div className={cls.deletePositionContainer}>
						<h1 className={cls.deletePosition_title}>{'Delete Department'}</h1>
						<p className={cls.deletePosition_description}>{'Please select the following action:'}</p>
						<div className={cls.deletePosition_checkboxContainer}>
							<CustomCheckbox
								label={`I want to delete the following department: ${selectedDepartment?.name}.`}
								
								onChange={checked => {
									setCheckedFirst(checked);
								}}
								defaultChecked={checkedFirst}
								mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
								checkMarkStyle={{ width: '24px', height: '24px' }}
							/>
						</div>
						<div className={cls.deletePosition_buttonContainer}>
							{checkedFirst ? (
								<Button title="Delete" style={{ marginLeft: '0px', width: '79%', padding: '0 10px' }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={onDeletePressed} />
							) : (
								<Button title={'Cancel'} style={{ marginLeft: '0px', width: '79%', padding: '0 10px' }} titleStyle={{ textAlign: 'center', padding: '0 10px ' }} clicked={onBackToDepartmentsPressed} />
							)}
						</div>
					</div>
				)}
				{showCase == 1 && (
					<div className={cls.warningDeletionContainer}>
						<h1 className={cls.warningDeletion_warning}>{'Warning'}</h1>
						<p className={cls.warningDeletion_warningAnnotation}>{'This action is irreversible.'}</p>
						<div className={cls.warningDeletion_warningInformation}>
							<p>{warningAnnotation}</p>
						</div>
						<div className={cls.warningDeletion_buttonContainer}>
							<Button  loading={loading} title="Permanently Delete" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={onConfirmDeletion} />
						</div>
					</div>
				)}
				{showCase == 2 && (
					<div className={cls.loadingContainer}>
						<Loader type={isSafari ? 'Watch' : 'Grid'} color="var(--app-color-gray-dark)" /*  visible={props.loading} */ secondaryColor="blue" height={'250px'} width={'250px'} /*radius={50} height={100} */ />
					</div>
				)}
			</div>
		</ModalR>
	);
};

const DeleteDepartmentResult = props => {
	const { isOpen, onClose, message } = props;
	const [deleteMessage] = useState(message);
	return (
		<ModalR backdropStyle={{ height: '100%' }} shadowedContainerStyle={{ maxWidth: '700px', minWidth: '475px' }} marginTop={'2%'} onClose={onClose} hasCloseButton position={'fixed'} scrollable={true} backdropColor={true} blured={true} width={'60%'} isOpen={isOpen}>
			<div className={cls.duplicateModalContainer}>
				<div className={cls.resultOperationWrapper}>
					<div className={cls.resultOperationContainer}>
						<div className={cls.resultContainer}>
							<h1 className={cls.resultText}>{deleteMessage}</h1>
						</div>
						<div className={cls.buttonContainer}>
							<Button title="Back to Departments" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onClose()} />
						</div>
					</div>
				</div>
			</div>
		</ModalR>
	);
};
export default CreateDepartments;
