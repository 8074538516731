import GoogleMapReact from 'google-map-react';
import classes from './MapPin.module.scss';
import { useEffect, useCallback, useState } from 'react';
import { getAllComponentBlueprints } from '../../../services/components.service';
import { connect } from 'react-redux';
import { FieldWrapper, SelectD } from '../../common';
import { useBlueprintsComponentContext, TheBlueprintsComponentsContex } from '../../../context/blueprintsComponents.context';
import { first } from 'rxjs/operators';

const R = require('ramda');
const mappingSchema = {
	selectProcess: {
		id: 'selectedProcess',
		title: 'Select Workflow',
		tooltipText: 'Select target process for this map',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useBlueprintsComponentContext,
	},
	selectBlueprint: {
		id: 'selectedComponentBlueprint',
		title: 'Select source of map pins',
		tooltipText: 'Select target source pins for this map',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useBlueprintsComponentContext,
	},
	selectComponent: {
		id: 'selectedComponent',
		title: 'Select Component',
		tooltipText: 'Select target component for this map',
		defaultHighlighted: true,
		withAckButton: false,

		useContext: useBlueprintsComponentContext,
	},
	selectFields: {
		id: 'selectedFields',
		title: 'Filter by',
		tooltipText: 'Select target filter for this map',
		defaultHighlighted: true,
		withAckButton: false,

		useContext: useBlueprintsComponentContext,
	},
	selectItem: {
		id: 'selectedItem',
		title: 'Select Item',
		tooltipText: 'Select target item for this map',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useBlueprintsComponentContext,
	},
	selectRoutingMethod: {
		id: 'selectedRouting',
		title: 'Select Routing',
		tooltipText: 'Select target routing method for this map',
		defaultHighlighted: true,
		withAckButton: false,
		useContext: useBlueprintsComponentContext,
	},
};

const MapRoutes = props => {
	const { companyProcesses, mapkey = '' } = props;

	const [sectionErrors, setSectionErrors] = useState({ name: '', icon: '', fields: '', components: '' });
	const [componentsArray, setComponentsArray] = useState([]);
	const [componentsBlueprintArray, setComponentsBlueprintArray] = useState([]);
	const [selectedComponents, setSelectedComponents] = useState([]);
	const [selectedFields, setSelectedFields] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedProcesses, setselectedProcesses] = useState([]);

	const [selectedComponentBlueprint, setSelectedComponentBlueprint] = useState({ value: '', blueprint: {} });
	const [selectedProcess, setSelectedProcess] = useState({ value: '', process: {} });
	const [selectedField, setSelectedField] = useState({ value: '', field: {} });
	const [selectedItem, setSelectedItem] = useState({ value: '', item: {} });
	const [points, setPoints] = useState([]);
	//const [popupInfo, setPopupInfo] = useState({});
	const processesList =
		companyProcesses?.length > 0
			? companyProcesses.map(elem => {
					return { label: elem.name, value: elem.id };
			  })
			: [];
	const MyMarker = ({ text, tooltip }) => (
		<div className={classes.circle}>
			<span className={classes.circleText} title={tooltip}>
				{text}
			</span>
		</div>
	);
	const distanceToMouse = (pt, mp) => {
		if (pt && mp) {
			return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
		}
	};

	useEffect(() => {
		retreiveComponents();
	}, []);

	const pinComponents = useCallback((components, selected) => {
		const found = components.filter(item => {
			return (
				item.component.component_fields.filter(field => {
					return field.value === selected.value;
				}).length > 0
			);
		});

		let pinA = [];
		found.map(elem => {
			const pin = elem.component.component_fields.find(field => {
				return field.field_name === 'Pin';
			});

			if (pin != undefined) {
				if (pin.value) {
					const location = R.split(',', pin.value);
					pinA.push({ id: elem.id, title: elem.label, lat: +location[0], lng: +location[1] });
				}
			}
		});

		return { components: found, pins: pinA };
	});

	const retreiveComponents = useCallback(() => {
		getAllComponentBlueprints()
			.pipe(first())
			.pipe(first())
			.subscribe({
				next: data => {
					const blueprintsArray =
						data.data.length > 0
							? R.sortBy(
									R.prop('name'),
									data.data.filter(outer_item => {
										return (
											outer_item.fields.filter(inner_item => {
												return inner_item.name === 'Pin';
											}).length > 0
										);
									})
							  )
							: [];

					setComponentsBlueprintArray(
						blueprintsArray.map(elem => {
							return { label: elem.name, value: elem.id, blueprint: elem };
						})
					);
				},
				error: _err => {},
			});
	});

	
	return (
		<div style={{ height: '1200px', width: '100%' }}>
			Routes
			<TheBlueprintsComponentsContex>
				<div style={{ height: '30%', width: '100%' }}>
					<GoogleMapReact
						// onClick={ev => {
						// 	onLocationChange({
						// 		lat: ev.lat,
						// 		lng: ev.lng,
						// 	});
						// }}
						bootstrapURLKeys={{
							key: '',
							language: 'en',
							region: 'US',
						}}
						//center={{ lat: 14.652116, lng: 121.012255, }}
						center={{ lat: -33.8149597, lng: 150.9171779 }}
						//defaultZoom={12}
						defaultZoom={11}
						distanceToMouse={distanceToMouse}
					>
						{points.map(({ lat, lng, id, title }) => {
							return <MyMarker key={id} lat={lat} lng={lng} tooltip={title} />;
						})}
					</GoogleMapReact>
				</div>

				<br />

				<div style={{ maxWidth: '50%' }}>
					<FieldWrapper {...mappingSchema.selectBlueprint} value={selectedComponentBlueprint.value} placeholder="Select blueprint..." sectionError={sectionErrors.icon} onOpenChanged={() => {}}>
						<SelectD
							//isClearable
							items={componentsBlueprintArray}
							iconValue={true}
							defaultSelected={''}
							className={classes.selectComponent}
							molclassname={classes.selectMenuOverlayList}
							moliclassname={classes.selectMenuOverlayListItem}
							placeholder={'Select blueprint...'}
							onChange={selected => {
								const fields_for_mapping = selected.blueprint.fields.filter(item => {
									return item.name != 'Pin';
								});

								setSelectedComponentBlueprint({ value: selected.label, blueprint: selected.blueprint });

								setSelectedFields([
									...fields_for_mapping.map(elem => {
										return { label: elem.value, value: elem.id, field: elem };
									}),
								]);

								setPoints([]);
								setSelectedField({ value: '', field: {} });
								setSelectedItem({ value: '', item: {} });
								setSelectedProcess({ value: '', process: {} });

								let pinA = [];
								componentsArray.map(elem => {
									const pin = elem.component.component_fields.find(field => {
										return field.field_name === 'Pin';
									});

									if (pin != undefined) {
										if (pin.value) {
											const location = R.split(',', pin.value);
											pinA.push({ id: elem.id, title: elem.label, lat: +location[0], lng: +location[1] });
										}
									}
								});

								setPoints([...pinA]);
							}}
						/>
					</FieldWrapper>

					<FieldWrapper {...mappingSchema.selectFields} value={selectedField.value} placeholder="Select field..." sectionError={sectionErrors.icon} onOpenChanged={() => {}}>
						<SelectD
							//isClearable
							items={selectedFields}
							iconValue={true}
							defaultSelected={''}
							className={classes.selectComponent}
							molclassname={classes.selectMenuOverlayList}
							moliclassname={classes.selectMenuOverlayListItem}
							placeholder={'Select field...'}
							onChange={selected => {
								setSelectedField({ value: selected.label, field: selected.field });

								const components_for_item_selection = componentsArray.filter(item => {
									return (
										item.component.component_fields.filter(inner_item => {
											return inner_item.component_blueprint_id === selected.field.component_blueprint_id;
										}).length > 0
									);
								});

								let array_for_filter = [];
								components_for_item_selection.map(item => {
									const result = item.component.component_fields.find(field => {
										return field.field_name === selected.label;
									});

									if (result != undefined) {
										if (result.data_type_name === 'People' || result.data_type_name === 'Record') {
											array_for_filter.push({ label: result.value.split(',')[0], value: result.value, id: result.id, component: item.component });
										} else {
											array_for_filter.push({ label: result.value, value: result.value, id: result.id, component: item.component });
										}
									}
								});

								setSelectedItems([...new Map(array_for_filter.map(item => [item['label'], item])).values()]);

								setSelectedComponents(components_for_item_selection);

								setPoints([]);
								setSelectedItem({ value: '', item: {} });
								setSelectedProcess({ value: '', process: {} });
							}}
						/>
					</FieldWrapper>
					<FieldWrapper {...mappingSchema.selectItem} value={selectedItem.value} placeholder="Select item..." sectionError={sectionErrors.icon} onOpenChanged={() => {}}>
						<SelectD
							//isClearable
							items={selectedItems}
							iconValue={true}
							defaultSelected={''}
							className={classes.selectComponent}
							molclassname={classes.selectMenuOverlayList}
							moliclassname={classes.selectMenuOverlayListItem}
							placeholder={'Select item...'}
							onChange={selected => {
								const found = pinComponents(selectedComponents, selected);

								setSelectedItem({ value: selected.label, id: selected.id, item: selected });

								setselectedProcesses(processesList.filter(process => [...found.components.map(elem => elem.component.procedure_id)].some(filter => process.value === filter)));

								setPoints([...found.pins]);
								setSelectedProcess({ value: '', process: {} });
							}}
						/>
					</FieldWrapper>
					<FieldWrapper
						{...mappingSchema.selectProcess}
						value={selectedProcess.value}
						placeholder="Select workflow..."
						sectionError={sectionErrors.icon}
						onOpenChanged={() => {
							setSectionErrors(c => ({ ...c, icon: '' }));
						}}
					>
						<SelectD
							//isClearable
							items={selectedProcesses}
							iconValue={true}
							defaultSelected={''}
							className={classes.selectComponent}
							molclassname={classes.selectMenuOverlayList}
							moliclassname={classes.selectMenuOverlayListItem}
							placeholder={'Select workflow...'}
							onChange={selected => {
								const found = pinComponents(
									selectedComponents.filter(item => {
										return item.component.procedure_id === selected.value;
									}),
									{ value: selectedItem.item.value }
								);

								setSelectedProcess({ id: selected.value, value: selected.label });
								setPoints([...found.pins]);
							}}
						/>
					</FieldWrapper>
					<FieldWrapper
						{...mappingSchema.selectRoutingMethod}
						//value={selectedProcess.value}
						placeholder="Select routing method..."
						sectionError={sectionErrors.icon}
						onOpenChanged={() => {}}
					>
						<SelectD
							//isClearable
							items={[
								{ label: 'By Manual Routing', value: 'Method 1' },
								{ label: 'Automated - by distance', value: 'Method 2' },
								{ label: 'By sales visit time', value: 'Method 3' },
							]}
							iconValue={true}
							defaultSelected={''}
							className={classes.selectComponent}
							molclassname={classes.selectMenuOverlayList}
							moliclassname={classes.selectMenuOverlayListItem}
							placeholder={'Select routing method...'}
							onChange={() => {}}
						/>
					</FieldWrapper>
				</div>
			</TheBlueprintsComponentsContex>
		</div>
	);
};

const mapStateToProps = store => {
	const { companyProcesses } = store.flowReducer;
	return { companyProcesses };
};

export default connect(mapStateToProps)(MapRoutes);
