import classNames from 'classnames';
import { isMobile, isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import cls from './Button.module.scss';
import ModalR from '../Modal/ModalR';
import Spinner from '../Spinner/Spinner';
import { useState } from 'react';

const Button = props => {
	const [show, setShow] = useState(true);

	const { loading, loadingIn, disabled, clicked, style, title, titleStyle, titleContainerStyle, children, icon, type, className, light, loaderRadius, clear, id = '', titleContainer, message = 'Processing...' } = props;
	return (
		<button
			id={id === '' ? title : id}
			type={type ?? 'button'}
			disabled={disabled}
			className={classNames(cls.host, className)}
			comp-state={disabled ? 'disabled' : 'enabled'}
			comp-loading={loading ? 'loading' : null}
			comp-variant={light ? 'light' : clear ? 'clear' : ''}
			style={{ ...style, border: loadingIn ? '0' : '', userSelect: 'none' }}
			onClick={clicked}
		>
			<div className={cls.target} style={{ display: loadingIn ? 'none' : '' }}>
				{!loading && icon && <div className={cls.iconContainer}>{icon}</div>}
				{title && (
					<div className={classNames(cls.titleContainer, titleContainer)} style={titleContainerStyle}>
						<p style={titleStyle}>{title}</p>
					</div>
				)}
			</div>
			{children}
			{loadingIn ? <Loader type={isSafari ? 'Watch' : 'Puff'} color={light ? 'var(--app-color-gray-dark)' : 'white'} secondaryColor="gray" radius={loaderRadius ?? 20} height={loaderRadius ?? 20} width={`${loaderRadius ?? 20}px`} /> : null}
			{loading && show ? (
				<ModalR blured={true} scrollable={true} isOpen={loading && show} shadowedContainerStyle={{ width: '300px' }}>
					<div className={cls.center} style={{ width: '250px', height: '50px' }} onClick={() => setShow(false)}>
						<Spinner type={'BallTriangle'} color="black" height={30} width={`30px`} style={{ marginRight: '30px' }} />
						{message}
					</div>
				</ModalR>
			) : null}
		</button>
	);
};
export default Button;
