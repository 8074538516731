import GoogleMapReact from 'google-map-react';
import { LoadScript, DirectionsRenderer } from '@react-google-maps/api';
import cls from './MapPin.module.scss';
import { useState, useRef, useEffect, useCallback } from 'react';

import { setKey, setDefaults, setLanguage, setRegion, fromAddress, fromLatLng, fromPlaceId, setLocationType, geocode, RequestType } from 'react-geocode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faLocationCrosshairs, faLocationDot, faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../reduxStore/store';
import { setCurrentLocation } from '../../../reduxStore/actions/flowActions';

const R = require('ramda');

const MapPin = props => {
	const { onLocationChange = () => { }, value, mapkey = '', onChange = () => { }, placeholder = '' } = props;

	const { currentLocation } = useSelector(store => store.flowReducer);

	const [address, setAddress] = useState(value?.address ?? '');
	const [addressArray, setAddressArray] = useState([]);
	const [currentLoc, setCurrentLoc] = useState({});
	const [message, setMessage] = useState('');
	const [points, setPoints] = useState({ id: 1, title: value?.lat ? 'Target Location' : 'Current Location', lat: value?.lat ?? currentLocation?.latitude ?? 0 , lng: value?.lng ?? currentLocation?.longitude ?? 0 });


	useEffect(() => {
		setKey(mapkey);
		//setKey('');
		navigator.geolocation.getCurrentPosition(
			location => {
				if (!(value?.lat && value?.lng)) {
					if (location) {
						setPoints({ id: 1, title: 'Current Location', lat: location.coords.latitude, lng: location.coords.longitude });
						setCurrentLoc({ id: 1, title: 'Current Location', lat: location.coords.latitude, lng: location.coords.longitude });
						store.dispatch(setCurrentLocation({ latitude: location.coords.latitude, longitude: location.coords.longitude }));
					}
				}
			},
			error => console.log(error)
		);
	}, []);

	const MyMarker = ({ text, tooltip }) => (
		<div className={cls.xcircle}>
			<span className={cls.circleText} title={tooltip}>
				<FontAwesomeIcon icon={faLocationDot} style={{ color: 'green', width: '50px', height: '50px' }} />
				{text}
			</span>
		</div>
	);
	const MyCurrentLocationMarker = ({ text, tooltip }) => (
				<FontAwesomeIcon icon={faCircle} style={{ color: 'red', width: '10px', height: '10px' }} />
	);
	const distanceToMouse = (pt, mp) => {
		if (pt && mp) {
			return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
		}
	};
	return (
		<div style={{ height: addressArray.length > 0 ? '': '600px', width: '100%', margin: '20px', textAlign: 'center' }}>
			<div style={{ display: 'flex', width: '100%', margin: 'auto', border: '1px dashed black' }}>
				<Button
					clear
					style={{ width: '10%', height: '20px' }}
					clicked={() => {
						if (Object.keys(currentLoc).length > 0) {
							setPoints(currentLoc);

							fromLatLng(currentLoc.lat, currentLoc.lng)
								.then(({ results }) => {
									setAddress(results[0].formatted_address);
									setAddressArray(
										results.map(res => {
											return {
												label: res.formatted_address,
												value: res.formatted_address,
												res: res,
											};
										})
									);
								})
								.catch(console.error);
						}
					}}
					icon={<FontAwesomeIcon icon={faLocationCrosshairs} style={{ swidth: '20px', height: '20px' }} />}
				/>
				<input
					onBlur={e => {
						if (e.target.value.length > 3) {
							geocode(RequestType.ADDRESS, address)
								.then(({ results }) => {
									const { lat, lng } = results[0].geometry.location;

									setPoints({
										id: 1,
										title: 'Target Location',
										lat: lat,
										lng: lng,
									});

									setAddressArray(
										results.map(res => {
											return {
												label: res.formatted_address,
												value: res.formatted_address,
												res: res,
											};
										})
									);
								})
								.catch(() => setMessage('Address need more details'));
						} else {
							setAddressArray([]);
						}
					}}
					type={'text'}
					onChange={e => {
						setAddress(e.target.value);
					}}
					value={address}
					autoComplete="none"
					className={cls.fieldTypeInput}
					style={{ width: '74%', border: '0' }}
					placeholder="Type address"
				/>
				<Button
					clear
					style={{ width: '10%', height: '20px' }}
					icon={
						<FontAwesomeIcon
							icon={faSearch}
							style={{ swidth: '20px', height: '20px' }}
							clicked={() => {
								if (address.length > 3) {
									geocode(RequestType.ADDRESS, address)
										.then(({ results }) => {
											const { lat, lng } = results[0].geometry.location;

											setPoints({
												id: 1,
												title: 'Target Location',
												lat: lat,
												lng: lng,
											});

											setAddressArray(
												results.map(res => {
													return {
														label: res.formatted_address,
														value: res,
													};
												})
											);
										})
										.catch(() => { });
								}
							}}
						/>
					}
				/>
			</div>
			<p onClick={() => setMessage('')}>{message}</p>
			{addressArray.slice(0, 5).map((address, index) => {
				return (
					<div key={index}>
						<Button
							light
							title={address.value}
							style={{ height: '100px', width: '100%' }}
							titleStyle={{ textAlign: 'left' }}
							clicked={() => {
								onChange(address?.res);
							}}
						/>
					</div>
				);
			})}
			<div style={{ marginTop: '20px', height: '80%', width: '100%' }}>
				<GoogleMapReact
					onClick={ev => {
						fromLatLng(ev.lat, ev.lng)
							.then(({ results }) => {
								setAddress(results[0].formatted_address);
								setAddressArray(
									results.map(res => {
										return {
											label: res.formatted_address,
											value: res.formatted_address,
											res: res,
										};
									})
								);
							})
							.catch(console.error);

						setPoints({
							id: 1,
							title: 'Target Location',
							lat: ev.lat,
							lng: ev.lng,
						});
					}}
					bootstrapURLKeys={{
						key: mapkey,
						//key: '',
						language: 'en',
						region: 'US',
					}}
					center={{ lat: +points.lat, lng: +points.lng }}
					defaultZoom={15}
					distanceToMouse={distanceToMouse}
				>
					{currentLocation && <MyCurrentLocationMarker key={1000} lat={currentLocation.latitude} lng={currentLocation.longitude} tooltip={'Current Location'} />}
					{points.title !== 'Current Location' && <MyMarker key={points.id} lat={points.lat} lng={points.lng} tooltip={points.title} />}
					
				</GoogleMapReact>
			</div>
		</div>
	);
};

export default MapPin;

/*

import { useRef, useState } from "react";
import "./App.css";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";

const center = {
  lat: 7.8731,
  lng: 80.7718,
};

function App() {
  const { isLoaded } = useJsApiLoader({
	id: "google-map-script",
	googleMapsApiKey: "YOUR-API-KEY",
  });

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const originRef = useRef();
  const destiantionRef = useRef();

  async function calculateRoute() {
	if (originRef.current.value === "" || destiantionRef.current.value === "") {
	  return;
	}
	const directionsService = new window.google.maps.DirectionsService();
	const results = await directionsService.route({
	  origin: originRef.current.value,
	  destination: destiantionRef.current.value,
	  travelMode: window.google.maps.TravelMode.DRIVING,
	});
	setDirectionsResponse(results);
  }

  function clearRoute() {
	setDirectionsResponse(null);
	originRef.current.value = "";
	destiantionRef.current.value = "";
  }

  return isLoaded ? (
	<>
	  <div className="searchbox">
		<div className="row">
		  <div className="col-lg-4">
			<Autocomplete>
			  <input
				type="text"
				name="Origin"
				className="form-control"
				placeholder="Origin"
				ref={originRef}
			  />
			</Autocomplete>
		  </div>
		  <div className="col-lg-4">
			<Autocomplete>
			  <input
				type="text"
				name="Destication"
				className="form-control"
				placeholder="Destication"
				ref={destiantionRef}
			  />
			</Autocomplete>
		  </div>
		  <div className="col-lg-2">
			<button
			  type="submit"
			  name="submit"
			  className="btn btn-primary"
			  onClick={calculateRoute}
			>
			  Search
			</button>
		  </div>
		  <div className="col-lg-2">
			<button
			  type="submit"
			  name="clear"
			  className="btn btn-danger"
			  onClick={clearRoute}
			>
			  Clear
			</button>
		  </div>
		</div>
	  </div>
	  <GoogleMap
		center={center}
		zoom={5}
		mapContainerStyle={{ width: "100%", height: "100vh" }}
		options={{
		  zoomControl: false,
		  streetViewControl: false,
		  mapTypeControl: false,
		  fullscreenControl: false,
		}}
		onLoad={(map) => setMap(map)}
	  >
		<Marker position={center} />
		{directionsResponse && (
		  <DirectionsRenderer directions={directionsResponse} />
		)}
	  </GoogleMap>
	</>
  ) : (
	<></>
  );
}

export default App;
*/
