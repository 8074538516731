import { createStateContext } from 'react-use';
type keysString = { [key: string]: string };

export type TheRoleInformationContextData = { activeElement: string };
export const [useRoleInformationContext, TheRoleInformationContext] = createStateContext<TheRoleInformationContextData>({ activeElement: '' });

export type OLContextData = { activeElement: string };
export const [useOLContext, OLContext] = createStateContext<OLContextData>({ activeElement: '' });

export type ValidationFields_ContextData = keysString | { [key: string]: string | keysString } | { [key: string]: keysString | { [key: string]: string | keysString } };
export const [useValidationFields, ValidationFieldsContext] = createStateContext<ValidationFields_ContextData>({
	'1': {
		title: '',
		text: '',
		status: {
			id: '',
			name: '',
		},
	},
});

export type TheListItemsRenderContext = { activeElement: string; items: string[] };
export const [useListItemsRenderedContext, TheListItemsRenderContext] = createStateContext<TheListItemsRenderContext>({ activeElement: '', items: [] });
