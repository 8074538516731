import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useTable } from 'react-table';

const HandleWrapper = styled.div`
	height: 1rem;
	vertical-align: bottom;
	display: inline-block;
	margin-right: 0.5rem;
	svg {
		width: 100%;
		height: 100%;
	}
	cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`;

export const DragHandle = props => {
	return (
		<HandleWrapper {...props}>
			<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="grip-vertical" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
				<path
					fill="currentColor"
					fillOpacity="0.1"
					d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
				></path>
			</svg>
		</HandleWrapper>
	);
};

const DraggingRow = styled.td`
	background: rgba(127, 207, 250, 0.3);
`;

const TableData = styled.td`
	background: white;
	&:first-of-type {
		min-width: 20ch;
	}
`;
export const DraggableTableRow = ({ row }) => {
	const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
		id: row.original.id,
	});
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition,
	};
	return (
		<tr ref={setNodeRef} style={style} {...row.getRowProps()}>
			{isDragging ? (
				<DraggingRow colSpan={1}>&nbsp;</DraggingRow>
			) : (
				row.cells.map((cell, i) => {
					if (i === 0) {
						return (
							<TableData key={i} {...cell.getCellProps()}>
								<DragHandle {...attributes} {...listeners} />
								<span>{cell.render('Cell')}</span>
							</TableData>
						);
					}
				})
			)}
		</tr>
	);
};

export function Table({ columns, data, onhandleDragEnd }) {
	const [, setActiveId] = useState();
	const items = useMemo(() => data?.map(({ id }) => id), [data]);
	const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({ columns, data });
	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	function handleDragStart(event) {
		setActiveId(event.active.id);
	}

	function handleDragEnd(event) {
		const { active, over } = event;
		if (active.id !== over.id) {
			const oldIndex = items.indexOf(active.id);
			const newIndex = items.indexOf(over.id);
			const new_data = arrayMove(data, oldIndex, newIndex);
			onhandleDragEnd(new_data);
		}

		setActiveId(null);
	}

	function handleDragCancel() {
		setActiveId(null);
	}

	return (
		<DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragCancel={handleDragCancel} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]}>
			<table {...getTableProps()}>
				<tbody {...getTableBodyProps()}>
					<SortableContext items={items} strategy={verticalListSortingStrategy}>
						{rows.map(row => {
							prepareRow(row);
							return <DraggableTableRow key={row.original.id} row={row} />;
						})}
					</SortableContext>
				</tbody>
			</table>
		</DndContext>
	);
}

export const Styles = styled.div`
	padding: 0rem;
	table {
		border-spacing: 0;
		border: 0px solid black;

		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
		th,
		td {
			margin: 0;
			width: 200rem;
			padding: 0rem;

			:last-child {
				border-right: 0;
			}
		}
	}
`;
