import React from 'react';
import { errorIcon } from '../../../../assets/images/icons';
import cls from './MessageContainer.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const MessageContainer = props => {
	const { message, withError = true, extraClassName, onClick = () => {} } = props;
	return (
		<div onClick={onClick}>
			<p className={classNames(cls.errorMessage, extraClassName)}>{message}</p>
		</div>
	);
};
export default MessageContainer;
