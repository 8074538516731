import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Form from 'react-validation/build/form';
import { Calendar } from '../../../../assets/images/icons';
import { createTask, deleteTask, updateTask, startTask } from '../../../../services/tasks.service';
import { DropdownIndicator, priorityArray } from '../../../../shared/utility';
import { ModalR } from '../../../common';
import Button from '../../../common/UI/Button/Button';
import cls from './TaskForm.module.scss';
import MessageContainer from '../../ProcessesComps/MessageContainer/MessageContainer';
const R = require('ramda');
function TaskForm(props) {
	const navigate = useNavigate();
	const { showPriority = true, selectedProcess, isProcess, statuses, companyUsersArray, task, isEdit, assigneeId, setDisplayModal, begunModule, onClose, user, start_date = new Date().setDate(new Date().getDate()), span = true, setTaskLabels, allModules, onModuleSelect = () => { }, companyData } = props;
	const [newAssignee, setNewAssignee] = useState(task?.assignee?.id ? { assignee_id: task?.assignee?.id } : '');
	const [taskProcess, setTaskProcess] = useState(task?.process?.id ? { process: task?.process?.id } : '');
	const [taskName, setTaskName] = useState(task?.name || '');
	const [taskStatus, setTaskStatus] = useState(task?.status || '');
	const [taskDescription, setTaskDescription] = useState(task?.description || '');
	const [dueDate, setDueDate] = useState(task?.add_info?.end ? new Date(task?.add_info?.end) :task?.due_date ? new Date(task?.due_date) : (new Date().setDate(new Date().getDate()) + (3600 * 1000)));

	const [startDate, setStartDate] = useState(task?.add_info?.start ? new Date(task?.add_info?.start) : start_date);
	const [endDate, setEndDate] = useState(task?.add_info?.end ? new Date(task?.add_info?.end) : start_date);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingRemove, setLoadingRemove] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [toDeleteTask, setToDeleteTask] = useState(false);
	const [isModuleTask, setIsModuleTask] = useState(isProcess);
	const [priority, setPriority] = useState(task?.add_info?.priority || '');
	const usersList =
		companyUsersArray?.length > 0
			? companyUsersArray.map(elem => {
				return { label: elem.first_name + ' ' + elem.last_name, value: elem.id };
			})
			: [];
	useEffect(() => {
		setMounted(true);
		return () => {
			setMounted(false);
		};
	}, []);

	useEffect(() => {
		if (isModuleTask && selectedProcess?.id && mounted) {
			const setInitials = () => {
				setTaskProcess({ procedure_id: selectedProcess.id });
				setTaskDescription(selectedProcess.description);
			};
			setInitials();
		}
	}, [isModuleTask, mounted, selectedProcess.description, selectedProcess.id]);
	const addTask = toStart => {
		setLoading(true);
		createTask(
			begunModule?.processModule
				? {
					newAssignee,
					taskProcess: begunModule?.processModule,
					taskName,
					taskDescription,
					dueDate: moment(endDate).format('YYYY-MM-DD HH:mm'),
					add_info: {
						module_id: begunModule?.processModule.module_id,
						priority: priority,
						start: startDate,
						end: endDate
					},
				}
				: {
					newAssignee,
					taskName,
					taskDescription,
					dueDate: moment(dueDate).format('YYYY-MM-DD HH:mm'),
					add_info: {
						priority: priority ?? '',
					},
				}
		).subscribe({
			next: data => {
				onClose();
			},
			error: error => {
				if (error) {
					setMessage(error.data);
					setLoading(false);
				}
			},
			complete: () => {
				setLoading(false);
				!isModuleTask && setDisplayModal(false);
			},
		});
	};

	const customStyles = {
		control: (provided, _state) => ({
			...provided,
			boxShadow: 'none',
			'&:hover': {
				border: '1px solid var(--app-color-gray-lighter)',
			},
			position: 'relative',
			height: '60px',
			width: '100%',
			border: 'var(--app-color-gray-lighter) solid 1px',
			padding: '3px',
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '1.4em',
			fontWeight: '700',
			color: '#4A4A4A',
			textAlign: 'left',
			flex: '1 1 auto',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			alignSelf: 'center',
			margin: '10px 0 0 0 ',
			marginBottom: '20px',
		}),
		input: (provided, _state) => ({
			...provided,
			fontFamily: 'var(--app-text-main-font)',
			color: '#4A4A4A',
			width: '100%',
			textAlign: 'left',
			fontSize: '0.8em',
			fontWeight: '700',
			alignSelf: 'center',
		}),
		menu: (provided, _state) => ({
			...provided,
			justifyContent: 'center',
			alignItems: 'center',
			verticalTextAlign: 'center',
			alignContent: 'center',
		}),
		menulist: (provided, _state) => ({
			...provided,
			justifyContent: 'center',
			alignItems: 'center',
			verticalTextAlign: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
		}),
		option: (provided, _state) => ({
			...provided,
			minHeight: 15,
			paddingTop: 0,
			fontSize: '1.1em',
			color: 'black',
			verticalTextAlign: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
		}),
		placeholder: (provided, _state) => ({
			...provided,
			fontSize: '0.75em',
			lineHeight: '20px',
			color: '#4A4A4A',
			fontWeight: 400,
			overflow: 'hidden',
			fontFamily: 'var(--app-text-main-font)',
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return {
				...provided,
				opacity,
				transition,
				marginLeft: '0px',
				fontSize: '0.75em',
				lineHeight: '20px',
				color: '#4A4A4A',
				fontFamily: 'var(--app-text-main-font)',
			};
		},
		indicatorsContainer: (styles, { _data }) => ({
			...styles,
			marginTop: 'auto',
			marginBottom: 'auto',
			color: '#4A4A4A',
			size: 7,
			':hover': { color: 'gray' },
			width: '65px',
			height: '45px',
		}),
	};

	const taskEdit = () => {
		setLoading(true);
		let assignee_id = newAssignee ? newAssignee.assignee_id : assigneeId;
		updateTask(
			{
				assignee_id,
				name: taskName,
				description: taskDescription,
				due_date: moment(dueDate).format('YYYY-MM-DD HH:mm'),
				status: taskStatus ?? '',
				add_info: { ...props?.task?.add_info, priority: priority ?? '', start: startDate, end: endDate },
			},
			props?.task?.id
		).subscribe({
			next: _data => {
				setLoading(false);
				setDisplayModal(false);
			},
			error: error => {
				if (error) {
					setMessage(error.data);
					setLoading(false);
				}
			},
			complete: () => { },
		});
	};
	const removeTask = taskId => {
		setLoadingRemove(true);
		deleteTask(taskId).subscribe({
			next: _data => {
				setLoadingRemove(false);
				setToDeleteTask(false);
			},
			error: _error => {
				setLoadingRemove(false);
			},
			complete: () => {
				setDisplayModal(false);
			},
		});
	};

	const statusArray = statuses ? statuses.map(stat => {
		return { label: stat, value: stat }
	}) : [
		{ label: 'Done', value: 'Done' },
		{ label: 'Ongoing', value: 'Ongoing' },
		{ label: 'In Review', value: 'In Review' },
		{ label: 'Approved', value: 'Approved' },
		{ label: 'Postponed', value: 'Postponed' },
		{ label: 'Moved', value: 'Moved' },
		{ label: 'Cancelled', value: 'Cancelled' },
	];

	return (
		<div className={cls.newTaskFormHost}>
			<Form style={{ flex: 1 }}>
				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.name ? 'var(--app-color-error)' : '', display: task?.completed_at ? '' : '' }}>
						<p>
							{'Name'}
						</p>
						<textarea readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true} required={message?.errors?.name} onFocus={() => setMessage('')} value={taskName} onChange={e => setTaskName(e.target.value)} />
					</label>

					{message && message?.errors?.name && <MessageContainer message={message.errors.name} />}
				</div>
				{isProcess ? <div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.due_date ? 'var(--app-color-error)' : '' }}>
						{'Start Date'}
						<div>
							<DatePicker
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								disabled={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
								placeholderText="Start Date"
								customInput={
									<div
										id={'custom-input'}
										style={{
											cursor: 'pointer',
											display: 'flex',
											justifyContent: 'center',
											width: '100%',
											alignItems: 'stretch',

											border: '1px solid',
											borderColor: 'var(--app-color-gray-lighter)',
										}}
									>
										<input
											disabled
											readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
											onFocus={() => setMessage('')}
											value={moment(startDate).format('DD MMMM YYYY HH:mm')}
											type="text"
											required={message?.errors?.startDate}
											id={'start-date'}
											style={{
												flex: 1,
												marginTop: 'auto',
												marginBottom: 'auto',
												outline: 'none',
												border: 'none',
												fontFamily: 'var(--app-text-main-font)',
												fontSize: '16px',
												lineHeight: '20px',
												color: '#4A4A4A',
												textAlign: startDate?.length > 0 ? 'center' : 'left',
												maxWidth: '98%',
											}}
											placeholder={'Start Date'}
											onChange={_e => { }}
										/>
										<Calendar className={cls.calendarSVG} />
									</div>
								}
								className={cls.datePickerWrapper}
								selected={startDate}
								onChange={e => {
									if (moment(e).diff(moment(endDate), 'minutes') < 1) {
										setStartDate(e);
									}
								}}
							/>
						</div>
					</label>
					<br />
					<label style={{ color: message?.errors?.due_date ? 'var(--app-color-error)' : '' }}>
						{'End Date'}
						<div>
							<DatePicker
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								disabled={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
								placeholderText="End Date"
								customInput={
									<div
										id={'custom-input'}
										style={{
											cursor: 'pointer',
											display: 'flex',
											justifyContent: 'center',
											width: '100%',
											alignItems: 'stretch',

											border: '1px solid',
											borderColor: message?.errors?.endDate ? 'var(--app-color-error)' : 'var(--app-color-gray-lighter)',
										}}
									>
										<input
											disabled
											readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
											onFocus={() => setMessage('')}
											value={moment(endDate).format('DD MMMM YYYY HH:mm')}
											type="text"
											required={message?.errors?.endDate}
											id={'due-date'}
											style={{
												flex: 1,
												marginTop: 'auto',
												marginBottom: 'auto',
												outline: 'none',
												border: 'none',
												fontFamily: 'var(--app-text-main-font)',
												fontSize: '16px',
												lineHeight: '20px',
												color: '#4A4A4A',
												textAlign: endDate?.length > 0 ? 'center' : 'left',
												maxWidth: '98%',
											}}
											placeholder={'Due Date'}
											onChange={_e => { }}
										/>
										<Calendar className={cls.calendarSVG} />
									</div>
								}
								className={cls.datePickerWrapper}
								selected={endDate}
								onChange={e => {

									if (moment(e).diff(moment(startDate), 'minutes') > 0) {
										if (span === false) {
											if (moment(e).format('DD') === moment(endDate).format('DD')) {
												setEndDate(e);
											} else {
												setEndDate(endDate);
											}
										} else {
											setEndDate(e);
										}
									} else {
										setEndDate(endDate);
									}
								}
								}
							/>
						</div>
					</label>
					{message && message?.errors?.due_date && <MessageContainer message={message.errors.endDate} />}
				</div>
					:
					<div style={{ marginBottom: '10px' }}>
						<label style={{ color: message?.errors?.due_date ? 'var(--app-color-error)' : '' }}>

							{'Due Date'}

							<div style={{ marginTop: '10px' }}>
								<DatePicker
									disabled={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
									placeholderText="Due Date"
									customInput={
										<div
											id={'custom-input'}
											style={{
												cursor: 'pointer',
												display: 'flex',
												justifyContent: 'center',
												width: '100%',
												alignItems: 'stretch',

												border: '1px solid',
												borderColor: message?.errors?.due_date ? 'var(--app-color-error)' : 'var(--app-color-gray-lighter)',
											}}
										>
											<input
												readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
												onFocus={() => setMessage('')}
												value={moment(dueDate).format('DD MMMM YYYY HH:mm')}
												type="text"
												required={message?.errors?.due_date}
												id={'due-date'}
												style={{
													flex: 1,
													marginTop: 'auto',
													marginBottom: 'auto',
													outline: 'none',
													border: 'none',
													fontFamily: 'var(--app-text-main-font)',
													fontSize: '16px',
													lineHeight: '20px',
													color: '#4A4A4A',
													textAlign: dueDate?.length > 0 ? 'center' : 'left',
													maxWidth: '98%',
												}}
												placeholder={'Due Date'}
												onChange={_e => { }}
											/>
											<Calendar className={cls.calendarSVG} />
										</div>
									}
									className={cls.datePickerWrapper}
									selected={dueDate}
									onChange={e => {
										setDueDate(e);
									}}
								/>
							</div>
						</label>
						{message && message?.errors?.due_date && <MessageContainer message={message.errors.due_date} />}
					</div>}

				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.description ? 'var(--app-color-error)' : '' }}>
						{'Description'}
						<textarea style={{ marginTop: '10px' }} disabled={task?.completed_at ? true : false} onFocus={() => setMessage('')} required={message?.errors?.description} value={taskDescription} onChange={e => setTaskDescription(e.target.value)} />
					</label>
					{message && message?.errors?.description && <MessageContainer message={message.errors.description} />}
				</div>
				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.assignee_id ? 'var(--app-color-error)' : '' }}>
						{'Assignee'}
						{task?.completed_at ? (
							<div>
								<input style={{ cursor: 'auto' }} placeholder={task?.assignee.first_name + ' ' + task?.assignee.last_name} readOnly />
							</div>
						) : (
							<Select
								onFocus={() => setMessage('')}
								isSearchable={false}
								key={'Assignee'}
								styles={customStyles}
								defaultInputValue={''}
								components={{ DropdownIndicator }}
								placeholder=""
								value={usersList[usersList.findIndex(c => c.value === newAssignee.assignee_id)]}
								options={usersList.concat( [{label: 'None', value:''}])}
								onChange={position => {
									if (position) {
										setNewAssignee(c => ({ ...c, assignee_id: position.value }));
									}
								}}
							/>
						)}
					</label>
					{message && message?.errors?.assignee_id && <MessageContainer message={message.errors.assignee_id} />}
				</div>
				{props?.task?.creator && (
					<div style={{ marginBottom: '10px' }}>
						<label>
							<p>
								{'Creator'}
							</p>
							<input style={{ cursor: 'auto' }} placeholder={task?.creator.first_name + ' ' + task?.creator.last_name} readOnly />
						</label>

						<label >
							<p>
								{'Created Date'}
							</p>
							<input style={{ cursor: 'auto' }} placeholder={moment(task?.created_at).format('DD MMMM YYYY')} readOnly />
						</label>
					</div>
				)}
				<div style={{ marginBottom: '10px' }}>
					{showPriority && <label style={{ color: message?.errors?.procedure_id ? 'var(--app-color-error)' : '' }}>
						<p>
							{'Priority'}
						</p>
						{task?.completed_at ? (
							<input style={{ cursor: 'auto' }} placeholder={task?.priority ?? 'Medium'} readOnly />
						) : (
							<Select
								onFocus={() => setMessage('')}
								key={'priority'}
								styles={customStyles}
								defaultInputValue={''}
								components={{ DropdownIndicator }}
								placeholder={''}
								value={priorityArray.find(s => s.value === priority)}
								options={priorityArray}
								onChange={e => setPriority(e.value)}
							/>
						)}
					</label>}
					<label style={{ color: message?.errors?.procedure_id ? 'var(--app-color-error)' : '' }}>
						<p>
							{'Status'}
						</p>
						{task?.completed_at ? (
							<input style={{ cursor: 'auto' }} placeholder={task?.status ?? 'No status set'} readOnly />
						) : (
							<Select
								onFocus={() => setMessage('')}
								key={'status'}
								styles={customStyles}
								defaultInputValue={''}
								components={{ DropdownIndicator }}
								placeholder={''}
								value={statusArray.find(s => s.value === taskStatus)}
								options={user.id === task?.creator?.id ? [...statusArray, { label: companyData?.misc_info?.lock, value: companyData?.misc_info?.lock }, {label: 'No status', value: ''}] : statusArray}
								onChange={e => setTaskStatus(e.value)}
							/>
						)}
					</label>

					{message && message?.errors?.procedure_id && <MessageContainer message={message.errors.procedure_id} />}
				</div>


				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.procedure_id ? 'var(--app-color-error)' : '' }}>
						<p>
							{'Module'}
						</p>
						{(task?.add_info?.module_id || task?.completed_at) ? (
							<input style={{ cursor: 'auto' }} placeholder={allModules.find(mod => mod.id === task?.add_info?.module_id)?.name} readOnly />
						) : (
							<Select
								onFocus={() => setMessage('')}
								key={'module'}
								styles={customStyles}
								components={{ DropdownIndicator }}
								placeholder={''}
								options={allModules.map(mod => {
									return { label: mod.name, value: mod.id, module: mod }
								})}
								onChange={e => {
									begunModule.processModule = { ...e.module.procedure, module_id: e.value }
									setTaskLabels({valueL: 'Value'});
									onModuleSelect(e.module);
								}}
							/>
						)}
					</label>

					{message && message?.errors?.procedure_id && <MessageContainer message={message.errors.procedure_id} />}
				</div>


				{message && message?.message && <MessageContainer message={message.message} />}
			</Form>
			{task?.completed_at ? (
				<div className={cls.buttonsContainer}>
					<Button clicked={onClose} className={cls.saveButton} title={'Close'} />
				</div>
			) : !isEdit ? (
				<div className={cls.buttonsContainer}>
					<Button clicked={onClose} className={cls.saveButton} title={'Close'} />
					<Button light clicked={() => addTask(false)} className={cls.saveButton} title={'Save'} loading={loading} />

				</div>
			) : (
				<div className={cls.buttonsContainer}>
					<Button clicked={onClose} className={cls.saveButton} title={'Close'} />
					<Button light clicked={() => setToDeleteTask(true)} className={cls.saveButton} title={'Delete'} />
					<Button light clicked={() => taskEdit()} className={cls.saveButton} title={'Update'} loading={loading} />

				</div>
			)}

			{toDeleteTask && <DeleteTaskModal taskId={task.id} isOpen={toDeleteTask} removeTask={removeTask} loading={loadingRemove} onClose={setToDeleteTask} />}
		</div>
	);
}

const DeleteTaskModal = props => {
	const { isOpen, onClose, loading, removeTask, taskId } = props;
	return (
		<ModalR isOpen={isOpen} onClose={() => onClose(false)} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: '35%' }}>
			<div>
				<p
					style={{
						textAlign: 'center',
						fontSize: '20px',
						color: '#4A4A4A',
						fontFamily: 'var(--app-text-main-font)',
					}}
				>
					{'Are you sure do you want to delete this task?'}
				</p>
				<Button loading={loading} clicked={() => removeTask(taskId)} title={'Yes, Delete'} style={{ fontWeight: '500', marginLeft: 'auto', marginRight: 'auto' }} />
				<p
					style={{
						cursor: 'pointer',
						textAlign: 'center',
						fontSize: '16px',
						fontWeight: 600,
						color: '#4A4A4A',
						fontFamily: 'var(--app-text-main-font)',
					}}
					onClick={() => onClose(false)}
					className={'go-back-text'}
				>
					{'No, Go Back'}
				</p>
			</div>
		</ModalR>
	);
};

const mapStateToProps = store => {
	const { selectedProcess, companyUsersArray, companyProcesses, allModules, begunModule, companyData } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { selectedProcess, companyUsersArray, companyProcesses, allModules, user, begunModule, companyData };
};

export default connect(mapStateToProps)(TaskForm);
