import {
	ERRORS_MODAL_CLOSE,
	ERRORS_MODAL_OPEN,
	INFO_MODAL_CLOSE,
	INFO_MODAL_OPEN,
	PREVIEW_CHART_MODAL_CLOSE,
	PREVIEW_CHART_MODAL_OPEN,
	SET_IS_ONBOARDING,
	SET_ONBOARDING_MODALS_VISIBILITY,
	SET_ONBOARDING_VISIBLE,
	SET_PAGED_MODAL_CLOSE,
	SET_PAGED_MODAL_OPEN,
	SET_USER_OVERVIEW_MODAL_CLOSE,
	SET_USER_OVERVIEW_MODAL_OPEN,
	TUTORIAL_MODAL_CLOSE,
	TUTORIAL_MODAL_OPEN,
	RESET_MODALS_REDUCER,
} from '../actions/types';
import { appDefaultReducer } from './defaultReducer';

const INITIAL_STATE = appDefaultReducer.modalsReducer;

export default function modalsReducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_ONBOARDING: {
			return { ...state, isOnboarding: action.payload };
		}

		case SET_ONBOARDING_VISIBLE:
			return { ...state, onBoardingVisible: payload };
		case SET_ONBOARDING_MODALS_VISIBILITY:
			return { ...state, onBoardingModalsVisibility: { ...INITIAL_STATE.onBoardingModalsVisibility, ...payload } };

		case INFO_MODAL_OPEN: {
			return { ...state, infoModal: { ...state.infoModal, isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case INFO_MODAL_CLOSE: {
			return { ...state, infoModal: { ...state.infoModal, isOpen: action.payload.isOpen, options: action.payload.options } };
		}

		case ERRORS_MODAL_OPEN: {
			return { ...state, errorsModal: { ...state.errorsModal, isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case ERRORS_MODAL_CLOSE: {
			return { ...state, errorsModal: { ...state.errorsModal, isOpen: action.payload.isOpen, options: action.payload.options } };
		}

		case PREVIEW_CHART_MODAL_OPEN: {
			return { ...state, previewChartModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case PREVIEW_CHART_MODAL_CLOSE: {
			return { ...state, previewChartModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}

		case TUTORIAL_MODAL_OPEN: {
			return { ...state, tutorialModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case TUTORIAL_MODAL_CLOSE: {
			return { ...state, tutorialModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}

		case SET_PAGED_MODAL_OPEN: {
			return { ...state, pagedModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case SET_PAGED_MODAL_CLOSE: {
			return { ...state, pagedModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}

		case SET_USER_OVERVIEW_MODAL_OPEN: {
			return { ...state, userOverviewModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case SET_USER_OVERVIEW_MODAL_CLOSE: {
			return { ...state, userOverviewModal: { isOpen: action.payload.isOpen, options: action.payload.options } };
		}
		case RESET_MODALS_REDUCER:
			return INITIAL_STATE;
		default:
			return state;
	}
}
