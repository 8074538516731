import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import cls from './ModalR.module.scss';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark, faX } from '@fortawesome/free-solid-svg-icons';
export default function ModalR(props) {
	const { isOpen, onClose, onContentPress, id, hasCloseIcon, hasCloseButton, shadowedContainerClassname } = props;
	const close = () => {
		onClose && onClose();
	};
	return (
		<>
			<DialogOverlay className={cls.transparent} isOpen={isOpen} onDismiss={close} id={id} style={{ ...props.modalWrapperStyle }}>
				<DialogContent onClick={onContentPress} className={classNames(cls.host, shadowedContainerClassname)} app-variant-opened={isOpen ? 'true' : 'false'} style={{ ...props.shadowedContainerStyle }} aria-label="Warning">
					{hasCloseButton && (
						<div className={cls.closeContainer}>
							<Button title={'Close'} style={{ width: '80px' }} clicked={close} />
						</div>
					)}
					{hasCloseIcon && (
						<div className={cls.closeContainer}>
							<Button clear style={{ marginTop: '20px', width: '40px' }} clicked={close}>
								<FontAwesomeIcon icon={faRectangleXmark} style={{ color: 'var(--app-color-gray)', width: '40px', height: '30px' }} />
							</Button>
						</div>
					)}
					{props.children}
				</DialogContent>
			</DialogOverlay>
		</>
	);
}
