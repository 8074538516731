import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { first } from 'rxjs/operators';
import { getProcessById, getStepsByProcessId } from '../../../../services/processes.service';

import { setSelectedProStep } from '../../../../reduxStore/actions/flowActions';
import cls from './ProcessStepsChart.module.scss';
import { generateNewUUID } from '../../../../shared/utility';
import { useProcessContext, pat } from '../../../../context/processStep.context';
import { useMountedState } from 'react-use';
import { Button, Spinner } from '../../../common';
import * as R from 'ramda';

import { Tree, TreeNode } from 'react-organizational-chart';
import ReactFlow, { ReactFlowProvider, Controls, Background, addEdge, removeElements, ArrowHeadType } from 'react-flow-renderer';

import 'react-flow-renderer/dist/style.css';
import './styles.css';

import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramNext, faDiagramProject } from '@fortawesome/free-solid-svg-icons';

const initialElements = [];

const generateNodesFromValueChain = valuChain => {
	const elements = [];
	const valueChainSize = Object.keys(valuChain).length;
	const firstNodeYPosition = window.innerHeight / (valueChainSize + 1);
	const firstNodeXPosition = window.innerWidth / 3;

	let positionIncreamentCount = 1;

	for (const valuChainItem in valuChain) {
		if (Object.hasOwnProperty.call(valuChain, valuChainItem)) {
			const element = valuChain[valuChainItem];
			const position = {
				y: element.step?.options?.layout?.y ? element.step?.options?.layout?.y : positionIncreamentCount == 1 ? 0 : firstNodeYPosition * positionIncreamentCount - 1,
				x: element.step?.options?.layout?.x ? element.step?.options?.layout?.x : element.step?.parent_steps?.length > 1 ? firstNodeXPosition + 400 : firstNodeXPosition,
			};
			positionIncreamentCount++;
			elements.push({
				id: valuChainItem,
				type: element.step.is_start == true ? 'output' : element.step.is_end == true ? 'input' : element.childOf ? 'default' : 'input',
				data: { label: element.label, step: element.step },
				position,
				sourcePosition: 'top',
				targetPosition: 'bottom',
			});
		}
	}
	return elements;
};

const generateEdgesFromValueChain = valuChain => {
	const elements = [];
	for (const valuChainItem in valuChain) {
		if (Object.hasOwnProperty.call(valuChain, valuChainItem)) {
			const element = valuChain[valuChainItem];
			if (element.childOf) {
				element.childOf.filter(child => {
					elements.push({
						id: `edge-${valuChainItem}-${child.id}`,
						source: valuChainItem,
						target: child.id,
						type: 'smoothstep',
						label: `${child.name} ⮕ ${element.step.name}`,
						//arrowHeadType: ArrowHeadType.ArrowClosed
					});
				});
			}
		}
	}
	return elements;
};

let id = 0;
const getId = () => `dndnode_${id++}`;

const ProcessStepsChart = props => {
	const isMounted = useMountedState();
	const { selectedProcess, onEditStep, companyProcesses, workflowTemplates, updateParent = () => { }, edit = false, updateStep = () => { } } = props;

	const [procContext, setInProcContext] = useProcessContext();
	const [loading, setLoading] = useState(false);
	const [onEdit, setOnEdit] = useState(edit);
	const [paneMoveable] = useState(false);
	const [panOnScroll] = useState(true);
	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [elements, setElements] = useState(initialElements);

	useEffect(() => {
		const values = R.mergeAll(procContext?.steps
			.concat((procContext?.steps?.length == 0 || procContext?.steps?.filter(st => st.is_end === true).length > 0) ? [] : [{
				name: '',
				id: 'New',
				new: true,
				rank: 0,
				parent_steps: [procContext?.steps.length == 0 ? [] : procContext?.steps[procContext?.steps?.length - 1]]
			}])
			.map(step => {
				return {
					[step?.id ?? 'New']: {
						label:
							step.name === '' ? (
								'New Step'
							) : (
								<div>
									<div>{`${step.is_start ? '(Start)' : ''} `}</div>
									<div style={{ fontSize: '16px' }}>{`${step.name}`}</div>
									<div>{`${step.is_end ? '(End)' : ''} `}</div>
								</div>
							),
						childrenOf: step.parent_steps.length > 0 ? step.parent_steps[0].id : null,
						childOf: step?.parent_steps ?? [],
						step: step,
					},
				};
			})
		);
		setElements([...generateNodesFromValueChain(values), ...generateEdgesFromValueChain(values)]);
	}, [procContext?.steps]);

	const onLoad = _reactFlowInstance => {
		setReactFlowInstance(_reactFlowInstance);
		setTimeout(_reactFlowInstance.fitView);
	};

	const onConnect = params => {
		if (new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/).test(params.source) || new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/).test(params.target)) {
			const step = procContext.steps.find(step => step.id === params.target);
			if (step) {
				if (step.is_end == true) {
					return;
				}
			}

			updateParent(params.source, params.target, true);
			setElements(els => addEdge(params, els));
		}
	};

	const onElementsRemove = elementsToRemove => {
		if (elementsToRemove?.length > 0) {
			if (elementsToRemove[0]?.id.includes('edge')) {
				if (new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/).test(elementsToRemove[0].source) && new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/).test(elementsToRemove[0].target)) {
					updateParent(elementsToRemove[0].source, elementsToRemove[0].target, false);
				}
			} else {
				const step2remove = elementsToRemove.find(elem => !elem.id.includes('edge'));
				if (step2remove) {
					setInProcContext({
						type: pat.setSelectedStep,
						value: {
							...step2remove.data.step,
							parent_steps: R.map(R.pick(['id', 'name', 'rank']))(step2remove?.data.step.parent_steps ?? []),
						},
					});
					onEditStep();
				}
			}
		}
		setElements(els => removeElements(elementsToRemove, els));
	};

	const onNodeDoubleClick = (event, node) => {
		if (node?.data?.step?.new == true) {
			setInProcContext({ type: pat.addNewStep, value: selectedProcess.id });
		} else {
			setInProcContext({
				type: pat.setSelectedStep,
				value: {
					...node.data.step,
					parent_steps: R.map(R.pick(['id', 'name', 'rank']))(node?.data?.step?.parent_steps ?? []),
					layout: node?.position,
				},
			});
		}
		onEditStep();
	};

	const onConnectBegin = (event, node) => {
		return;
	};

	const onNodeDragStop = (event, node) => {
		setInProcContext({
			type: pat.setSelectedStep,
			value: {
				...node.step,
				layout: node?.position,
			},
		});

		updateStep({
			...node?.data?.step,
			options: { ...node?.data?.step?.options, layout: node.position },
		});
	};
	const onDragOver = event => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	};

	const onDrop = event => {
		event.preventDefault();
		const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
		const type = event.dataTransfer.getData('application/reactflow');
		const position = reactFlowInstance.project({
			x: event.clientX - reactFlowBounds.left,
			y: event.clientY - reactFlowBounds.top,
		});
		const newNode = {
			id: getId(),
			type,
			position,
			sourcePosition: 'top',
			targetPosition: 'bottom',
			data: { label: `New Step ${type}`, step: { name: '', new: true } },
		};
		setInProcContext({ type: pat.addNewStep, value: selectedProcess.id });
		setElements(es => es.concat(newNode));
		onEditStep();
	};

	useEffect(() => {
		if (selectedProcess.name !== '' && selectedProcess.id !== '') {
			let found = companyProcesses.find(process => process.id === selectedProcess.id);
			if (!found) {
				found = workflowTemplates.find(process => process.id === selectedProcess.id);
			}
			if (found && found?.steps.length > 0) {
				const addUUID = obj => R.assoc('_uuid', generateNewUUID(), obj);
				const updated = R.compose(R.sortBy(R.eqProps('rank')), R.map(R.over(R.lensProp('component_blueprints'), R.map(addUUID))))(found.steps);
				setInProcContext({
					type: pat.setAllSteps,
					value: updated,
				});
			} else {
				setLoading(true);
				getProcessById(selectedProcess.id)
					.pipe(first())
					.subscribe({
						next: data => {
							if (data?.steps?.length > 0) {
								const addUUID = obj => R.assoc('_uuid', generateNewUUID(), obj);
								const updated = R.compose(R.sortBy(R.eqProps('rank')), R.map(R.over(R.lensProp('component_blueprints'), R.map(addUUID))))(data.steps);
								setInProcContext({
									type: pat.setAllSteps,
									value: updated,
								});

								isMounted() && setLoading(false);
							} else {
								setLoading(false);
							}
						},
						error: _error => {
							isMounted() && setLoading(false);
						},
					});
			}
		}
	}, [selectedProcess]);

	const Tree_Component = props => {
		const { step } = props;
		return (
			<TreeNode
				label={
					<div
						className={cls.target}
						style={{ display: '' }}
						onClick={() => {
							setInProcContext({
								type: pat.setSelectedStep,
								value: {
									...step,
									parent_steps: R.map(R.pick(['id', 'name', 'rank']))(step?.parent_steps ?? []),
								},
							});
							onEditStep();
						}}
					>
						<div className={cls.startContainer}>
							<div className={cls.step} app-card-type="start">
								<p className={cls.paragraph}>{step?.name}</p>
							</div>
						</div>
					</div>
				}
			>
				{step?.next_steps && step?.next_steps?.map((ch, index) => <Tree_Component key={index} step={procContext?.steps.find(s => s.id === ch.id)} />)}
				{step?.pathway_steps && step?.pathway_steps?.map((ch, index) => <TreeNode key={index} label={<div className={cls.tree}>{ch.name}</div>} />)}
				{step?.pathway_processes &&
					step?.pathway_processes?.map((ph, index) => (
						<TreeNode
							key={index}
							label={
								<div
									className={cls.tree}
									onClick={() => {
										setLoading(true);
										getStepsByProcessId({ processId: ph.id })
											.pipe(first())
											.subscribe({
												next: data => {
													const addUUID = obj => R.assoc('_uuid', generateNewUUID(), obj);
													const updated = R.compose(R.sortBy(R.eqProps('rank')), R.map(R.over(R.lensProp('component_blueprints'), R.map(addUUID))))(data);
													setInProcContext({
														type: pat.setAllSteps,
														value: updated,
													});
													isMounted() && setLoading(false);
												},
												error: _error => {
													isMounted() && setLoading(false);
												},
											});
									}}
								>
									{`Process: ${ph.name}`}
								</div>
							}
						/>
					))}
				{step?.is_end === true && procContext?.steps.filter(s => s.rank !== 1 && s?.parent_steps?.length == 0).length == 0 && <TreeNode label={<div className={cls.tree}>End Step</div>} />}
			</TreeNode>
		);
	};
	//console.log('selectedProcess', selectedProcess, procContext, loading);
	return (
		<div>
			{loading && selectedProcess.name !== '' && selectedProcess.id !== '' && (
				<div className={cls.placeholderContainer} style={{ display: '' }}>
					{<Spinner size={80} type={'ThreeDots'} />}
				</div>
			)}

			{onEdit && (
				<div className="dndflow">
					<ReactFlowProvider>
						<div className="reactflow-wrapper" ref={reactFlowWrapper}>
							<ReactFlow
								elements={elements}
								nodesDraggable={false}
								onConnectStart={onConnectBegin}
								onConnect={onConnect}
								onElementsRemove={onElementsRemove}
								onLoad={onLoad}
								onDrop={onDrop}
								onDragOver={onDragOver}
								paneMoveable={paneMoveable}
								panOnScroll={panOnScroll}
								snapToGrid={true}
								onNodeDragStop={onNodeDragStop}
								onNodeDoubleClick={onNodeDoubleClick}
							>
								<Controls />
								<Background variant="lines" />
							</ReactFlow>
						</div>
						<Sidebar title={selectedProcess.name} subtitle={selectedProcess.description} />
					</ReactFlowProvider>
				</div>
			)}

			<div className={cls.host} app-is-preview={'false'} style={{ display: procContext?.steps.length > 0 && !onEdit ? '' : 'none' }}>
				{!loading && procContext?.steps.length > 0 && (
					<div>
						<Tree
							lineWidth={'2px'}
							lineColor={'green'}
							lineBorderRadius={'10px'}
							label={
								<div className={cls.tree} style={{ fontSize: '26px', padding: '30px 10px', display: procContext?.steps.length > 0 ? '' : 'none', maxWidth: '50%' }}>
									{selectedProcess.name}
									<p>{selectedProcess.description}</p>
								</div>
							}
						>
							{procContext?.steps.length > 0 && <Tree_Component step={procContext?.steps.find(s => s.rank === 1)} />}
						</Tree>
						<Tree lineWidth={'0px'} lineColor={'transparent'}>
							{procContext?.steps.length > 0 && procContext?.steps.filter(s => s.rank !== 1 && s?.parent_steps?.length == 0).length > 0 && <Tree_Component step={procContext?.steps.find(s => s.rank !== 1 && s?.parent_steps?.length == 0)} />}
						</Tree>
					</div>
				)}
			</div>
			{procContext?.steps.length > 0 > 0 && onEditStep && (
				<div style={{ float: 'right' }}>
					<Button
						icon={<FontAwesomeIcon style={{ width: '20px', height: '20px' }} icon={onEdit ? faDiagramNext : faDiagramProject} />}
						light
						title={onEdit ? 'Chart' : 'Builder'}
						clicked={() => {
							setOnEdit(!onEdit);
						}}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = store => {
	const { selectedProcess, companyProcesses, workflowTemplates } = store.flowReducer;
	return { selectedProcess, companyProcesses, workflowTemplates };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetSelectedProStep: selectedStep => dispatch(setSelectedProStep(selectedStep)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcessStepsChart);
