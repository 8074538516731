import React from 'react';
import ReactPlayer from 'react-player';
import cls from './TutorialRectangle.scss';

const TutorialRectangle = props => {
	const { onClick, url } = props;
	return (
		<div onClick={onClick} className={cls.tutorialRectangle}>
			<ReactPlayer width="70%" url={url} style={{ minHeight: '550px', borderRadius: '16px', overflow: 'hidden' }} />
		</div>
	);
};

export default TutorialRectangle;
