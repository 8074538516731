import { useEffect, useRef } from 'react';

const UnfocusableWrapper = props => {
	const { onClickOutside, className, children, ...rest } = props;
	let ref = useRef(null);

	const handleClickOutside = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			onClickOutside && onClickOutside();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});
	return (
		<div ref={ref} className={className} {...rest} tag={'UnfocusableWrapper'}>
			{children}
		</div>
	);
};
export default UnfocusableWrapper;
