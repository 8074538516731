var qsSimple = require('qs');
import { Observable } from 'rxjs';
import { setCompanyData, setCompanyDepartments, setCompanyOrgChartData, setCompanyPositionsArray, setPositionInfo, setMessageBar, setAllModules, setCompanyProcesses, setLastUpdate } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import moment, { now } from 'moment';


import * as R from 'ramda';
import { APPEND_TO_DATA } from '../shared/utility';


export const createNewCompany = companyData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('name', companyData.companyName);
	data.append('display_name', companyData.displayName);
	data.append('slug', companyData.slug);
	data.append('email', companyData.email);
	data.append('first_name', companyData.firstName);
	data.append('last_name', companyData.lastName);

	const defaultConfig = { ...URLS.company.createNewCompany(last_access_token) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const createOpenUserInvite = userData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('position_id', userData.position_id);
	data.append('email', userData.email);
	data.append('first_name', userData.firstName);
	data.append('last_name', userData.lastName);

	const defaultConfig = { ...URLS.company.createUserOpenInvite(last_access_token, userData.company_id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				console.log('received', response);
				if (response?.status == 201 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getCompanyDetails = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const { lastUpdates } = store.getState().flowReducer;
	const config = {
		...URLS.company.getCompanyDetails(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					if (response?.data?.data?.modules){
						store.dispatch(setAllModules(response?.data?.data?.modules));
						delete(response?.data?.data?.modules);
					}
					if (response?.data?.data?.procedures){
						
						const ascendingProcesses = response?.data?.data?.procedures.length > 0 ? R.sortBy(R.prop('name'), response?.data?.data?.procedures) : [];
						store.dispatch(setCompanyProcesses(ascendingProcesses, APPEND_TO_DATA));
						store.dispatch(setLastUpdate({ ...lastUpdates, procedures: now() }));
						delete(response?.data?.data?.procedures);
					}
					store.dispatch(setCompanyData(response?.data?.data));
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getCompanyDetailsUsingSlug = slug => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.company.getCompanyDetailsUsingSlug(last_access_token, slug),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setCompanyData(response?.data?.data));
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getAllCompanyPositionsAsArray = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	//const { axiosAbort} = store.getState().flowReducer;

	const config = {
		...URLS.positions.getAllPositionsAsArray(last_access_token),
		handlerEnabled: true,
		//signal: axiosAbort.signal
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					const posi = [...(response?.data?.data?.map(i => ({ label: i.name, value: i.id, department: i?.department?.name ?? 'Board' })) ?? [])].sort((a, b) => (a?.label?.toUpperCase() < b?.label?.toUpperCase() ? -1 : a?.label?.toUpperCase() > b?.label?.toUpperCase() ? 1 : 0));
					store.dispatch(setCompanyPositionsArray(posi));
					subscriber.next(posi);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const setCompanyLogo = logoFile => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('logo', logoFile);
	const config = {
		...URLS.company.updateCompanyLogo(last_access_token),
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					store.dispatch(setCompanyData({ logo: (response?.data?.data?.logo + '?t=' + new Date().getTime()).toString() }));
					subscriber.next(response.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: 'You have successfully set a company logo' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const setCompanyDetails = companyData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = qsSimple.stringify(companyData);
	const config = {
		...URLS.company.updateCompanyDetails(last_access_token),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					store.dispatch(setMessageBar({ show: true, message: 'Update successful' }));
					store.dispatch(setCompanyData(response?.data?.data));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const updateCompanyDetailsById = companyData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	
	
	const data = {
		trial_ended_at: companyData?.trial_ended_at,
		status: companyData?.status,
		misc_info: JSON.stringify(companyData?.misc_info),
		core_values: JSON.stringify(companyData?.core_values),
		company_standards: JSON.stringify(companyData?.company_standards),
	};

	const config = {
		...URLS.company.updateCompanyById(last_access_token, companyData?.id),
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					store.dispatch(setMessageBar({ show: true, message: 'Update successful' }));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const setCompanyOptions = companyData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = qsSimple.stringify(companyData);
	const config = {
		...URLS.company.updateCompanyOptions(last_access_token),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					store.dispatch(setMessageBar({ show: true, message: 'Update successful' }));
					store.dispatch(setCompanyData(response?.data?.data));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const sendOnboardingComplete = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.company.completeOnBoarding(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					store.dispatch(setCompanyData({ ...response?.data?.data, onboarding_status: true }));
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getCompanyDepartments = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.departments.getAllDepartments(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setCompanyDepartments(response.data.data?.map(i => ({ ...i, label: i.name, value: i.id }))));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const createNewCompanyDepartment = (ndValue, render_index) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('name', ndValue);
	data.append('render_index', render_index);
	const defaultConfig = { ...URLS.departments.createNewDepartment(last_access_token) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					// store.dispatch(someAction(payload));
					subscriber.next(response.data.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: 'You have successfully created a new department. A department head position was also created' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateCompanyDepartment = (ndValue, depId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = qsSimple.stringify({ name: ndValue });
	const defaultConfig = { ...URLS.departments.updateDepartmentById(last_access_token, depId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
		refreshOrgChart: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					// store.dispatch(someAction(payload));
					subscriber.next(response.data.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: 'You have successfully updated the department' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateCompanyDepartmentsOrder = depOrderArray => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	var data = JSON.stringify(depOrderArray);
	const defaultConfig = { ...URLS.departments.updateDepartmentsOrder(last_access_token) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
		refreshOrgChart: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					// store.dispatch(someAction(payload));
					const newOrdered = response.data.data?.sort((a, b) => (a.render_index < b.render_index ? -1 : a.render_index > b.render_index ? 1 : 0));
					subscriber.next(newOrdered);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deleteCompanyDepartment = department => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('name', department.name);
	const defaultConfig = { ...URLS.departments.deleteDepartment(last_access_token, department.id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					// store.dispatch(someAction(payload));
					subscriber.next(response.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `${department?.name ?? 'Department'} was successfully deleted`, color: 'red' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getCompanyOrgChartPositions = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.positions.getPositionsOrgChart(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setCompanyOrgChartData(response?.data?.data));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const importInvitationsFromExcelFile = excelFile => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('file', excelFile);

	const config = {
		...URLS.invitations.importInvitation(last_access_token, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const createInvitation = invitationData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('email', invitationData.email);
	data.append('first_name', invitationData.first_name);
	data.append('last_name', invitationData.last_name);
	data.append('position_id', invitationData.position_id);
	const config = {
		...URLS.invitations.createInvitation(last_access_token, data),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const { first_name, last_name, email, position_id } = response.data.data;
					// store.dispatch(setCompanyOrgChartData(response?.data?.data));
					subscriber.next({ first_name, last_name, email, position_id });
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `You have successfully created an invitation to ${invitationData.first_name}. An email will be sent to the invited user.` }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getAllCompanyDepartmentPositions = department => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.departments.getAllDepartmentPositions(last_access_token, department.id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					// store.dispatch(someAction(payload));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getCompanyDepartmentManagerialPositions = department_id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const defaultConfig = { ...URLS.departments.getDepartmentManagerialPositions(last_access_token, department_id) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data?.data) {
					// store.dispatch(someAction(payload));
					subscriber.next(response.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
//positions

export const getAllPositions = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.positions.getAllPositions(last_access_token),

		handlerEnabled: true,
		forwardAllErrors: true,
		refreshOrgChart: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				// console.log('axiosInstance getAllPositions : ', response);
				if (response?.data && response?.data?.data) {
					const pos = response?.data?.data;
					store.dispatch(
						setPositionInfo({
							name: pos?.name || '',
							id: pos?.id,
							parent: pos.parent?.id ? pos.parent : pos.parent_id,
							department_id: pos.department_id,
							is_managerial: pos.is_managerial,
							users: pos.users,
							shared_users: pos.shared_users,
							children: pos.children,
							ui_permissions: pos.ui_permissions,
						})
					);
					subscriber.next(pos);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const createNewPosition = positionData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('name', positionData.name);
	data.append('parent_id', positionData.parent_id);
	data.append('department_id', positionData.department_id);
	data.append('is_managerial', positionData.is_managerial);
	data.append('standards', positionData.standards);
	const config = {
		...URLS.positions.createNewPosition(last_access_token),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
		refreshOrgChart: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const pos = response?.data?.data;
					store.dispatch(
						setPositionInfo({
							name: pos?.name || '',
							standards: pos?.standards || '',
							ui_permissions: pos?.ui_permissions ?? [],
							id: pos?.id,
							parent: pos.parent?.id ? pos.parent : pos.parent_id,
							department_id: pos.department_id,
							is_managerial: pos.is_managerial,
							users: pos.users,
							shared_users: pos.shared_users,
							children: pos.children,
						})
					);
					subscriber.next(pos);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: 'You have successfully created a position' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deletePosition_AndKeepChildren = positionId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.positions.deletePositionAndKeepChildren(last_access_token, positionId),
		handlerEnabled: true,
		withModalOutput: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					store.dispatch(
						setPositionInfo({
							name: '',
							standards: '',
							id: null,
							parent: null,
							department_id: null,
							is_managerial: 0,
							users: [],
							shared_users: [],
							ui_permissions: [],
						})
					);
					subscriber.next(response?.data);
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `You have successfully deleted the position`, color: 'red' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const deletePosition_AndItsChildren = positionId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.positions.deletePositionAndItsChildren(last_access_token, positionId),
		handlerEnabled: true,
		withModalOutput: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					store.dispatch(
						setPositionInfo({
							name: '',
							standards: '',
							id: null,
							parent: null,
							department_id: null,
							is_managerial: 0,
							users: [],
							ui_permissions: [],
						})
					);
					subscriber.next('success');
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `You have successfully deleted the position and its child positions`, color: 'red' }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const updatePosition = (positionId, position) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	var data = qsSimple.stringify({
		name: position.name,
		department_id: position.department_id,
		parent_id: position.parent_id,
		is_managerial: position.is_managerial,
		standards: position.standards,
		tactical: position.tactical,
	});

	const config = {
		...URLS.positions.updatePositionById(last_access_token, positionId),
		data: data,
		handlerEnabled: true,
		withModalOutput: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response.status == '200' && response?.data) {
					store.dispatch(
						setPositionInfo(response?.data?.data)
					);
					subscriber.next('success');
					subscriber.complete();
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated the position` }));
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getPositionById = positionId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.positions.getPositionById(last_access_token, positionId),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response.status == '200' && response?.data) {
					const pos = response?.data?.data;
					store.dispatch(
						setPositionInfo({
							name: pos?.name || '',
							standards: pos?.standards || '',
							permissions: pos?.permissions,
							id: pos?.id,
							parent: pos?.parent_id?.length > 0 ? pos.parent_id : pos?.parent?.id ? pos.parent : undefined,
							parent_position: pos.parent_position,
							department_id: pos.department_id,
							is_managerial: pos.is_managerial,
							children: pos.children,
							users: pos.users,
							shared_users: pos.shared_users,
						})
					);
					subscriber.next('success');
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getSpecificPositionUsers = positionId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.positions.getPositionUsers(last_access_token, positionId),
		handlerEnabled: true,
		withModalOutput: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response.status == '200' && response?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const duplicateSpecificPosition = positionId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.positions.duplicatePosition(last_access_token, positionId),
		handlerEnabled: true,
		withModalOutput: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const addingUserToPosition = (positionId, userId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	let data = new FormData();
	data.append('user_id', `${userId}`);
	const config = {
		...URLS.positions.addUserToPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
							store.dispatch(setMessageBar({ show: true, message: `You have successfully added user to the position` }));
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const addUsersToPosition = (positionId, users) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = JSON.stringify({ users: users });
	const config = {
		...URLS.positions.addUserToPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
							store.dispatch(setMessageBar({ show: true, message: `You have successfully added user to the position` }));
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const removeUserFromPosition = (positionId, userId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	var data = qsSimple.stringify({ user_id: `${userId}` });
	const config = {
		...URLS.positions.deleteUserFromPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const sharingUserToPosition = (positionId, userId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('user_id', `${userId}`);
	const config = {
		...URLS.positions.shareUserToPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
							store.dispatch(setMessageBar({ show: true, message: `You have successfully shared user to the position` }));
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const unsharingUserFromPosition = (positionId, userId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = qsSimple.stringify({ user_id: `${userId}` });
	const config = {
		...URLS.positions.unshareUserFromPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const addPermissionToPosition = (positionId, permission_name) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('permissions', [`${permission_name}`]);
	const config = {
		...URLS.positions.addPermissionToPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
							store.dispatch(setMessageBar({ show: true, message: `You have successfully added permission` }));
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const removePermissionToPosition = (positionId, permission_name) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = qsSimple.stringify({ permissions: [`${permission_name}`] });
	const config = {
		...URLS.positions.removePermissionToPosition(last_access_token, positionId, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					getPositionById(positionId).subscribe({
						next: _data => {},
						error: _error => {},
						complete: () => {
							subscriber.next(response.data);
							subscriber.complete();
							store.dispatch(setMessageBar({ show: true, message: `You have successfully removed permission` }));
						},
					});
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getAllCompanies = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.company.getAllCompanies(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
