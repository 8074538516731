import React, { ComponentProps, forwardRef, ReactElement } from 'react';
import { ErrorMessage, Label } from '../../../common/UI/Select/SelectComponents';
import classes from './Checkbox.module.scss';
import { Checked } from '../../../../assets/images/icons';
import { UseFormRegisterReturn } from 'react-hook-form';
import classNames from 'classnames';

export type CheckboxContainerProps = {
	/** Sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
	id?: string;
	/** Sets a prop for the label */
	title: string;
	/** Sets a prop for the name attribute*/
	name: string;
	/** Sets an indicator if the field is mandatory */
	hasMandatoryIndicator?: boolean;
	/** Sets an optional prop for an error message in case of an error */
	errormessage?: string;
	/** Sets an optional prop for a help text*/
	helpText?: string;
	/** Sets a prop for the children*/
	children: ReactElement[];
} & ComponentProps<'input'>;

export type CheckboxProps = {
	/** Sets a prop for the checkbox label */
	checkboxLabel: string;
	/** Sets a prop for the name attribute*/
	name: string;
	/** Sets an indicator if the field is mandatory */
	hasMandatoryIndicator?: boolean;
	/** Sets an optional prop for an error message in case of an error */
	errormessage?: string;
} & ComponentProps<'input'> &
	Omit<Partial<UseFormRegisterReturn>, 'onChange'>;

function renderChildren(props: CheckboxContainerProps) {
	return React.Children.map(props.children, (child: ReactElement, index: number) => {
		if (typeof child === 'object')
			return (
				<li key={'checkbox_' + index} className={classes.listItem}>
					{React.cloneElement(child, {
						errormessage: props.errormessage,
					})}
				</li>
			);
		else
			return (
				<li key={'checkbox_' + index} className={classes.listItem}>
					{child}
				</li>
			);
	});
}
const CheckboxContainer = forwardRef<HTMLInputElement, CheckboxContainerProps>((props: CheckboxContainerProps, ref) => {
	const { title, hasMandatoryIndicator, errormessage, ...rest } = props;
	return (
		<div className={classes.container} {...rest} ref={ref}>
			<Label hasMandatoryIndicator={hasMandatoryIndicator}>{title}</Label>
			<ul className={classes.list}>{renderChildren(props)}</ul>
			<div>{errormessage && <ErrorMessage>{errormessage}</ErrorMessage>}</div>
		</div>
	);
});

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props: CheckboxProps, ref) => {
	const { checkboxLabel, hasMandatoryIndicator, disabled, errormessage, ...rest } = props;

	return (
		<Label
			hasMandatoryIndicator={hasMandatoryIndicator}
			className={classNames(classes.host, {
				[classes.checkboxDisabled]: disabled,
			})}
			app-checkbox-error={errormessage && errormessage?.length > 0 ? 'error' : 'false'}
		>
			<span className={classNames(classes.checkmarkContainer)}>
				<input ref={ref} className={classNames(classes.input)} type="checkbox" disabled={disabled} {...rest} />
				<span className={classNames(classes.checkboxControl)}>
					<Checked className={classNames(classes.checkboxControlIcon)} />
				</span>
			</span>
			<span className={classNames(classes.checkboxLabel)}>{checkboxLabel}</span>
		</Label>
	);
});
export { Checkbox, CheckboxContainer };
