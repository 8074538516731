import { SET_AUTHENTICATED_USER, SET_LAST_KNOWN_ROUTE, SET_SESSION_SETTINGS, RESET_AUTH_REDUCER } from '../actions/types';
import { appDefaultReducer } from './defaultReducer';

const INITIAL_STATE = appDefaultReducer.authReducer;
export default function authReducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_SESSION_SETTINGS:
			return { ...state, sessionSettings: { ...state.sessionSettings, ...payload } };
		case SET_AUTHENTICATED_USER:
			return { ...state, authenticatedUser: { ...state.authenticatedUser, ...payload } };

		case SET_LAST_KNOWN_ROUTE:
			return { ...state, lastKnownRoute: payload };
		case RESET_AUTH_REDUCER: {
			const rememberMe = state.sessionSettings.rememberMe;
			return { ...INITIAL_STATE, sessionSettings: { ...INITIAL_STATE.sessionSettings, rememberMe: rememberMe } };
		}
		default:
			return state;
	}
}
