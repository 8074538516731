import { useSelect } from 'downshift';
import React, { ComponentProps, ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { ErrorMessage, Label, ToggleMenuButton, MenuOverlayListItem, MenuOverlayList } from './SelectComponents';
import { XSign } from '../../../../assets/images/icons';
import cls from './Select.module.scss';
import Loader from 'react-loader-spinner';
import { isSafari } from 'react-device-detect';
import Spinner from '../Spinner/Spinner';

type Item = { value: string; label: string; icon?: SVGElement };
export type SelectProps = {
	defaultSelected: any;
	items: Item[];
	title: string;
	placeholder?: string;
	id?: string;
	isClearable: boolean;
	hasMandatoryIndicator?: boolean;
	errorMessage?: string;
	onChange?: (selectedItem?: Item | null) => void;
	onOpen?: (isOpen?: boolean | null) => void;
	toggleLeftIcon?: string;
	selectType?: 'radio' | 'normal';
	iconValue?: boolean;
	menuButtonPlaceholder: string;
	tmiClassName: string;
	molclassname: string;
	moliclassname: string;
	error: boolean;
	loading: boolean;
} & Omit<ComponentProps<'div'>, 'onChange'>;

const Select = forwardRef<HTMLDivElement, SelectProps>((props: SelectProps, ref: ForwardedRef<HTMLDivElement>) => {
	const { items, title, defaultSelected, isClearable, id = title?.replace(/ /g, '-'), placeholder, hasMandatoryIndicator, errorMessage, onChange, className, iconValue, tmiClassName, molclassname, moliclassname, ...rest } = props;

	const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps, selectedItem, selectItem } = useSelect({
		items,
		id,
		defaultSelectedItem: defaultSelected,
		itemToString: item => item?.label ?? '',
		onSelectedItemChange: event => onChange?.(event.selectedItem),
	});
	return (
		<div ref={ref} className={classNames(cls.host, className)} {...rest}>
			<Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
				{title}
			</Label>
			<ToggleMenuButton
				className={classNames(cls.toggleMenu, tmiClassName)}
				{...getToggleButtonProps()}
				isOpen={isOpen}
				extraControl={
					isClearable &&
					selectedItem?.label && (
						<div
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								selectItem(null);
							}}
						>
							<XSign className={cls.toggleMenuControlsIcons} />
						</div>
					)
				}
			>
				{iconValue && defaultSelected?.length > 0 ? <img alt={''} style={{ marginRight: 'auto', width: '18px', height: '18px' }} src={defaultSelected} /> : selectedItem?.label?.length > 0 ? selectedItem?.label : placeholder}
			</ToggleMenuButton>
			<MenuOverlayList {...getMenuProps()} className={classNames(cls.menuOverlayList, molclassname)}>
				{isOpen &&
					items?.map((item, index) => (
						<MenuOverlayListItem
							isVisible={true}
							className={classNames(cls.menuOverlayListItem, moliclassname)}
							key={`${item}${index}`}
							isHighlighted={highlightedIndex === index}
							isSelected={selectedItem == item}
							{...getItemProps({ item, index })}
							style={{
								cursor: 'pointer',
								backgroundColor: selectedItem == item ? 'var(--app-color-gray-lighter)' : highlightedIndex === index ? '#F1F1F1' : 'transparent',
							}}
						>
							<div className={cls.itemContainer}>
								<span className={cls.itemIcon}>{item?.icon && item.icon}</span> {item.label}
							</div>
						</MenuOverlayListItem>
					))}
			</MenuOverlayList>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
});

export default Select;

export const SelectR = forwardRef<HTMLDivElement, SelectProps>((props: SelectProps, ref: ForwardedRef<HTMLDivElement>) => {
	const {
		items,
		title,
		defaultSelected,
		isClearable,
		id = title?.replace(/ /g, '-'),
		placeholder,
		hasMandatoryIndicator,
		errorMessage,
		onChange,
		onOpen,
		className,
		iconValue,
		selectType,
		tmiClassName,
		molclassname,
		moliclassname,
		toggleLeftIcon,
		menuButtonPlaceholder,
		error,
		loading,
		...rest
	} = props;
	const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps, selectedItem, selectItem } = useSelect({
		items,
		id,
		initialSelectedItem: defaultSelected,
		itemToString: item => item?.label ?? '',
		onSelectedItemChange: event => onChange?.(event.selectedItem),
		onStateChange: event => {
			const { type } = event;
			switch (type) {
				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.ToggleButtonClick:
					
					onOpen?.(!isOpen);
					break				
				case useSelect.stateChangeTypes.MenuBlur:
					
					onOpen?.(false);
					break				
				default:
					break;
			}
		},
	});

	return (
		<div ref={ref} className={classNames(cls.host, className)} {...rest} app-variant-has-error={error ? 'error' : ''}>
			{props.children}
			{title && (
				<Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
					{title}
				</Label>
			)}
			<ToggleMenuButton
				className={classNames(cls.toggleMenu, tmiClassName)}
				{...getToggleButtonProps()}
				isOpen={isOpen}
				extraControl={
					isClearable &&
					selectedItem?.label && (
						<div
							onClick={e => {								
								e.stopPropagation();
								e.preventDefault();
								selectItem(null);
							}}
						>
							<XSign className={cls.toggleMenuControlsIcons} />
						</div>
					)
				}
			>
				{toggleLeftIcon && (
					<div className={cls.toggleLeftIconContainer}>
						<img alt={''} src={toggleLeftIcon} className={cls.toggleLeftIcon} />
					</div>
				)}
				{iconValue && defaultSelected?.length > 0 ? (
					<img alt={''} style={{ marginRight: 'auto', width: '18px', height: '18px' }} src={defaultSelected} />
				) : selectedItem?.label?.length > 0 ? (
					<p className={menuButtonPlaceholder}>{selectedItem?.label}</p>
				) : (
					<p className={menuButtonPlaceholder}>{placeholder}</p>
				)}
			</ToggleMenuButton>
			<MenuOverlayList style={{ display: isOpen ? '' : 'none' }} {...getMenuProps()} className={classNames(cls.menuOverlayList, molclassname)}>
				{isOpen &&
					(loading ? (
						<Loader type={isSafari ? 'Watch' : 'Puff'} color="#4A4A4A" secondaryColor="gray" radius={22} height={22} width={'22px'} />
					) : 
						items?.map((item, index) => (
							<MenuOverlayListItem
								className={classNames(cls.menuOverlayListItem, moliclassname)}
								key={`${item}${index}`}
								isVisible={true}
								itemType={selectType}
								isHighlighted={highlightedIndex === index}
								isSelected={JSON.stringify(selectedItem) === JSON.stringify(item)}
								{...getItemProps({ item, index })}
								style={{
									cursor: 'pointer',									
									backgroundColor: selectedItem == item ? '#F2F2F2' : highlightedIndex === index ? '#F2F2F2' : 'transparent',
								}}
							>
								<div className={cls.itemContainer}>
									{item?.icon && <span className={cls.itemIcon}>{item?.icon && item.icon}</span>} {item.label}
								</div>
							</MenuOverlayListItem>
						))
					)}
			</MenuOverlayList>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
});
