import { CustomCheckbox } from '@reach/checkbox';
import '@reach/checkbox/styles.css';
import { useCallback, useEffect, useState } from 'react';
import './CheckboxR.scss';

export default function CheckboxR({ children, ...props }) {
	const { className, labelclassname, checked, onChange, resetTrigger, style, ...rest } = props;
	const [checkedValue, setCheckedValue] = useState(checked ?? false);
	const handleChange = event => {
		setCheckedValue(event.target.checked);
		onChange && onChange(event);
	};
	const resettingChecked = useCallback(() => {
		setCheckedValue(false);
	}, [checked]);
	useEffect(() => {
		resettingChecked();
	}, [resetTrigger]);
	return (
		<span className={`some-custom-checkbox ${className}`}>
			<label className={['label', labelclassname].join(' ')}>
				<CustomCheckbox checked={checkedValue} onChange={handleChange} {...rest} style={style} />
				{children}
			</label>
		</span>
	);
}
