import { createReducerContext } from 'react-use';

const R = require('ramda');

export const beginProcAction = {
	setAllSteps: 'SET_CONTEXT_STEPS',
	setCurrentStep: 'SET_CURRENT_STEP',
	setCurrentStepIndex: 'SET_CURRENT_STEP_INDEX',
	editInputField: 'EDIT_INPUT_FIELD',
	addNewComponent: 'ADD_NEW_COMPONENT',
	setComponentBlueprints: 'SET_COMPONENT_BLUEPRINTS',
	addStepToHistory: 'ADD_STEP_TO_HISTORY',
	setStepsHistory: 'SET_STEPS_HISTORY',
	setInheritedComponents: 'SET_INHERITED_COMPONENTS',
	setComponentId: 'SET_COMPONENT_ID',
};

const beginProcessContextReducer = (state, payload) => {
	const { action, value, index } = payload;
	const newBluePrintsArray = [];

	switch (action) {
		case beginProcAction.setAllSteps:
			return {
				...state,
				steps: value,
			};
		case beginProcAction.addNewComponent:
			return {
				...state,
				newStepComponents: state.newStepComponents.concat(value),
			};
		case beginProcAction.setInheritedComponents:
			return {
				...state,
				inheritedComponents: value,
			};
		case beginProcAction.setComponentBlueprints:
			value.forEach(value => {
				const newArray = value.selected_fields.filter(field => field.visible === true);
				newBluePrintsArray.push({
					...value,
					selected_fields: newArray,
				});
			});
			return {
				...state,
				stepsHistory: state.stepsHistory.map(content => (content.id === state.currentStep.id ? state.currentStep : content)),
				currentStep: {
					...state.currentStep,
					component_blueprints: [...newBluePrintsArray],
				},
			};
		case beginProcAction.setCurrentStep:
			return {
				...state,
				currentStep: value,
			};
		case beginProcAction.setCurrentStepIndex:
			return {
				...state,
				currentStepIndex: value,
			};
		case beginProcAction.addStepToHistory:
			return {
				...state,
				stepsHistory:
					state?.stepsHistory[state?.stepsHistory?.length - 1]?.id !== state?.currentStep?.id
						? state?.stepsHistory?.concat({
								...value,
						  })
						: state.stepsHistory,
			};
		case beginProcAction.setStepsHistory:
			return {
				...state,
				stepsHistory: value,
			};
		case beginProcAction.setComponentId:
			return {
				...state,
				stepsHistory: state.stepsHistory.map(content =>
					content.id === state.currentStep.id
						? {
								...state.currentStep,
								component_blueprints: state.currentStep.component_blueprints.map((content, i) =>
									i === index
										? {
												...content,
												new_component_id: value,
										  }
										: content
								),
						  }
						: content
				),
				currentStep: {
					...state.currentStep,
					component_blueprints: state.currentStep.component_blueprints.map((content, i) =>
						i === index
							? {
									...content,
									new_component_id: value,
							  }
							: content
					),
				},
			};
		default:
			return { ...state };
	}
};

export const [useBeginProcessContext, TheBeginProcessContext] = createReducerContext(beginProcessContextReducer, {
	steps: [],
	currentStep: { id: '', component_blueprints: [] },
	processTask: [],
	newStepComponents: [],
	inheritedComponents: [],
	stepsHistory: [],
	currentStepIndex: 0,
});

export const selectContextAction = {
	setSelectedComponent: 'SET_SELECTED_COMPONENT',
	setSelectedField: 'SET_SELECTED_FIELD',
	extractComponent: 'EXTRACT_COMPONENT',
	toggleState: 'TOGGLE_STATE',
	cleanContextComponents: 'CLEAN_CONTEXT_COMPONENTS',
	setSelectedFields2: 'SET_SELECTED_FIELDS2',
};

const accordionSelectContextReducer = (state, payload) => {
	const { action, value } = payload;
	switch (action) {
		case selectContextAction.setSelectedComponent:
			return {
				...state,
				selectedComponents: [...state.selectedComponents, value],
			};
		case selectContextAction.cleanContextComponents:
			return {
				...state,
				selectedComponents: [],
			};
		case selectContextAction.setSelectedFields2:
			return {
				...state,
				selectedComponents: { ...state.selectedComponents, selected_fields2: value },
			};
		case selectContextAction.extractComponent:
			return {
				...state,
				selectedComponents: state.selectedComponents.filter(comp => comp !== value),
			};

		case selectContextAction.toggleState:
			return {
				...state,
				selectedComponents: [...state.selectedComponents, value],
			};

		default:
			return { ...state };
	}
};

export const [useAccordionSelectContext, TheAccordionSelectContext] = createReducerContext(accordionSelectContextReducer, {
	open: false,
	selectedComponents: [],
});
