import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Form from 'react-validation/build/form';
import { Calendar } from '../../../../assets/images/icons';
import { createTask, deleteTask, updateTask, startTask } from '../../../../services/tasks.service';
import { DropdownIndicator, priorityArray } from '../../../../shared/utility';
import { ModalR } from '../../../common';
import Button from '../../../common/UI/Button/Button';
import cls from './TaskForm.module.scss';
import MessageContainer from '../../ProcessesComps/MessageContainer/MessageContainer';
import { isMobile } from 'react-device-detect';
import SelectAndMultiselect from '../../ProcessesComps/SelectAndMultiselect/SelectAndMultiselect';
const R = require('ramda');
function ModuleTaskForm(props) {

	const { selectedProcess, isProcess, statuses, companyUsersArray, task, isEdit, assigneeId, setDisplayModal, begunModule, onClose, user, start_date = new Date().setDate(new Date().getDate()), span = true, labels, onUpdate = () => { }, companyData, save = true } = props;

	const [newAssignee, setNewAssignee] = useState(task?.assignee?.id ? { assignee_id: task?.assignee?.id } : '');
	const [taskProcess, setTaskProcess] = useState(task?.process?.id ? { process: task?.process?.id } : '');
	const [taskName, setTaskName] = useState(task?.name || '');
	const [taskStatus, setTaskStatus] = useState(task?.status || '');
	const [taskDescription, setTaskDescription] = useState(task?.description || '');
	const [dueDate, setDueDate] = useState(task?.due_date ? new Date(task?.due_date) : (new Date().setDate(new Date().getDate()) + (3600 * 1000)));

	const [startDate, setStartDate] = useState(task?.add_info?.start ? new Date(task?.add_info?.start) : start_date);
	const [endDate, setEndDate] = useState(task?.add_info?.end ? new Date(task?.add_info?.end) : start_date);
	const [value, setValue] = useState(task?.add_info?.value);

	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingRemove, setLoadingRemove] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [toDeleteTask, setToDeleteTask] = useState(false);
	const [isModuleTask, setIsModuleTask] = useState(isProcess);
	const [priority, setPriority] = useState(task?.add_info?.priority || '');
	const usersList =
		companyUsersArray?.length > 0
			? (isEdit && task?.creator?.id !== user.id) ? [
				{ label: user.first_name + ' ' + user.last_name, value: user.id }
			]
				: companyUsersArray.map(elem => {
					return { label: elem.first_name + ' ' + elem.last_name, value: elem.id };
				})
			: [];
	useEffect(() => {
		setMounted(true);
		return () => {
			setMounted(false);
		};
	}, []);

	useEffect(() => {
		if (isModuleTask && selectedProcess?.id && mounted) {
			const setInitials = () => {
				setTaskProcess({ procedure_id: selectedProcess.id });
				setTaskDescription(selectedProcess.description);
			};
			setInitials();
		}
	}, [isModuleTask, mounted, selectedProcess.description, selectedProcess.id]);

	const customStyles = {
		control: (provided, _state) => ({
			...provided,
			boxShadow: 'none',
			'&:hover': {
				border: '1px solid var(--app-color-gray-lighter)',
			},
			position: 'relative',
			height: '60px',
			width: '100%',
			border: 'var(--app-color-gray-lighter) solid 1px',
			padding: '3px',
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '1.4em',
			fontWeight: '700',
			color: '#4A4A4A',
			textAlign: 'left',
			flex: '1 1 auto',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			alignSelf: 'center',
		}),
		input: (provided, _state) => ({
			...provided,
			fontFamily: 'var(--app-text-main-font)',
			color: '#4A4A4A',
			width: '100%',
			textAlign: 'left',
			fontSize: '0.8em',
			fontWeight: '700',
			alignSelf: 'center',
		}),
		menu: (provided, _state) => ({
			...provided,
			justifyContent: 'center',
			alignItems: 'center',
			verticalTextAlign: 'center',
			alignContent: 'center',
		}),
		menulist: (provided, _state) => ({
			...provided,
			justifyContent: 'center',
			alignItems: 'center',
			verticalTextAlign: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
		}),
		option: (provided, _state) => ({
			...provided,
			minHeight: 15,
			paddingTop: 0,
			fontSize: '1.1em',
			color: 'black',
			verticalTextAlign: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			fontFamily: 'var(--app-text-main-font)',
			fontWeight: 'bold'
		}),
		placeholder: (provided, _state) => ({
			...provided,
			fontSize: '0.75em',
			lineHeight: '20px',
			color: '#4A4A4A',
			fontWeight: 400,
			overflow: 'hidden',
			fontFamily: 'var(--app-text-main-font)',
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return {
				...provided,
				opacity,
				transition,
				marginLeft: '0px',
				fontSize: '0.75em',
				lineHeight: '20px',
				color: '#4A4A4A',
				fontFamily: 'var(--app-text-main-font)',
			};
		},
		indicatorsContainer: (styles, { _data }) => ({
			...styles,
			marginTop: 'auto',
			marginBottom: 'auto',
			color: '#4A4A4A',
			size: 7,
			':hover': { color: 'gray' },
			width: '65px',
			height: '45px',
		}),
	};

	const addTask = toStart => {
		setLoading(true);
		createTask(
			{
				newAssignee,
				taskProcess: begunModule?.processModule,
				taskName,
				taskDescription: taskDescription ?? '-',
				dueDate: moment(endDate).format('YYYY-MM-DD HH:mm'),
				add_info: {
					module_id: begunModule?.processModule.module_id,
					priority: priority,
					start: startDate,
					end: endDate,
					value: value
				},
			}

		).subscribe({
			next: data => {
				onClose();
			},
			error: error => {
				if (error) {
					setMessage(error.data);
					setLoading(false);
				}
			},
			complete: () => {
				setLoading(false);
				!isModuleTask && setDisplayModal(false);
			},
		});
	};



	const taskEdit = () => {
		setLoading(true);
		let assignee_id = newAssignee ? newAssignee.assignee_id : assigneeId;
		updateTask(
			{
				assignee_id,
				name: taskName,
				description: taskDescription,
				due_date: moment(dueDate).format('YYYY-MM-DD HH:mm'),
				status: taskStatus ?? '',
				add_info: { ...props?.task?.add_info, priority: priority ?? '', start: startDate, end: endDate, value: value },
			},
			props?.task?.id
		).subscribe({
			next: _data => {
				onUpdate(_data);
				setLoading(false);
				setDisplayModal(false);
			},
			error: error => {
				if (error) {
					setMessage(error.data);
					setLoading(false);
				}
			},
			complete: () => { },
		});
	};

	const removeTask = taskId => {
		setLoadingRemove(true);
		deleteTask(taskId).subscribe({
			next: _data => {
				setLoadingRemove(false);
				setToDeleteTask(false);
			},
			error: _error => {
				setLoadingRemove(false);
			},
			complete: () => {
				setDisplayModal(false);
			},
		});
	};

	const statusArray = statuses ? statuses.map(stat => {
		return { label: stat, value: stat }
	}) : [

		{ label: 'Ongoing', value: 'Ongoing' },
		{ label: 'In Review', value: 'In Review' },
		{ label: 'Approved', value: 'Approved' },
		{ label: 'Postponed', value: 'Postponed' },
		{ label: 'Moved', value: 'Moved' },
		{ label: 'Cancelled', value: 'Cancelled' },
	];

	return (
		<div className={cls.newTaskFormHost}>
			<Form style={{ flex: 1 }}>
				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.name ? 'var(--app-color-error)' : '', display: task?.completed_at ? '' : '' }}>
						<p>
							{labels?.title ?? 'Event Name'}
						</p>
						<textarea readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true} required={message?.errors?.name} onFocus={() => setMessage('')} value={taskName} onChange={e => setTaskName(e.target.value)} />
					</label>
					{message && message?.errors?.name && <MessageContainer message={message.errors.name} />}
				</div>
				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.name ? 'var(--app-color-error)' : '', display: task?.completed_at ? '' : '' }}>
						<p>
							{labels?.description ?? 'Description'}
						</p>
						<textarea style={{ minHeight: '200px' }} readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true} required={message?.errors?.description} onFocus={() => setMessage('')} value={taskDescription} onChange={e => setTaskDescription(e.target.value)} />
					</label>
					{message && message?.errors?.name && <MessageContainer message={message.errors.description} />}
				</div>
				{labels?.valueL && <div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.name ? 'var(--app-color-error)' : '' }}>
						<p>
							{labels?.valueL}
						</p>
						<input readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
							onFocus={() => setMessage('')}
							value={value}
							onChange={e => setValue(e.target.value)}
							onBlur={() => {
								if (begunModule?.processModule?.module?.options?.credits) {
									if (+value > +user?.options?.credit) {
										setValue('0.00');
										setMessage({
											errors: {
												value: `You have insufficient funds in your wallet. You currently have ${user?.options?.credit}.`
											}
										})
									}
								}
							}}
						/>
					</label>
					{message && message?.errors?.value && <MessageContainer message={message.errors.value} />}
				</div>}
				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.due_date ? 'var(--app-color-error)' : '' }}>
						<p>
							{labels?.start ?? 'Start Date'}
						</p>
						<DatePicker
							showTimeSelect
							timeFormat="HH:mm"
							timeIntervals={15}
							disabled={(task?.id === undefined || (!task?.completed_at && user.id === task?.creator?.id)) ? false : true}
							placeholderText="Start Date"
							customInput={
								<div
									id={'custom-input'}
									style={{
										cursor: 'pointer',
										display: 'flex',
										justifyContent: 'center',
										width: '100%',
										alignItems: 'stretch',

										border: '1px solid',
										borderColor: 'var(--app-color-gray-lighter)',
									}}
								>
									<input
										disabled
										readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
										onFocus={() => setMessage('')}
										value={(task?.id === undefined || (!task?.completed_at && user.id === task?.creator?.id)) ? moment(startDate).format('DD MMMM YYYY HH:mm') : `${moment(startDate).format('DD MMMM YYYY HH:mm')}`}
										type="text"
										required={message?.errors?.startDate}
										id={'start-date'}
										style={{
											flex: 1,
											marginTop: 'auto',
											marginBottom: 'auto',
											outline: 'none',
											border: 'none',
											fontFamily: 'var(--app-text-main-font)',
											fontSize: '16px',
											lineHeight: '20px',
											color: '#4A4A4A',
											textAlign: startDate?.length > 0 ? 'center' : 'left',
											maxWidth: '98%',
										}}
										placeholder={'Start Date'}
										onChange={_e => { }}
									/>
									<Calendar className={cls.calendarSVG} />
								</div>
							}
							className={cls.datePickerWrapper}
							selected={startDate}
							onChange={e => {

								if (moment(e).diff(moment(endDate), 'minutes') < 1) {
									setStartDate(e);
								} else {
									setStartDate(e);
									setEndDate(e);
								}
							}}
						/>

					</label>
				</div>
				{(task?.id === undefined || (!task?.completed_at && user.id === task?.creator?.id)) &&
					<div style={{ marginBottom: '10px' }}>
						<label style={{ color: message?.errors?.due_date ? 'var(--app-color-error)' : '' }}>
							<p>
								{labels?.end ?? 'End Date'}
							</p>
							<DatePicker
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								disabled={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
								placeholderText="End Date"
								customInput={
									<div
										id={'custom-input'}
										style={{
											cursor: 'pointer',
											display: 'flex',
											justifyContent: 'center',
											width: '100%',
											alignItems: 'stretch',

											border: '1px solid',
											borderColor: message?.errors?.endDate ? 'var(--app-color-error)' : 'var(--app-color-gray-lighter)',
										}}
									>
										<input
											disabled
											readOnly={task?.id === undefined ? false : task?.completed_at ? true : user.id === task?.creator?.id ? false : true}
											onFocus={() => setMessage('')}
											value={moment(endDate).format('DD MMMM YYYY HH:mm')}
											type="text"
											required={message?.errors?.endDate}
											id={'due-date'}
											style={{
												flex: 1,
												marginTop: 'auto',
												marginBottom: 'auto',
												outline: 'none',
												border: 'none',
												fontFamily: 'var(--app-text-main-font)',
												fontSize: '16px',
												lineHeight: '20px',
												color: '#4A4A4A',
												textAlign: endDate?.length > 0 ? 'center' : 'left',
												maxWidth: '98%',
											}}
											placeholder={'Due Date'}
											onChange={_e => { }}
										/>
										<Calendar className={cls.calendarSVG} />
									</div>
								}
								className={cls.datePickerWrapper}
								selected={endDate}
								onChange={e => {

									if (moment(e).diff(moment(startDate), 'minutes') > 0) {
										if (span === false) {
											if (moment(e).format('DD') === moment(endDate).format('DD')) {
												setEndDate(e);
											} else {
												setEndDate(endDate);
											}
										} else {
											setEndDate(e);
										}
									} else {
										setEndDate(endDate);
									}
								}
								}
							/>

						</label>
					</div>}
				{message && message?.errors?.due_date && <MessageContainer message={message.errors.endDate} />}


				<div style={{ marginBottom: '10px' }}>
					<label style={{ color: message?.errors?.assignee_id ? 'var(--app-color-error)' : '' }}>
						<p>
							{labels?.assignee ?? 'Assignee'}
						</p>
						{task?.completed_at ? (
							<div>
								<input style={{ cursor: 'auto' }} placeholder={task?.assignee.first_name + ' ' + task?.assignee.last_name} readOnly />
							</div>
						) : (
							<Select
								onFocus={() => setMessage('')}
								isSearchable={false}
								key={'Assignee'}
								styles={customStyles}
								defaultInputValue={''}
								components={{ DropdownIndicator }}
								placeholder=""
								value={usersList[usersList.findIndex(c => c.value === newAssignee.assignee_id)]}
								options={usersList.concat([{ label: 'None', value: '' }])}
								onChange={assignee => {
									if (assignee) {
										setNewAssignee(c => ({ ...c, assignee_id: assignee.value }));
									}
								}}
							/>
						)}
					</label>
					
					{message && message?.errors?.assignee_id && <MessageContainer message={message.errors.assignee_id} />}
				</div>

				{(task?.completed_at || user.id === task?.creator?.id) && <div style={{ marginBottom: '10px' }}>

					<label style={{ color: message?.errors?.procedure_id ? 'var(--app-color-error)' : '' }}>
						<p>
							{'Status'}
						</p>
						{task?.completed_at ? (
							<input style={{ cursor: 'auto' }} placeholder={task?.status ?? 'No status set'} readOnly />
						) : (
							user.id === task?.creator?.id ? <Select
								onFocus={() => setMessage('')}
								key={'status'}
								isSearchable={false}
								styles={customStyles}
								defaultInputValue={''}
								components={{ DropdownIndicator }}
								placeholder=''
								value={statusArray.find(s => s.value === taskStatus)}
								options={user.id === task?.creator?.id ? [...statusArray, { label: companyData?.misc_info?.lock, value: companyData?.misc_info?.lock }, { label: 'No status', value: '' }] : statusArray}
								onChange={e => setTaskStatus(e.value)}
							/> :
								<input style={{ cursor: 'auto' }} placeholder={task?.status ?? 'No status set'} readOnly />
						)

						}
					</label>
					{message && message?.errors?.procedure_id && <MessageContainer message={message.errors.procedure_id} />}
				</div>}
				{message && message?.message && <MessageContainer message={message.message} />}
			</Form>
			{task?.completed_at ? (
				<div className={cls.buttonsContainer}>
					<Button clicked={onClose} className={cls.saveButton} title={'Close'} />
				</div>
			) : !isEdit ? (
				<div className={cls.buttonsContainer}>
					<Button clicked={onClose} className={cls.saveButton} title={'Close'} />
					<Button light clicked={() => addTask(false)} className={cls.saveButton} title={'Save'} loading={loading} />
				</div>
			) : (
				<div className={cls.buttonsContainer}>
					<Button clicked={onClose} className={cls.saveButton} title={'Close'} />
					{save && user.id === task.creator?.id && <Button light clicked={() => setToDeleteTask(true)} className={cls.saveButton} title={'Delete'} />}
					{save && <Button light clicked={() => taskEdit()} className={cls.saveButton} title={'Update'} loading={loading} />}
					{!save && <Button
						title={'Assign'}
						light
						clicked={() => {

							onUpdate({
								...props?.task,
								assignee_id: newAssignee ? newAssignee.assignee_id : assigneeId,
								name: taskName,
								description: taskDescription,
								due_date: moment(dueDate).format('YYYY-MM-DD HH:mm'),
								status: taskStatus ?? '',
								add_info: { ...props?.task?.add_info, priority: priority ?? '', start: startDate, end: endDate, value: value },
							})
							setDisplayModal(false);

						}} className={cls.saveButton} />}

				</div>
			)}

			{toDeleteTask && <DeleteTaskModal taskId={task.id} isOpen={toDeleteTask} removeTask={removeTask} loading={loadingRemove} onClose={setToDeleteTask} />}
		</div>
	);
}

const DeleteTaskModal = props => {
	const { isOpen, onClose, loading, removeTask, taskId } = props;
	return (
		<ModalR isOpen={isOpen} onClose={() => onClose(false)} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: '35%' }}>
			<div>
				<p
					style={{
						textAlign: 'center',
						fontSize: '20px',
						color: '#4A4A4A',
						fontFamily: 'var(--app-text-main-font)',
					}}
				>
					{'Are you sure do you want to delete this task?'}
				</p>
				<Button loading={loading} clicked={() => removeTask(taskId)} title={'Yes, Delete'} style={{ fontWeight: '500', marginLeft: 'auto', marginRight: 'auto' }} />
				<p
					style={{
						cursor: 'pointer',
						textAlign: 'center',
						fontSize: '16px',
						fontWeight: 600,
						color: '#4A4A4A',
						fontFamily: 'var(--app-text-main-font)',
					}}
					onClick={() => onClose(false)}
					className={'go-back-text'}
				>
					{'No, Go Back'}
				</p>
			</div>
		</ModalR>
	);
};

const mapStateToProps = store => {
	const { selectedProcess, companyUsersArray, companyProcesses, allModules, begunModule, companyData } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { selectedProcess, companyUsersArray, companyProcesses, allModules, user, begunModule, companyData };
};

export default connect(mapStateToProps)(ModuleTaskForm);
