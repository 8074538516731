import React from 'react';
import './HomeScreen.css';
import Button from '../../../UI/Button/Button';

export default function HomeScreen({ createCall, startHairCheck }) {
  const startDemo = () => {
    createCall().then((url) => {
      startHairCheck(url);
    });
  };

  return (
    <div className="home-screen">
      <Button clicked={startDemo} type="button" title={'Join Call'}/>
    </div>
  );
}
