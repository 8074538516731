import { ReactElement, useMemo } from 'react';
import { usePermissions, perm, po } from '../../../hooks/usePermisions';

export type PermissionWrapperProps = {
	permissions: typeof perm;
	children: ReactElement | ReactElement[];
};
export const PermissionWrapper = (props: PermissionWrapperProps) => {
	const [perm] = usePermissions();
	const { permissions, children } = props;

	// eslint-disable-next-line no-extra-parens
	const viewFeature = useMemo(() => permissions.some(i => (perm as any)[i]), [permissions, perm]);

	return viewFeature ? children : null;
};
export { po };
